import { CellContext } from '@tanstack/react-table';
import { useState } from 'react';
import { ActionMeta, components, GroupBase, OptionProps, OptionsOrGroups, ValueContainerProps } from 'react-select';
import { Input } from './Input.tsx';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { getDropdownStyle } from '../SenraTable.style.ts';
import { ColorBadge } from '../../badges/ColorBadge.tsx';
import CreatableSelect from 'react-select/creatable';

type OptionValue = unknown;

export interface TSelectOption<OptionValue> {
  value: OptionValue;
  label: string;
  id: string;
}

const { Option, ValueContainer } = components;

export const Dropdown = <T, U extends TSelectOption<OptionValue>>({
  props,
  options,
  onCreate,
  createLabel,
  onChange,
  isReadonly,
}: {
  props: CellContext<T, string | number | readonly string[] | undefined>;
  options: U[];
  onCreate?: (inputValue: string) => U;
  createLabel?: (inputValue: string) => string;
  onChange?: (inputValue: string) => Promise<OptionsOrGroups<U, GroupBase<U>>>;
  isReadonly?: boolean;
}) => {
  const {
    getValue,
    row: { index, depth },
    column,
    table,
  }: CellContext<T, unknown> = props;
  const initialValue = getValue<string>();
  const initialOption = options.find((opt) => opt.id === initialValue);
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<U | undefined>(initialOption);

  // Is valid if there's an there's a valid value and valid opt
  const isValidOption = !!initialOption || initialValue?.length === 0;

  const _onChange = (option: U | null, _: ActionMeta<U>) => {
    if (option) {
      setValue(option);
      table.options.meta?.updateData(index, depth, column.id, option.value);
    } else {
      setValue(undefined);
      table.options.meta?.updateData(index, depth, column.id, '');
    }
  };

  const onCreateOption = (input: string) => {
    if (onCreate) {
      const option = onCreate(input);
      setValue(option);
      table.options.meta?.updateData(index, depth, column.id, option.value);
    }
  };

  if (!isValidOption) {
    return (
      <>
        <Tooltip label="Warning: Your selection is outside the standard parameters, but is acceptable for now.">
          {Input(props, isReadonly, { color: 'orange' })}
        </Tooltip>
      </>
    );
  }

  return (
    <Box data-cy="dropdown" width="full" minWidth="max-content" justifyContent="center" alignItems="center">
      {onCreate ? (
        onChange ? (
          <AsyncCreatableSelect
            cacheOptions
            isDisabled={isReadonly}
            inputId={`dropdown-${index}-${column.id}`}
            styles={getDropdownStyle<U, false>()}
            defaultValue={value}
            menuPortalTarget={document.getElementById('portal')}
            loadOptions={onChange}
            onCreateOption={onCreateOption}
            formatCreateLabel={(inputValue: string) =>
              createLabel ? createLabel(inputValue) : `Create new ${inputValue}`
            }
            onKeyDown={(e) => e.stopPropagation()}
            onChange={_onChange}
            getOptionLabel={(opt: U) => opt.label}
            getOptionValue={(opt: U) => opt.id}
            options={options}
            defaultOptions={options}
            isClearable={true}
            components={{
              ClearIndicator: () => null,
              Option: ColorBadgeOptions,
              ValueContainer: ColorBadgeValueContainer,
            }}
            closeMenuOnSelect={false}
          />
        ) : (
          <CreatableSelect
            isDisabled={isReadonly}
            inputId={`dropdown-${index}-${column.id}`}
            styles={getDropdownStyle<U, false>()}
            defaultValue={value}
            menuPortalTarget={document.getElementById('portal')}
            onCreateOption={onCreateOption}
            formatCreateLabel={(inputValue: string) =>
              createLabel ? createLabel(inputValue) : `Create new ${inputValue}`
            }
            onKeyDown={(e) => e.stopPropagation()}
            onChange={_onChange}
            getOptionLabel={(opt: U) => opt.label}
            getOptionValue={(opt: U) => opt.id}
            options={options}
            isClearable={true}
            components={{
              ClearIndicator: () => null,
              Option: ColorBadgeOptions,
              ValueContainer: ColorBadgeValueContainer,
            }}
            closeMenuOnSelect={false}
          />
        )
      ) : (
        <AsyncSelect
          cacheOptions
          isDisabled={isReadonly}
          inputId={`dropdown-${index}-${column.id}`}
          onKeyDown={(e) => e.stopPropagation()}
          styles={getDropdownStyle<U, false>()}
          defaultValue={value}
          menuPortalTarget={document.getElementById('portal')}
          onChange={_onChange}
          getOptionLabel={(opt: U) => opt.label}
          getOptionValue={(opt: U) => opt.id}
          options={options}
          isClearable={true}
          components={{
            Option: ColorBadgeOptions,
            ValueContainer: ColorBadgeValueContainer,
            ClearIndicator: () => null,
          }}
        />
      )}
    </Box>
  );
};

export const ColorBadgeOptions = <U extends TSelectOption<unknown>>(props: OptionProps<U, false, GroupBase<U>>) => (
  <Option {...props}>
    <Flex gap="2" alignItems="center">
      <ColorBadge value={props.data.label} />
      {props.data.label}
    </Flex>
  </Option>
);

export const ColorBadgeValueContainer = <U extends TSelectOption<unknown>>({
  children,
  ...props
}: ValueContainerProps<U, false, GroupBase<U>>) => {
  return (
    <ValueContainer {...props}>
      <Flex gap="2" alignItems="center">
        {props.getValue().map((v) => (
          <ColorBadge key={v.label} value={v.label} />
        ))}
        {children}
      </Flex>
    </ValueContainer>
  );
};
