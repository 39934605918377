import { HStack, Text } from '@chakra-ui/react';
import { Panel, PanelProps, useViewport } from '@xyflow/react';

interface Props {
  position?: PanelProps['position'] | 'top-right';
  style?: PanelProps['style'];
}

/**
 * Coordinate panel component.
 * @param position
 * @param style
 * @constructor
 */
const CoordinatePanel = ({ position, style }: Props) => {
  const { zoom } = useViewport();

  return (
    <Panel position={position} style={style}>
      <HStack fontSize="sm" fontFamily="mono">
        <Text>Zoom: {(Math.round(zoom * 100) / 100).toFixed(2)}</Text>
      </HStack>
    </Panel>
  );
};

export default CoordinatePanel;
