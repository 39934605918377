import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Box, Text } from '@chakra-ui/react';
import { PassiveData, PassiveType, UUID } from '@senrasystems/senra-ui';
import { defaultLayoutConfig } from '../../config.ts';
import { centerAbsolute } from '@web/apps/styles.ts';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';

export type PassiveNodeData = {
  designPartId: UUID;
};

export const defaultPassiveNodeData: PassiveNodeData = {
  designPartId: '',
};

export type PassiveNode = Node<PassiveNodeData>;

/**
 * Passive node component.
 * @param props
 * @constructor
 */
const PassiveNode = (props: NodeProps<PassiveNode>) => {
  const { data = defaultPassiveNodeData } = props;
  const { designPartId } = data;

  // Get the design part data
  const designPart = useDesignParts().getDesignPartById(designPartId);

  const Shape = () => {
    const subtype = (designPart?.partData as PassiveData).subtype;
    const color = props.selected ? defaultLayoutConfig.selectedNodeColor : defaultLayoutConfig.nodeColor;
    switch (subtype) {
      case PassiveType.CAPACITOR:
        return <CapacitorShape color={color} />;
      case PassiveType.DIODE:
        return <DiodeShape color={color} />;
      case PassiveType.RESISTOR:
        return <ResisterShape color={color} />;
      default:
        return null;
    }
  };

  return (
    <Box position="relative">
      <Text position="absolute" top="-20px">
        {designPart?.name}
      </Text>
      <Shape />
      <Box sx={{ ...centerAbsolute, visibility: 'hidden' }}>
        <Handle type="source" position={Position.Top} />
      </Box>
    </Box>
  );
};

const CapacitorShape = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        d="M15 7.25C15.4142 7.25 15.75 7.58579 15.75 8V10.25H20C20.4142 10.25 20.75 10.5858 20.75 11C20.75 11.4142 20.4142 11.75 20 11.75H15.75V14C15.75 14.4142 15.4142 14.75 15 14.75C14.5858 14.75 14.25 14.4142 14.25 14V8C14.25 7.58579 14.5858 7.25 15 7.25Z"
        fill="white"
      />
      <path
        d="M3 10.25C2.58579 10.25 2.25 10.5858 2.25 11C2.25 11.4142 2.58579 11.75 3 11.75H7.25V14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14V11.0006V8C8.75 7.58579 8.41421 7.25 8 7.25C7.58579 7.25 7.25 7.58579 7.25 8V10.25H3Z"
        fill="white"
      />
    </svg>
  );
};

const DiodeShape = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11.8301V15.0801C15.5 15.4943 15.1642 15.8301 14.75 15.8301C14.3358 15.8301 14 15.4943 14 15.0801V12.3792L7.625 16.0598C7.39295 16.1937 7.10705 16.1937 6.875 16.0598C6.64295 15.9258 6.5 15.6782 6.5 15.4103V11.8301H2.75C2.33579 11.8301 2 11.4943 2 11.0801C2 10.6659 2.33579 10.3301 2.75 10.3301H6.5V6.75C6.5 6.48205 6.64295 6.23446 6.875 6.10048C7.10705 5.96651 7.39295 5.96651 7.625 6.10048L14 9.78109V7.08008C14 6.66586 14.3358 6.33008 14.75 6.33008C15.1642 6.33008 15.5 6.66586 15.5 7.08008V10.3301H19.75C20.1642 10.3301 20.5 10.6659 20.5 11.0801C20.5 11.4943 20.1642 11.8301 19.75 11.8301H15.5ZM8 8.04904L13.25 11.0801L8 14.1112L8 8.04904Z"
        fill="white"
      />
    </svg>
  );
};

const ResisterShape = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54916 4.75161C9.8605 4.77206 10.1267 4.98314 10.2175 5.28163L13.1101 14.7858L14.8077 10.7115C14.9241 10.4321 15.1972 10.25 15.5 10.25H18.5C18.9142 10.25 19.25 10.5858 19.25 11C19.25 11.4142 18.9142 11.75 18.5 11.75H16L13.6923 17.2885C13.5706 17.5805 13.2788 17.7648 12.9628 17.7491C12.6468 17.7334 12.3746 17.521 12.2825 17.2184L9.36083 7.61863L7.68277 11.3104C7.56107 11.5781 7.29411 11.75 7 11.75H4C3.58579 11.75 3.25 11.4142 3.25 11C3.25 10.5858 3.58579 10.25 4 10.25H6.51707L8.81722 5.18965C8.94634 4.9056 9.23782 4.73116 9.54916 4.75161Z"
        fill="white"
      />
    </svg>
  );
};

export default PassiveNode;
