export const toTitleCase = (s: string): string =>
  s
    .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c: string) => ' ' + c.toUpperCase());

export function toCamelCase(value: string): string {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function cleanString(value: string): string {
  return value.trim();
}

export function cleanStringOptional(value?: string | null): string | undefined | null {
  if (!value) return value;
  return cleanString(value);
}

export function stripWhitespace(value: string): string {
  return value.replace(/\s/g, '');
}

export function pluralize(n: number): string {
  if (n === -1 || n === 1) return '';
  return 's';
}

export function emptyToUndefined(value?: string): string | undefined {
  if (value === undefined || value === '') return undefined;
  return value;
}

export function toSentenceCase(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function encodeText(str: string) {
  /*https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986 */
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}

export function sentenceCaseToSnakeCase(s: string) {
  return s
    .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
}

export function snakeCaseToSentenceCase(s: string) {
  return s
    .split('_')
    .join(' ')
    .replace(/^\w/, (c) => c.toUpperCase());
}

export function getInitials(name: string) {
  return name
    .split(' ')
    .map((x) => x.substring(0, 1))
    .join('')
    .substring(0, 3) // max 3 initials
    .toUpperCase();
}
