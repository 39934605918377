import { Graph } from '../types.ts';
import { AddControlPoint, AddControlPointOperation } from './operations/AddControlPoint.ts';
import { BuildLayout, BuildLayoutOperation } from './operations/BuildLayout.ts';
import { HighlightBreakoutPoints, HighlightBreakoutPointsOperation } from './operations/HighlightBreakoutPoints.ts';
import { MergeBreakoutPoints, MergeBreakoutPointsOperation } from './operations/MergeBreakoutPoints.ts';
import { RebuildMeasurements, RebuildMeasurementsOperation } from './operations/RebuildMeasurements.ts';
import { RemoveControlPoint, RemoveControlPointOperation } from './operations/RemoveControlPoint.ts';
import { UnmergeBundle, UnmergeBundleOperation } from './operations/UnmergeBundle.ts';
import { UpdateNodeData, UpdateNodeDataOperation } from './operations/UpdateNodeData.ts';
import { UpdateEdgeData, UpdateEdgeDataOperation } from './operations/UpdateEdgeData.ts';
import { UpdateBuildNotes, UpdateBuildNotesOperation } from './operations/UpdateBuildNotes.ts';

// Abstract class for GraphOperations
export interface Operations<T extends GraphOperation> {
  // Flag to indicate if the operation requires a rebuild
  requiresRebuild?: boolean;

  // Execute the operation
  execute(graph: Graph, params: T): Graph;
}

// Define a union type for all operations
export type GraphOperation =
  | AddControlPointOperation
  | BuildLayoutOperation
  | HighlightBreakoutPointsOperation
  | MergeBreakoutPointsOperation
  | RebuildMeasurementsOperation
  | RemoveControlPointOperation
  | UnmergeBundleOperation
  | UpdateNodeDataOperation
  | UpdateEdgeDataOperation
  | UpdateBuildNotesOperation;

// Define a type for the operation class
export type OperationClass<T extends GraphOperation> = new () => Operations<T>;

// Create a mapping from operation type to corresponding class
export const operationsMap: {
  [K in GraphOperation['type']]: OperationClass<Extract<GraphOperation, { type: K }>>;
} = {
  AddControlPoint,
  BuildLayout,
  HighlightBreakoutPoints,
  MergeBreakoutPoints,
  RebuildMeasurements,
  RemoveControlPoint,
  UnmergeBundle,
  UpdateNodeData,
  UpdateEdgeData,
  UpdateBuildNotes,
} as const;
