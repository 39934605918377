import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Xmark from '../svg/Xmark';
import { Box, Button, Text } from '@chakra-ui/react';

interface Props {
  label: string;
  hint: string;
  files: File[];
  bgColor: string;
  onUpload: (acceptedFiles: File[]) => void;
  multiple?: boolean;
  onDeleteFile?: (fileName: string) => void;
}

const FileInput = ({ label, hint, files, bgColor, onUpload, onDeleteFile, multiple = true }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onUpload(acceptedFiles);
    },
    [onUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

  return (
    <Box maxW="lg" pb={4}>
      <Text>{label}</Text>
      <Box
        h={32}
        border="1px"
        bg={bgColor}
        borderStyle="dashed"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        p={8}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>{hint}</p>
      </Box>
      {files.map((f: File) => (
        <Text key={f.name} py={2} fontSize="sm" alignItems="center">
          {f.name}
          {onDeleteFile && (
            <Button onClick={() => onDeleteFile(f.name)} ml={2}>
              <Xmark size={3} />
            </Button>
          )}
        </Text>
      ))}
    </Box>
  );
};

export default FileInput;
