import { useMemo } from 'react';
import { acceptsContacts, uniqueDesignPartsByPartNumber } from '@senrasystems/senra-ui';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { Option } from '../../../components/SearchableDropdown.tsx';

/**
 * Valid source contact options are (unique) contacts on the selected design part
 */
export const useSourceContactOptions = () => {
  const { selectedDesignPart } = useDesign();

  const options = useMemo(() => {
    const contacts = acceptsContacts(selectedDesignPart) ? (selectedDesignPart?.contacts ?? []) : [];
    return uniqueDesignPartsByPartNumber(contacts).map(
      (contact): Option => ({
        label: contact.partData.partNumber,
        value: contact.id,
      }),
    );
  }, [selectedDesignPart]);

  return { options };
};
