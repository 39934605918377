import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';

interface Props extends IconButtonProps {}

const DeleteIconButton = ({ ...rest }: Props) => {
  return <IconButton icon={<BiTrash />} fontSize="1.25em" variant="unstyled" {...rest} />;
};

export default DeleteIconButton;
