import { DesignPart, isAccessory, isLinear, PartType } from '@senrasystems/senra-ui';
import {
  Box,
  BoxProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useDeleteDesignPartMutation, useRenameDesignPartMutation } from '../../../api/queries.ts';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { MouseEvent, useEffect, useState } from 'react';
import EditableText from '../../../components/EditableText.tsx';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { BiSolidCircle } from 'react-icons/bi';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props extends BoxProps {
  part: DesignPart;
  onReplacePart?: (part: DesignPart) => void;
  designLengthUnit?: string;
  editMode?: boolean;
}

/**
 * DesignPartListItem component displays a single part in a list of parts.
 * @param part
 * @param designParts
 * @param rest
 * @constructor
 */
const PartListItem = ({ part, onReplacePart, designLengthUnit, editMode, ...rest }: Props) => {
  const { designId, isSelectedDesignPart, setSelectedDesignPart, selectedBOMItem } = useDesign();
  const { showErrorToast } = useDesignToast();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [originalPartName, setOriginalPartName] = useState(part.name);
  const [currentPartName, setCurrentPartName] = useState(part.name);
  const isSelected = isSelectedDesignPart(part);

  // If part is equal to selectedDesignPart, make sure selectedDesignPart is set to the latest part. i.e. when part is
  // updated, selectedDesignPart should also be updated.
  useEffect(() => {
    if (isSelected) {
      setSelectedDesignPart(part);
    }
  }, [part, isSelected, setSelectedDesignPart]);

  // Mutation to rename a part
  const {
    mutate: renameDesignPart,
    isSuccess: renameDesignPartSuccess,
    isError: renameDesignPartError,
  } = useRenameDesignPartMutation();

  useEffect(() => {
    if (renameDesignPartSuccess) {
      setOriginalPartName(currentPartName);
    } else if (renameDesignPartError) {
      setCurrentPartName(originalPartName);
    }
  }, [renameDesignPartSuccess, renameDesignPartError, currentPartName, originalPartName]);

  // Mutation to delete a part
  const { mutate: deleteDesignPart, error: deleteDesignPartError } = useDeleteDesignPartMutation();

  useEffect(() => {
    if (deleteDesignPartError) {
      showErrorToast('Error removing part', deleteDesignPartError.message);
    }
  }, [deleteDesignPartError, showErrorToast]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!isSelected) {
      event.stopPropagation();
      setSelectedDesignPart(part);
    }
  };

  const handleMouseEnter = () => {
    if (!isMenuOpen) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMenuOpen) {
      setIsHovered(false);
    }
  };

  const handleDeletePart = () => {
    deleteDesignPart({ designId, partId: part.id });
  };

  const handleRenamePart = (nextName: string) => {
    setCurrentPartName(nextName);
    renameDesignPart({ designId, partId: part.id, data: { name: nextName } });
  };

  const handleReplacePart = () => {
    if (onReplacePart) {
      onReplacePart(part);
    }
  };

  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      bg={isSelected ? 'blue.100' : 'transparent'}
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor={isHovered && part.partData.type !== PartType.CONNECTOR ? 'blue.300' : 'transparent'}
      {...rest}
    >
      <HStack h={8} w="full">
        <Box
          as={BiSolidCircle}
          fontSize=".5em"
          color="gray.400"
          opacity={part.partData.partNumber === selectedBOMItem?.partData.partNumber ? 1 : 0}
          transition="opacity .3s ease-in-out"
        />
        {!isAccessory(part.partData.type) && (
          <Box>
            <EditableText
              text={currentPartName}
              onEdit={handleRenamePart}
              size="sm"
              w="50px"
              isTruncated={true}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="75px"
            />
          </Box>
        )}
        <Box onDoubleClick={handleReplacePart}>
          <HStack>
            <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="blue.500">
              {part.partData.partNumber}
            </Text>
            {part?.includedAccessory && <Text color="gray.600">Included</Text>}
          </HStack>
        </Box>
        {isLinear(part.partData.type) && (
          <Box>
            <Text>
              ({part.quantity} {designLengthUnit || ''})
            </Text>
          </Box>
        )}
        <Spacer />
        {isSelected && (
          <Box as={Menu} isOpen={isMenuOpen} onOpen={onMenuOpen} onClose={onMenuClose}>
            <MenuButton
              as={IconButton}
              size="sm"
              icon={<SlOptionsVertical />}
              _hover={{ bg: 'blue.100' }}
              bg="transparent"
              opacity={editMode ? 1 : 0}
              transition="opacity .3s ease-in-out"
              onClick={(event) => event.stopPropagation()}
            />
            <MenuList>
              <Tooltip label={part?.includedAccessory ? 'This part is included with the parent component' : ''}>
                <MenuItem isDisabled={part?.includedAccessory} onClick={handleDeletePart}>
                  Remove
                </MenuItem>
              </Tooltip>
              <Tooltip label={part?.includedAccessory ? 'This part is included with the parent component' : ''}>
                <MenuItem isDisabled={part?.includedAccessory} onClick={handleReplacePart}>
                  Replace
                </MenuItem>
              </Tooltip>
            </MenuList>
          </Box>
        )}
      </HStack>
    </Box>
  );
};

export default PartListItem;
