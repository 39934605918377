import { CellContext } from '@tanstack/react-table';
import { Input as ChakraInput, InputGroup, InputProps } from '@chakra-ui/react';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { ColorBadge } from '../../badges/ColorBadge.tsx';

export const Input = <T, U extends string | number | readonly string[] | undefined>(
  props: CellContext<T, U>,
  isReadonly?: boolean,
  overrides?: InputProps,
) => {
  const { getValue, row, column, table } = props;
  const { index, depth } = row;
  const initialValue = getValue<U>();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<U>(initialValue);
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputType = typeof initialValue;

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    setOnFocus(false);
    if (value !== initialValue) {
      table.options.meta?.updateData(index, depth, column.id, value);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (onFocus) {
      inputRef.current?.focus();
    }
  }, [onFocus]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'number') {
      setValue(parseInt(e.target.value) as U);
    } else {
      setValue(e.target.value as U);
    }
  };

  return (
    <InputGroup height="full" alignItems="center" gap={2}>
      <ColorBadge value={`${value?.toString() ?? ''}`} />
      <ChakraInput
        readOnly={isReadonly}
        ref={inputRef}
        placeholder={`${column.columnDef.header?.toString() ?? ''}...`}
        variant="unstyled"
        type={inputType}
        tabIndex={0}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={() => setOnFocus(true)}
        {...overrides}
      />
    </InputGroup>
  );
};
