import { useColorMode } from '@chakra-ui/system';
import LogoTextDark from './assets/logo-text-dark.svg';
import LogoTextLight from './assets/logo-text-light.svg';

interface Props {
  width?: number;
}

const LogoText = ({ width = 165 }: Props) => {
  const { colorMode } = useColorMode();
  return <img src={colorMode === 'light' ? LogoTextLight : LogoTextDark} width={width} alt="Logo" />;
};

export default LogoText;
