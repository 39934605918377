import { useActionsBase } from './useActionsBase.tsx';
import { MenuGroup, MenuItem, Text } from '@chakra-ui/react';
import { useLayoutBuilder } from '../useLayoutBuilder.tsx';
import Confirm from '@web/components/Confirm.tsx';
import { MenuActions } from './actionsTypes.ts';

/**
 * Hook for pane actions.
 */
export const usePaneActions = () => {
  const { showMenu, closeMenu, renderMenu } = useActionsBase();
  const { resetLayout } = useLayoutBuilder();

  const onReset = () => {
    resetLayout();
    closeMenu();
  };

  const menu = renderMenu(
    <MenuGroup title="Actions">
      <Confirm
        title="Are you sure?"
        body=" This will reset the layout to its initial state. This action cannot be undone."
        onConfirm={resetLayout}
      >
        <MenuItem onClick={onReset}>
          <Text color="red">Reset layout</Text>
        </MenuItem>
      </Confirm>
    </MenuGroup>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
  } as MenuActions;
};
