import { Text, HStack, useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';

interface Props {
  text: string;
  timestamp: string;
  userName: string;
}

const UserMeta = ({ text, timestamp, userName }: Props) => {
  const textColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.500');

  return (
    <HStack>
      <Text fontWeight="bold">{text}</Text>
      <Text color={textColor}>
        {format(timestamp, 'Pp')} by: {userName}
      </Text>
    </HStack>
  );
};

export default UserMeta;
