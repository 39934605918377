import { Box, Icon, Textarea } from '@chakra-ui/react';
import { MdAttachFile } from 'react-icons/md';
import { Control, UseFormRegister } from 'react-hook-form';
import S3FileInput from './S3FileInput';

interface TextareaWithAttachmentProps<T> {
  register: UseFormRegister<T>;
  control: Control<T>;
  textareaName: string;
  textareaPlaceholder?: string;
  required?: boolean;
}

const TextareaWithAttachment = <T extends Record<string, unknown>>({
  register,
  control,
  textareaName,
  textareaPlaceholder,
  required = false,
}: TextareaWithAttachmentProps<T>) => (
  <Box borderWidth={1} mt={2}>
    <Textarea
      borderWidth={0}
      w="full"
      h={30}
      px={3}
      _focus={{ boxShadow: 'none' }}
      resize="none"
      {...register(textareaName, { required: required })}
      placeholder={textareaPlaceholder}
    />
    <Box p={3}>
      <S3FileInput
        control={control}
        name="attachments"
        button={<Icon as={MdAttachFile} fontSize="lg" color="gray.500" />}
      />
    </Box>
  </Box>
);

export default TextareaWithAttachment;
