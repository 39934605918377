import { isValidElement, ReactNode } from 'react';
import { Text, Box } from '@chakra-ui/react';
interface EditableFormTextProps {
  editing: boolean;
  value?: string | null;
  label: string | null;
  children: ReactNode;
}

/**
 * EditableFormText is a wrapper component handles displaying either text or an input field in place.
 */
const EditableFormText = ({ editing, value, label, children }: EditableFormTextProps) => {
  let derivedValue = '';

  if (value) {
    derivedValue = value;
  }
  if (!value && isValidElement(children)) {
    const childElement = children as React.ReactElement;
    derivedValue = childElement.props.value;
  }

  return editing ? (
    <>{children}</>
  ) : (
    <Box>
      <Text color="gray.500" fontSize="md">
        {label ? label + ':' : null}
      </Text>
      <Text fontSize="lg">{derivedValue ?? null}</Text>
    </Box>
  );
};

export default EditableFormText;
