import { useEffect } from 'react';
import { NoteType } from '@senrasystems/senra-ui';
import { useValueGetter } from '@web/hooks/useValueGetter.ts';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes';

export const useUpdateLayoutNotes = (updateLayout: () => void) => {
  const updateLayoutGetter = useValueGetter(updateLayout);
  const { data: flagNotes = [], isSuccess: isFlagNotesSuccess } = useDesignBuildNotes(NoteType.FLAG);

  useEffect(() => {
    if (isFlagNotesSuccess) {
      const updateLayout = updateLayoutGetter() as () => void;
      updateLayout();
    }
  }, [flagNotes, isFlagNotesSuccess, updateLayoutGetter]);
};
