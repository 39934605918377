import { Spinner, Stack, Text } from '@chakra-ui/react';
import Hero from './Hero';

const SpinnerStack = ({ message }: { message?: string }) => (
  <Stack spacing={6} alignItems="center">
    <Spinner size="xl" />
    {message && <Text>{message}</Text>}
  </Stack>
);

const Loading = ({ message, inline }: { message?: string; inline?: boolean }) => {
  if (inline) return <SpinnerStack message={message} />;

  return (
    <Hero>
      <SpinnerStack message={message} />
    </Hero>
  );
};

export default Loading;
