import { useEffect } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import TextAreaInput from '../../../components/form/TextAreaInput';
import LineItemForm from '../new/LineItemForm';
import SelectInput from '../../../components/form/SelectInput';
import SubmitButton from '../../../components/buttons/SubmitButton';
import CancelButton from '../../../components/buttons/CancelButton';
import { Order } from '@web/common/lib';
import { errorToast } from '@web/common/toasts.ts';
import { Box, Button, HStack, Stack } from '@chakra-ui/react';

const EditOrderForm = ({
  order,
  readOnly,
  onCancelEdit,
  onSubmit,
}: {
  order: Order;
  readOnly: boolean;
  onCancelEdit: () => void;
  onSubmit: (formValues: FieldValues) => Promise<void>;
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues: { order } });
  const {
    fields: lineItemFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'order.lineItems',
  });

  useEffect(() => {
    reset({ order });
  }, [order, reset]);

  return (
    <form onSubmit={handleSubmit((order) => onSubmit(order))}>
      <Stack spacing={6}>
        {lineItemFields.map((lineItem, index: number) => {
          return (
            <LineItemForm
              editing={!!getValues(`order.lineItems.${index}.id`)}
              key={lineItem.id}
              index={index}
              control={control}
              register={register}
              readOnly={readOnly}
              onDeleteLine={() => {
                if (lineItemFields.length === 1) {
                  errorToast("Can't delete last line item");
                  return;
                }
                remove(index);
              }}
            />
          );
        })}

        {!readOnly && (
          <Box>
            <Button variant="outline" colorScheme="green" onClick={() => append({ id: '', documents: [] })}>
              + Add Line Item
            </Button>
          </Box>
        )}

        <SelectInput
          label="Material acquisition *"
          options={
            <>
              <option value="consignment">Consignment (customer to provide all material)</option>
              <option value="turnkey">Turnkey (Senra Systems to procure material)</option>
              <option value="mix">Mix (customer to clarify part numbers in notes below)</option>
            </>
          }
          {...register('order.materialAcquisition')}
          disabled={readOnly}
        />

        <TextAreaInput
          label="Quality Requirements*"
          maxLength={1000}
          rows={4}
          {...register('order.qualityRequirements')}
          formControlProps={{ isReadOnly: readOnly }}
        />

        <TextAreaInput
          label="Order notes"
          maxLength={1000}
          rows={4}
          {...register('order.notes')}
          formControlProps={{ isReadOnly: readOnly }}
        />

        <HStack spacing="3px">
          {!readOnly && <SubmitButton text="Save Changes" loading={isSubmitting} />}
          {!readOnly && <CancelButton onClick={onCancelEdit} />}
        </HStack>
      </Stack>
    </form>
  );
};

export default EditOrderForm;
