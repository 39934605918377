import { MouseEvent, useEffect, useState } from 'react';
import Title from '../../../components/Title.tsx';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  useCreateDesignMutation,
  useDeleteDesignMutation,
  useDesignsQuery,
  useUpdateDesignMutation,
} from '../api/queries.ts';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useDesignToast } from '../hooks/useDesignToast.tsx';
import DesignModal, { DesignFormData } from './DesignModal.tsx';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';
import FilterBox from '@web/components/FilterBox.tsx';
import Loading from '@web/components/Loading.tsx';
import DesignActions from './DesignActions.tsx';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';

/**
 * Component that displays the list of Designs.
 * @constructor
 */
const Designs = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';
  const debouncedQuery = useDebouncedValue(query, 500);
  const { showErrorToast } = useDesignToast();
  const { isLoading, data: designs, error } = useDesignsQuery(debouncedQuery);
  const [openDesignModal, setOpenDesignModal] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState<DesignFormData | undefined>(undefined);

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('q', value);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
  };

  // Mutations to add and delete designs
  const { mutate: createDesign, isPending: createDesignPending, error: createDesignError } = useCreateDesignMutation();

  useEffect(() => {
    if (createDesignError) {
      showErrorToast('Error creating design', createDesignError.message);
    }
  }, [createDesignError, showErrorToast]);

  const { mutate: updateDesign, error: updateDesignError } = useUpdateDesignMutation();

  useEffect(() => {
    if (updateDesignError) {
      showErrorToast('Error updating design', updateDesignError.message);
    }
  }, [updateDesignError, showErrorToast]);

  const { mutate: deleteDesign, error: deleteDesignError } = useDeleteDesignMutation();

  useEffect(() => {
    if (deleteDesignError) {
      showErrorToast('Error deleting design', deleteDesignError.message);
    }
  }, [deleteDesignError, showErrorToast]);

  if (error) return;

  const handleOpenForCreate = () => {
    setSelectedDesign(undefined);

    setOpenDesignModal(true);
  };

  const handleCreateDesign = (data: DesignFormData) => {
    createDesign({
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
        tenantId: data.tenant.id,
      },
    });
    setSelectedDesign(undefined);
    setOpenDesignModal(false);
  };

  const handleOpenForUpdate = () => {
    setOpenDesignModal(true);
  };

  const handleUpdateDesign = (data: DesignFormData) => {
    updateDesign({
      designId: selectedDesign?.id || '',
      data: {
        name: data.name,
        partNumber: data.partNumber,
        partRevision: data.partRevision,
        description: data.description,
        lengthUnit: data.lengthUnit,
      },
    });
    setSelectedDesign(undefined);
    setOpenDesignModal(false);
  };

  const handleDeleteDesign = (designId: string) => {
    deleteDesign({ designId });
  };

  return (
    <Box>
      <TopbarNav />
      <Stack padding={8} spacing={8}>
        <Title title={'Designs'} aria-label="page-title">
          <Button onClick={handleOpenForCreate} isLoading={createDesignPending}>
            New Design
          </Button>
        </Title>
        <DesignModal
          isOpen={openDesignModal}
          onClose={() => {
            setOpenDesignModal(false);
            setSelectedDesign(undefined);
          }}
          onCreate={handleCreateDesign}
          onSave={handleUpdateDesign}
          initialData={selectedDesign}
        />
        <HStack gap={6}>
          <FilterBox placeholder="Type to filter" autoFocus value={query} onChange={handleFilterQuery} />
          <Button
            onClick={() => {
              handleFilterQuery('');
            }}
          >
            Clear filters
          </Button>
        </HStack>
        <HStack alignItems="flex-start" spacing={0} height="full">
          <Flex width="full" height="100%" overflowY="auto">
            {isLoading ? (
              <Center height="50vh" width="80vw">
                <Loading message="Loading designs…" />
              </Center>
            ) : (
              <VStack width="full">
                <TableContainer width="full" overflowX="hidden">
                  <Table layout="fixed" variant="senraTable">
                    <Thead>
                      <Tr>
                        <Th w={80}>Tenant</Th>
                        <Th w={80}>Part Number</Th>
                        <Th>Description</Th>
                        <Th w={40}>Length Unit</Th>
                        <Th w={80}>Drawn By</Th>
                        <Th w={40} textAlign="right">
                          Actions
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {designs?.data.map((design) => (
                        <Tr
                          key={design.id}
                          role="group"
                          h="55px"
                          onClick={() => navigate(generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id }))}
                        >
                          <Td>
                            <Text paddingRight={4}>{design.tenant.name}</Text>
                          </Td>
                          <Td>
                            <Flex alignItems="center">
                              {design.partNumber}{' '}
                              <Badge ml={2} bg={'orange.500'} color={'white'}>
                                REV {design.partRevision}
                              </Badge>
                            </Flex>
                          </Td>
                          <Td>
                            {design.description && (
                              <Tooltip label={design.description}>
                                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                  {design.description}
                                </Text>
                              </Tooltip>
                            )}
                          </Td>
                          <Td>{design.lengthUnit}</Td>
                          <Td>
                            {design.creator?.name && (
                              <Tooltip label={design.creator.name}>
                                <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                  {design.creator.name}
                                </Text>
                              </Tooltip>
                            )}
                          </Td>
                          <Td textAlign="right">
                            <DesignActions
                              design={design}
                              onOpen={() => navigate(generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id }))}
                              onEdit={handleOpenForUpdate}
                              onDelete={handleDeleteDesign}
                              onClick={(event: MouseEvent<HTMLDivElement>) => {
                                setSelectedDesign(design);
                                event.stopPropagation();
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            )}
          </Flex>
        </HStack>
      </Stack>
    </Box>
  );
};

export default Designs;
