import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Select, Stack, Box } from '@chakra-ui/react';
import TextInput from '@web/components/form/TextInput.tsx';
import { Passive, PassiveType } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const PassiveFields = ({ editing }: Props) => {
  const { control, register, getValues } = useFormContext<Passive>();
  const {
    fields: connections,
    replace: replaceConnections,
    remove: removeConnections,
  } = useFieldArray({ control, name: 'connections' });

  const handleNumConnectionsChange = (value: string) => {
    removeConnections();

    const num = parseInt(value);
    if (isNaN(num)) return;

    const newConnections = [];
    for (let i = 0; i < num; i++) {
      newConnections.push({ label: '', signalName: '' });
    }
    replaceConnections(newConnections);
  };

  return (
    <Stack spacing={6}>
      <HStack spacing={10}>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Passive Type" value={getValues('subtype') ?? ''}>
            <FormControl>
              <FormLabel>Passive Type</FormLabel>
              <Select isDisabled={!editing} {...register('subtype')}>
                {Object.values(PassiveType).map((val) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Passive Value" value={getValues('passiveValue') ?? ''}>
            <TextInput
              label="Passive Value"
              formControlProps={{ isDisabled: !editing }}
              {...register('passiveValue')}
            />
          </EditableFormText>
        </Box>
      </HStack>
      <EditableFormText
        editing={editing}
        label="Connections"
        value={connections.length ? connections.length.toString() : ''}
      >
        <TextInput
          label="Connections"
          value={connections.length ? connections.length.toString() : ''}
          isDisabled={!editing}
          onChange={(e) => handleNumConnectionsChange(e.target.value)}
          formControlProps={{ w: '100px' }}
        />
      </EditableFormText>
      <Stack>
        {connections.map((connection, index) => (
          <HStack key={connection.id} spacing={10}>
            <Box flex={1}>
              <EditableFormText editing={editing} label="Labels" value={getValues(`connections.${index}.label`) ?? ''}>
                <TextInput
                  label={index === 0 ? 'Labels' : undefined}
                  onFocus={(e) => e.target.select()}
                  formControlProps={{ isDisabled: !editing }}
                  {...register(`connections.${index}.label` as const, { required: true })}
                />
              </EditableFormText>
            </Box>
            <Box flex={1}>
              <EditableFormText
                editing={editing}
                label="Signal Name"
                value={getValues(`connections.${index}.signalName`) ?? ''}
              >
                <TextInput
                  label={index === 0 ? 'Signal Name' : undefined}
                  onFocus={(e) => e.target.select()}
                  formControlProps={{ isDisabled: !editing }}
                  {...register(`connections.${index}.signalName` as const)}
                />
              </EditableFormText>
            </Box>
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
};

export default PassiveFields;
