import { useFormContext, useController } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Select, Stack, Checkbox, Box } from '@chakra-ui/react';
import { Splice, SpliceType } from '@senrasystems/senra-ui';
import MinMaxAwgFields from '../../components/MinMaxAwgFields.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const SpliceFields = ({ editing }: Props) => {
  const { register, getValues, control } = useFormContext<Splice>();
  const { field: insulated } = useController({ name: 'insulated', control });
  const { field: madeOnAssembly } = useController({ name: 'madeOnAssembly', control });

  return (
    <Stack spacing={6}>
      <HStack spacing={10}>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Splice Type" value={getValues('subtype') ?? ''}>
            <FormControl>
              <FormLabel>Splice Type</FormLabel>
              <Select isDisabled={!editing} {...register('subtype')}>
                {Object.values(SpliceType).map((val) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <MinMaxAwgFields editing={editing} />
        </Box>
      </HStack>
      <FormControl>
        <Checkbox
          isDisabled={!editing}
          isChecked={insulated.value}
          onChange={(e) => insulated.onChange(e.target.checked)}
          aria-label="Insulated"
        >
          Insulated
        </Checkbox>
      </FormControl>
      <FormControl>
        <Checkbox
          isDisabled={!editing}
          isChecked={madeOnAssembly.value}
          onChange={(e) => madeOnAssembly.onChange(e.target.checked)}
          aria-label="Made On Assembly"
        >
          Made On Assembly
        </Checkbox>
      </FormControl>
    </Stack>
  );
};

export default SpliceFields;
