import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { NoteType, UUID } from '@senrasystems/senra-ui';
import { useDesignBuildNotes } from '../../../../hooks/useDesignBuildNotes';
import TrianglePosition from '../notes/TrianglePosition.tsx';

export type NoteGroupNodeData = {
  layoutElementId: UUID;
};

export const defaultNoteGroupNodeData: NoteGroupNodeData = {
  layoutElementId: '',
};

export type NoteGroupNodeType = Node<NoteGroupNodeData>;

/**
 * Note Group point node component.
 * @param props
 * @constructor
 */
export const NoteGroupNode = (props: NodeProps<NoteGroupNodeType>) => {
  const {
    data: { layoutElementId },
  } = props;
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);

  const flagNotesForLayoutId = useMemo(
    () => flagNotes.filter((note) => note.layoutElementIds.includes(layoutElementId)),
    [flagNotes, layoutElementId],
  );

  return (
    <Box position="relative">
      <HStack gap={0}>
        {flagNotesForLayoutId.map(({ id, position }) => (
          <TrianglePosition key={id} position={position} />
        ))}
      </HStack>
      <Box visibility="hidden">
        <Handle type="source" position={Position.Right} />
      </Box>
    </Box>
  );
};

export default NoteGroupNode;
