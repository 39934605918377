import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { OptionBase, Select } from 'chakra-react-select';

export interface Option extends OptionBase {
  value: string;
  label: string;
}

interface Props {
  label?: string;
  placeholder?: string;
  options: Option[];
  value: Option | null;
  onChange: (option: Option | null) => void;
}

const SearchableDropdown = ({ label, placeholder, options, onChange, value }: Props) => {
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <Box position="relative">
        <Select<Option>
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          isClearable
          size="sm"
        />
      </Box>
    </FormControl>
  );
};

export default SearchableDropdown;
