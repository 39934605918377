import { CellContext } from '@tanstack/react-table';
import { Text as ChakraText } from '@chakra-ui/react';

export const Text = <T, U extends string | number | readonly string[] | undefined>({
  row: { index },
  column,
  getValue,
}: CellContext<T, unknown>) => {
  return (
    <ChakraText cursor="text" data-testid={`text-${index}-${column.id}`}>
      {getValue<U>()}
    </ChakraText>
  );
};
