import { Design } from '@senrasystems/senra-ui';
import { Box, IconButton, Menu, MenuButton, MenuList, MenuItem, Portal, BoxProps } from '@chakra-ui/react';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props extends BoxProps {
  design: Design;
  onOpen: () => void;
  onEdit: () => void;
  onDelete: (itemId: string) => void;
}
const DesignActions = ({ design, onOpen, onEdit, onDelete, ...rest }: Props) => {
  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={IconButton} icon={<SlOptionsVertical />} />
        <Portal>
          <MenuList>
            <MenuItem onClick={() => onOpen()}>Edit Design</MenuItem>
            <MenuItem onClick={() => onEdit()}>Edit Details</MenuItem>
            <MenuItem color="red.300" onClick={() => onDelete(design.id)}>
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};
export default DesignActions;
