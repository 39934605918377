import { FormProvider, useFormContext, useForm } from 'react-hook-form';
import { IconButton, Td, Tr, useDisclosure } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { MdCancel } from 'react-icons/md';
import { apiGetParts } from '@web/api/parts-api.ts';
import { useParts, usePart } from '../api/queries.ts';
import { IncludedAccessory, Part, PartType } from '@senrasystems/senra-ui';
import CreatableTypeAheadSelectInput from '@web/components/form/CreatableTypeAheadSelectInput.tsx';
import PartsLibraryModal, { Mode } from './PartsLibraryModal.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  index: number;
  partType: PartType;
  editing: boolean;
  remove: (index: number) => void;
  update: (index: number, value: IncludedAccessory) => void;
  customColumns?: { header: string; renderFn: (part: Part) => React.ReactNode }[];
}

const IncludedAccessoryRow = ({ index, partType, editing, remove, update, customColumns }: Props) => {
  const { getValues } = useFormContext<Part>();
  const accessory = getValues(`includedAccessories.${index}`);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: partSearch } = useParts(partType);
  const { data: initialSelectedPart } = usePart(accessory.partId);
  const formMethods = useForm<Part>();

  if (!accessory || accessory._destroy || accessory.type !== partType) return null;

  let selectedPart = initialSelectedPart;
  const searchResults = partSearch?.data ?? [];

  const toOption = (part: Part) => ({
    label: part.partNumber,
    value: part,
  });
  const defaultOptions = searchResults.map(toOption);
  const selectedOption = selectedPart ? toOption(selectedPart) : null;

  const handleSearch = async (inputValue: string) => {
    const res = await apiGetParts(partType, inputValue);
    if (res) {
      return res.data.map(toOption);
    }
    return [];
  };

  const handleCreate = (inputValue: string) => {
    formMethods.reset({ partNumber: inputValue });
    onOpen();
    return Promise.resolve(null);
  };

  const handleOnChange = (newOption: SingleValue<{ label: string; value: Part }>) => {
    if (newOption) {
      selectedPart = newOption.value as Part;
      update(index, { ...accessory, partNumber: newOption.value.partNumber, partId: newOption.value.id });
    }
  };

  const handleRemove = () => {
    if (accessory.id) {
      update(index, { ...accessory, _destroy: true });
    } else {
      remove(index);
    }
  };

  return (
    <>
      {isOpen && (
        <Tr>
          <Td>
            <FormProvider {...formMethods}>
              <PartsLibraryModal
                partType={partType}
                mode={Mode.CREATE}
                isOpen={isOpen}
                onClose={onClose}
                onPartCreated={(part) => part && handleOnChange(toOption(part))}
              />
            </FormProvider>
          </Td>
        </Tr>
      )}
      <Tr>
        <Td w="250px">
          <EditableFormText
            editing={editing}
            label={null}
            value={selectedPart?.partNumber ?? 'error your part is not in the database'}
          >
            <CreatableTypeAheadSelectInput
              label=""
              placeholder="Search"
              editing={editing}
              value={selectedOption}
              defaultOptions={defaultOptions}
              isMulti={false}
              onChange={handleOnChange}
              onCreateOption={handleCreate}
              loadOptions={handleSearch}
              isValidNewOption={() => true}
              createOptionPosition="first"
              formatCreateLabel={(inputValue) => `Create ${inputValue || `New ${partType}`}`}
              autoFocus
              maxMenuHeight={80}
              menuPortalTarget={document.getElementById('portal')}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </EditableFormText>
        </Td>
        {customColumns?.map((columnDef) =>
          selectedPart ? (
            <Td key={`${columnDef.header}-${index}`}>{columnDef.renderFn(selectedPart)}</Td>
          ) : (
            <Td key={`${columnDef.header}-${index}`} />
          ),
        )}
        <Td>
          <IconButton
            isRound
            variant="ghost"
            aria-label={`Remove ${partType}`}
            icon={<MdCancel size="1em" />}
            visibility={editing ? 'initial' : 'hidden'}
            onClick={handleRemove}
          />
        </Td>
      </Tr>
    </>
  );
};

export default IncludedAccessoryRow;
