import { ImLink } from 'react-icons/im';

/**
 * Link icon (adding @ts-expect-error to suppress error for _hover SystemStyleFunction property: https://github.com/chakra-ui/chakra-ui/issues/6261)
 * @param fill
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const Link = ({ fill }: { fill: string }) => <ImLink color={fill} _hover={{ opacity: 0.6 }} />;

export default Link;
