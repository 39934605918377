export const colorDelimiter = '/';
export const partNumberDelimiter = '.';

// Accepted Mil Spec colors and some other ones too.
// See bottom of: https://www.prowireusa.com/p-1607-m22759-32-12-5-green-wire-tefzel-12-awg.html
export const milSpecColors = {
  BLACK: '#000000',
  BROWN: '#7F3E1A',
  RED: '#FD191D',
  ORANGE: '#FD8322',
  YELLOW: '#FFFF2E',
  GREEN: '#0D7410',
  BLUE: '#0C04FA',
  VIOLET: '#740C73',
  PINK: '#FFC0DB',
  GREY: '#757575',
  GRAY: '#757575',
  WHITE: '#FFFFFF',
};

//Colors natively supported by Chakra UI
export const chakraColors = [
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Purple',
  'Pink',
  'Gray',
  'Teal',
  'Cyan',
];

export const splitColors = (value: string) =>
  value.includes(colorDelimiter) || value.includes(partNumberDelimiter)
    ? value
        .slice(value.indexOf(partNumberDelimiter) > 0 ? value.indexOf(partNumberDelimiter) + 1 : 0)
        .split(colorDelimiter)
    : [value];

export const getColors = (value?: string) => {
  if (!value) return [];
  const splitValues = splitColors(value);
  return splitValues?.filter((v) => Object.keys(milSpecColors).includes(v.toUpperCase()));
};
