import { ComponentType, KeyboardEvent, useState } from 'react';
import { Box, Center, HStack, Input, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import { BuildNote, UUID } from '@senrasystems/senra-ui';
import PositionTd from './PositionTd';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import EditableText from '../../../../components/EditableText';

interface Props {
  PositionComponent?: ComponentType<{ position: number }>;
  notes?: BuildNote[];
  addNotePlaceholder?: string;
  onAddNote: (body: string) => void;
  onEditNote: (noteId: UUID, body: string) => void;
  onDeleteNote: (noteId: UUID) => void;
}

const NotesTable = ({
  PositionComponent,
  notes = [],
  addNotePlaceholder = 'Add note...',
  onAddNote,
  onEditNote,
  onDeleteNote,
}: Props) => {
  const [inputValue, setInputValue] = useState('');

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onAddNote(inputValue);
      setInputValue('');
    }
  };

  return (
    <TableContainer color="gray.500">
      <Table>
        <Tbody>
          {notes.map(({ id, position, body }) => (
            <Tr key={id}>
              <PositionTd>
                {PositionComponent ? <PositionComponent position={position} /> : <Center>{position}</Center>}
              </PositionTd>
              <Td role="group">
                <HStack position="relative" w="full">
                  <Box>
                    <EditableText
                      text={body}
                      onEdit={(updatedBody) => {
                        if (body !== updatedBody) {
                          onEditNote(id, updatedBody);
                        }
                      }}
                      size="sm"
                    />
                  </Box>
                  <DeleteIconButton
                    aria-label={`delete note ${position}`}
                    position="absolute"
                    right={0}
                    display="none"
                    _groupHover={{ display: 'flex' }}
                    onClick={() => onDeleteNote(id)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
          <Tr>
            <PositionTd />
            <Td borderBottom="unset">
              <Input
                variant="unstyled"
                placeholder={addNotePlaceholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleEnterPress}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default NotesTable;
