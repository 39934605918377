import { BaseEdge, Edge, EdgeLabelRenderer, EdgeProps } from '@xyflow/react';
import MeasurementEdgeLabel from './MeasurementEdgeLabel.tsx';
import { useMeasurementPaths } from './useMeasurementPaths.tsx';

export type MeasurementEdgeData = {
  flipped: boolean;
  distanceFromEdge: number;
  measurement: number;
};

export const defaultMeasurementEdgeData: MeasurementEdgeData = {
  flipped: false,
  distanceFromEdge: 60,
  measurement: 0,
};

export type MeasurementEdge = Edge<MeasurementEdgeData>;

/**
 * Measurement edge component representing a measurement line between two nodes.
 * @constructor
 * @param props
 */
const MeasurementEdge = (props: EdgeProps<MeasurementEdge>) => {
  const { id: edgeId, data = defaultMeasurementEdgeData } = props;
  const { flipped, distanceFromEdge, measurement } = data;

  const { startLinePath, measurementLinePath, endLinePath, midpoint } = useMeasurementPaths({
    sourcePosition: { x: props.sourceX, y: props.sourceY },
    targetPosition: { x: props.targetX, y: props.targetY },
    distanceFromEdge: distanceFromEdge,
    flipped: flipped,
  });

  return (
    <>
      <EdgeLabelRenderer>
        <MeasurementEdgeLabel edgeId={edgeId} position={midpoint} measurement={measurement} />
      </EdgeLabelRenderer>
      <BaseEdge path={startLinePath} style={props.style} />
      <BaseEdge
        path={measurementLinePath}
        markerStart={'url(#measurement-arrow)'} // this is a reference to a marker in SVGDefinitions.tsx
        markerEnd={'url(#measurement-arrow)'} // this is a reference to a marker in SVGDefinitions.tsx
      />
      <BaseEdge path={endLinePath} style={props.style} />
    </>
  );
};

export default MeasurementEdge;
