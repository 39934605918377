import { CellContext } from '@tanstack/react-table';
import {
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

export const SenraNumberInput = <T, U extends number>(
  props: CellContext<T, U>,
  isReadonly?: boolean,
  overrides?: NumberInputProps,
) => {
  const { getValue, row, column, table } = props;
  const { index, depth } = row;
  const initialValue = getValue<number>();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState<number>();
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    setOnFocus(false);
    if (value !== initialValue) {
      table.options.meta?.updateData(index, depth, column.id, value ?? '');
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (initialValue) setValue(parseInt(`${initialValue}`));
  }, [initialValue]);

  useEffect(() => {
    if (onFocus) {
      inputRef.current?.focus();
    }
  }, [onFocus]);

  const onNumericChange: (valueAsString: string, _valueAsNumber: number) => void = (
    valueAsString: string,
    _valueAsNumber: number,
  ): void => {
    setValue(valueAsString ? parseInt(valueAsString) : undefined);
  };

  return (
    <InputGroup height="full" alignItems="center" gap={2}>
      <NumberInput
        tabIndex={0}
        value={value}
        onChange={onNumericChange}
        onBlur={onBlur}
        onFocus={() => setOnFocus(true)}
        isReadOnly={isReadonly}
        {...overrides}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </InputGroup>
  );
};
