import ManagedWindow from '../../components/ManagedWindow.tsx';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useBOM } from '../../hooks/useBOM.tsx';
import BOMItem from './components/BOMItem.tsx';
import { useDesign } from '../../hooks/useDesign.tsx';
import { BiCopyAlt } from 'react-icons/bi';
import { useDesignToast } from '../../hooks/useDesignToast.tsx';
import Papa from 'papaparse';
import BOMItemDetails from './components/BOMItemDetails.tsx';

/**
 * BOM component displays the Bill of Materials for the design.
 * @constructor
 */
const BOM = () => {
  const { designId, setSelectedBOMItem } = useDesign();
  const { bom, bomCSV, isLoading, error } = useBOM(designId);
  const { showSuccessToast, showErrorToast } = useDesignToast();

  if (isLoading || error) return null;

  if (!bom.length) {
    return (
      <ManagedWindow title="Bill of Materials" routeName={RouteNames.DESIGNS.BOM}>
        <Box p={8}>No BOM items found.</Box>
      </ManagedWindow>
    );
  }

  const copyToClipboard = async () => {
    try {
      const csv = Papa.unparse(bomCSV, { delimiter: '\t' });
      await navigator.clipboard.writeText(csv);
      showSuccessToast('Success', 'BOM copied to clipboard');
    } catch (err) {
      showErrorToast('Error', 'Failed to copy BOM to clipboard');
    }
  };

  // Handle the change event for the accordion.
  const handleAccordionChange = (indices: number | number[]) => {
    // If no items are expanded, clear the selected BOM item.
    if (indices === -1 || (Array.isArray(indices) && indices.length === 0)) {
      setSelectedBOMItem(null);
    }
  };

  return (
    <ManagedWindow
      title="Bill of Materials"
      routeName={RouteNames.DESIGNS.BOM}
      actions={<BiCopyAlt fontSize="1.5em" cursor="pointer" onClick={copyToClipboard} />}
    >
      {({ editMode }) => (
        <Box h="full">
          {bom.length === 0 && (
            <Box p={8}>No BOM items found. Add a design part in the Assembly Navigator to see BOM items.</Box>
          )}
          <BOMItem header={true} py={1} />
          <Accordion allowToggle onChange={handleAccordionChange}>
            {bom.map((bomItem, index) => (
              <AccordionItem key={bomItem.partNumber}>
                {({ isExpanded }) => (
                  <Box>
                    <AccordionButton as={Box} px={0} py={0} onClick={() => setSelectedBOMItem(bomItem.part)}>
                      <BOMItem key={index} bomItem={bomItem} isExpanded={isExpanded} editMode={editMode} />
                    </AccordionButton>
                    <AccordionPanel px={0} py={0} bg="gray.50">
                      <BOMItemDetails bomItem={bomItem} editMode={editMode} />
                    </AccordionPanel>
                  </Box>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      )}
    </ManagedWindow>
  );
};

export default BOM;
