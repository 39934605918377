import Loading from '../../../components/Loading';
import SelectInput from '../../../components/form/SelectInput';
import SubmitButton from '../../../components/buttons/SubmitButton';
import TextAreaInput from '../../../components/form/TextAreaInput';
import TextInput from '../../../components/form/TextInput';
import TypeAheadSelectInput from '../../../components/form/TypeAheadSelectInput';
import { Box, Button, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Controller, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { errorToast } from '@web/common/toasts.ts';
import { apiCreateOrder } from '@web/api/order-api.ts';
import { CreateOrderDto } from '@web/common/lib';
import { RouteNames } from '@web/consts/routeNames';
import LineItemForm from './LineItemForm';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAdminTenants } from '@web/queries/admin.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import ErrorText from '../../../components/form/ErrorText';
import { SingleValue } from 'chakra-react-select';

const CreateOrderForm = () => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [value, setValue] = useState<SingleValue<{ label: string; value: string }>>(null);

  const { data: user } = useCurrentUser();
  const { isLoading, data } = useAdminTenants('1', query);

  const options = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : data.data.map((tenant: { id: string; name: string }) => ({
        value: tenant.id,
        label: tenant.name,
      }));

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      order: { materialAcquisition: '', lineItems: [{ id: '', documents: [] }], qualityRequirements: '' },
    },
  });
  const {
    fields: lineItemFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'order.lineItems',
  });

  const validateFiles = (lineItems) => {
    const emptyDocuments = lineItems.filter((li) => li.documents.length === 0);
    return emptyDocuments.length === 0;
  };

  const onSubmit = async (formData: FieldValues) => {
    if (!validateFiles(formData.order.lineItems)) {
      errorToast('Some documents are missing.');
      return;
    }

    const results = await apiCreateOrder({
      tenantId: formData.order.tenantId,
      name: formData.order.name,
      materialAcquisition: formData.order.materialAcquisition,
      qualityRequirements: formData.order.qualityRequirements,
      notes: formData.order.notes,
      lineItems: formData.order.lineItems,
    } as unknown as CreateOrderDto);

    if (results) {
      navigate(RouteNames.ORDERS.SHOW.replace(':orderId', results.id));
    }
  };

  const getFieldError = (fieldName: string, label: string) => {
    return errors[fieldName] ? `${label} is required.` : null;
  };

  if (!user) {
    return <Loading message="Loading user…" />;
  }

  const handleBlur = () => {
    setError(value?.label.trim().length ? '' : 'Tenant required');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        {user.employee && (
          <FormControl>
            <FormLabel>{'Tenant * '}</FormLabel>
            <Controller
              name={'order.tenantId'}
              control={control}
              rules={{ required: true, onBlur: handleBlur }}
              render={({ field: { onBlur, onChange, ref } }) => {
                const handleChange = (selectedOption?: SingleValue<{ label: string; value: string }>) => {
                  onChange(selectedOption?.value ?? null);
                  setError('');
                  setValue(selectedOption ?? { label: '', value: '' });
                };
                return (
                  <TypeAheadSelectInput
                    options={options}
                    onChange={handleChange}
                    querySetter={setQuery}
                    value={value}
                    noOptionsMessage={() => 'No matching tenants'}
                    onBlur={onBlur}
                    placeholder="Select a tenant…"
                    ref={ref}
                  />
                );
              }}
            />
            <ErrorText>{error}</ErrorText>
          </FormControl>
        )}
        <TextInput
          label="Order name *"
          error={getFieldError('name', 'Order name')}
          {...register('order.name', { required: true })}
        />

        {lineItemFields.map((lineItem, index) => (
          <LineItemForm
            key={lineItem.id}
            index={index}
            control={control}
            register={register}
            onDeleteLine={() => {
              if (lineItemFields.length === 1) {
                errorToast("Can't delete last line item");
                return;
              }
              remove(index);
            }}
          />
        ))}
        <Box>
          <Button variant="outline" colorScheme="green" onClick={() => append({ id: '', documents: [] })}>
            + Add Line Item
          </Button>
        </Box>

        <SelectInput
          label="Material acquisition *"
          options={
            <>
              <option disabled>Please select one...</option>
              <option value="consignment">Consignment (customer to provide all material)</option>
              <option value="turnkey">Turnkey (Senra Systems to procure material)</option>
              <option value="mix">Mix (customer to clarify part numbers in notes below)</option>
            </>
          }
          {...register('order.materialAcquisition', { required: true })}
          error={getFieldError('materialAcquisition', 'Material acquisition')}
        />

        <TextAreaInput
          label="Quality Requirements*"
          placeholder="Enter any special quality requirements"
          maxLength={1000}
          rows={4}
          {...register('order.qualityRequirements', { validate: (value) => value.trim().length > 0 })}
        />

        <TextAreaInput
          label="Notes"
          placeholder="Additional notes for the quote"
          maxLength={1000}
          rows={4}
          {...register('order.notes')}
        />

        <Box>
          <SubmitButton text="Continue" loading={isSubmitting} />
        </Box>
      </Stack>
    </form>
  );
};

export default CreateOrderForm;
