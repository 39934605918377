import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, Center, Stack, Table, Tbody, Th, Tr, Thead } from '@chakra-ui/react';
import { Part } from '@senrasystems/senra-ui';
import PartWireRow from './PartWireRow.tsx';

interface Props {
  isEditing: boolean;
  isPigtailParent?: boolean;
}

const PartWireTable = ({ isEditing, isPigtailParent }: Props) => {
  const { control, getValues } = useFormContext<Part>();
  const { fields: partWires, append, remove, replace, update } = useFieldArray({ control, name: 'partWires' });

  const activePartWires = partWires.filter((partWire) => !partWire._destroy);

  const handleAddPartWire = () => {
    append({ position: activePartWires.length + 1 });
  };

  const afterRemoveCallback = () => {
    // Preserve the position order after a partWire is removed
    let position = 1;
    replace(
      getValues('partWires').map((partWire) => {
        if (partWire._destroy) return partWire;
        return { ...partWire, position: position++ };
      }),
    );
  };

  return (
    <Stack spacing={2}>
      <Center>
        <Table>
          {!!activePartWires.length && (
            <Thead>
              <Tr>
                <Th>Position</Th>
                <Th>Wire</Th>
                <Th>Gauge</Th>
                <Th>Colors</Th>
                {isPigtailParent && <Th>Signal Name</Th>}
                <Th>Twisting Group</Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            {partWires.map((partWire, index) => (
              <PartWireRow
                key={partWire.id}
                partWireIndex={index}
                isEditing={isEditing}
                remove={remove}
                update={update}
                afterRemoveCallback={afterRemoveCallback}
                isPigtailParent={isPigtailParent}
              />
            ))}
          </Tbody>
        </Table>
      </Center>
      <Box>
        <Button visibility={isEditing ? 'initial' : 'hidden'} onClick={handleAddPartWire}>
          Add Conductor
        </Button>
      </Box>
    </Stack>
  );
};

export default PartWireTable;
