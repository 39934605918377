import { useEffect } from 'react';
import { Accordion, AccordionProps } from '@chakra-ui/react';
import { Part, PartType } from '@senrasystems/senra-ui';
import CreatePartButton from '../../AssemblyNavigator/components/CreatePartButton.tsx';
import PartSearchListItem from './PartSearchListItem.tsx';

interface Props extends AccordionProps {
  parts: Part[];
  setSearchValue: (value: string) => void;
  expandedIndex: number;
  setExpandedIndex: (index: number) => void;
  expandedPartId: string | null;
  setExpandedPartId: (id: string | null) => void;
  showCreateButton?: boolean;
  filterType: PartType;
}

/**
 * PartSearchList component displays a list of parts in an accordion.
 * @param parts
 * @param setSearchValue
 * @param expandedIndex
 * @param setExpandedIndex
 * @param expandedPartId
 * @param setExpandedPartId
 * @param showCreateButton
 * @param filterType
 * @param connectorPart
 * @param rest
 * @constructor
 */
const PartSearchList = ({
  parts,
  setSearchValue,
  expandedIndex,
  setExpandedIndex,
  expandedPartId,
  setExpandedPartId,
  showCreateButton,
  filterType,
  ...rest
}: Props) => {
  const expandedPartIndex = parts.findIndex((part) => part.id === expandedPartId);

  useEffect(() => {
    if (expandedPartIndex !== -1) {
      setExpandedIndex(expandedPartIndex);
    }
  }, [expandedPartIndex, setExpandedIndex]);

  const setIndex = (expandedIndex: number) => {
    setExpandedIndex(expandedIndex);
  };

  const resetIndex = () => {
    setExpandedIndex(-1);
  };

  const afterPartCreated = (part: Part) => {
    // Set search value to new part number to
    // have it show up in the search results
    setSearchValue(part.partNumber);
    setExpandedPartId(part.id);
  };

  return (
    <Accordion allowToggle {...rest} index={expandedIndex} onChange={setIndex}>
      {parts.map((part) => (
        <PartSearchListItem
          key={part.id}
          role="group"
          part={part}
          afterDeleteCallback={resetIndex}
          afterUpdateCallback={setExpandedPartId}
        />
      ))}
      <CreatePartButton
        afterCreateCallback={afterPartCreated}
        display={showCreateButton ? 'inherit' : 'none'}
        filterType={filterType}
      />
    </Accordion>
  );
};

export default PartSearchList;
