import { MouseEvent, ReactNode } from 'react';
import { BOMItem as Item, BOMItemUsage } from '@senrasystems/senra-ui';
import { Box, HStack, Icon, StackProps, Text, TextProps, useDisclosure } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { Part } from '@senrasystems/senra-ui';
import PartSearchModal from '../../PartSearch';
import AddAlternateButton from './AddAlternateButton.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';

interface Props extends StackProps {
  bomItem?: Item;
  header?: boolean;
  isExpanded?: boolean;
  editMode?: boolean;
}

/**
 * BOMItem component displays a single row of the Bill of Materials.
 * @param bomItem
 * @param header
 * @param isExpanded
 * @param onAddAlternate
 * @param rest
 * @constructor
 */
const BOMItem = ({ bomItem, header, isExpanded, editMode = false, ...rest }: Props) => {
  const { isSelectedBOMItem, selectedDesignPart } = useDesign();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isSelected = bomItem?.part && isSelectedBOMItem(bomItem?.part);

  if (!header && !bomItem) {
    throw new Error('BOMItem: bomItem is required, if header is false.');
  }

  const color = header ? 'gray.600' : 'gray.500';
  const fontWeight = header ? '500' : 'normal';
  const borderColor = 'gray.200';

  // Convert an array of parts to a string of part names.
  const usagesToString = (usages: BOMItemUsage[] | undefined) => {
    if (!usages) return '';
    return usages.map((p) => p.name).join(', ');
  };

  // Handle the close event of the modal.
  const handleAddAlternateClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isSelected) {
      event.stopPropagation();
    }
    onOpen();
  };

  // Render a text element with a label or value.
  const renderText = (label: string, value: number | string | undefined | ReactNode, textProps: TextProps) => {
    return (
      <Text as={Box} isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" {...textProps}>
        {header ? label : value}
      </Text>
    );
  };

  // Render the add alternate button.
  const renderAddAlternateButton = (part: Part) => {
    if (!bomItem) return;
    if (bomItem.alternates.some((alternate) => alternate.alternatePart.id === part.id)) return;
    return <AddAlternateButton part={part} preferredPart={bomItem.part} visibility={editMode ? 'visible' : 'hidden'} />;
  };

  return (
    <HStack
      w="full"
      color={color}
      fontWeight={fontWeight}
      borderColor={borderColor}
      p={1}
      gap={4}
      role="group"
      bg={isExpanded ? 'blue.100' : 'transparent'}
      {...rest}
    >
      <Box
        w="5px"
        h="24px"
        bg={
          bomItem?.part && selectedDesignPart?.partData.partNumber === bomItem?.partNumber ? 'blue.200' : 'transparent'
        }
      />
      <Icon
        as={isExpanded ? ChevronDownIcon : ChevronRightIcon}
        mb={1}
        mr={1}
        visibility={header || !editMode ? 'hidden' : 'visible'}
      />
      {renderText('ID', bomItem?.itemNumber, { flex: 1 })}
      {renderText('Type', bomItem?.displayType, { flex: 2 })}
      {renderText('Qty', bomItem?.quantity, { flex: 1, textAlign: 'center' })}
      {renderText('Unit', bomItem?.unit, {
        flex: 1,
        textAlign: 'center',
      })}
      {renderText('Part Number', bomItem?.partNumber, { flex: 2 })}
      {renderText('Usage', usagesToString(bomItem?.usages), { flex: 4 })}
      {renderText('Description', bomItem?.description, { flex: 8 })}
      {renderText('Manufacturer', bomItem?.manufacturer, { flex: 4 })}
      {renderText('Alts', bomItem?.alternates.length, { flex: 1, textAlign: 'center' })}
      <AddIconButton
        aria-label={'Add alternate part'}
        variant="secondary"
        visibility="hidden"
        _groupHover={{ visibility: !header && editMode ? 'visible' : 'hidden' }}
        onClick={handleAddAlternateClick}
      />
      <PartSearchModal
        isOpen={isOpen}
        onClose={onClose}
        partTypeFilters={bomItem ? [bomItem.part.partData.type] : []}
        renderPartAction={renderAddAlternateButton}
      />
    </HStack>
  );
};

export default BOMItem;
