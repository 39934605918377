import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { ConnectorNode } from '../../components/nodes/ConnectorNode.tsx';
import { GraphOperation } from '../../graph/Operations.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { MenuActions } from './actionsTypes.ts';
import { NodeType } from '../../types.ts';
import { useFlagNoteActions } from './useFlagNoteActions.tsx';

/**
 * Hook for connector actions.
 */
export const useConnectorActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const node = element as ConnectorNode;

  // Flag Notes
  const { flagNoteActions } = useFlagNoteActions(node?.id);

  // Data properties
  const rotateLock = node?.data?.rotateLock ?? false;
  const angle = node?.data?.angle.toString() ?? '0';

  const onRotateLock = (value: boolean) => {
    const params: GraphOperation = { type: 'UpdateNodeData', params: { nodeId: node.id, data: { rotateLock: value } } };
    executeOperation(params);
    closeMenu();
  };

  const onChangeDirection = (angle: number) => {
    const params: GraphOperation = { type: 'UpdateNodeData', params: { nodeId: node.id, data: { angle } } };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        {!rotateLock ? (
          <MenuItem onClick={() => onRotateLock(true)}>Lock rotation</MenuItem>
        ) : (
          <MenuItem onClick={() => onRotateLock(false)}>Unlock rotation</MenuItem>
        )}
      </MenuGroup>
      {rotateLock && (
        <>
          <MenuDivider />
          <MenuOptionGroup defaultValue={angle} title="Face connector:" type="radio">
            <MenuItemOption onClick={() => onChangeDirection(180)} value="180">
              Left
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(0)} value="0">
              Right
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(270)} value="270">
              Up
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(90)} value="90">
              Down
            </MenuItemOption>
          </MenuOptionGroup>
        </>
      )}
      <MenuDivider />
      {flagNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Connector,
  } as MenuActions;
};
