import { errorToast } from '../common/toasts';
import { getUrl, doRequest } from '../common/api';
import { IBestOffersRequest, IBestOffersResponse, PartOffers } from '../models/Procurement.model';

export async function apiFindBestOffers(bestOffersRequest: IBestOffersRequest): Promise<PartOffers[]> {
  const bestOffersResponse: IBestOffersResponse | undefined = await doRequest(
    'POST',
    getUrl('/api/v1/procurement/best_offers'),
    JSON.stringify(bestOffersRequest),
  );

  if (!bestOffersResponse) {
    errorToast('No response from server');
  } else if (bestOffersResponse?.message) {
    errorToast(bestOffersResponse.message);
  } else if (bestOffersResponse.data) {
    return bestOffersResponse.data.map((offers) => new PartOffers(offers));
  }

  return [];
}
