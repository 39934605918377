import { Badge, ChakraProps } from '@chakra-ui/react';
import { PartStatus } from '@senrasystems/senra-ui';

interface Props extends ChakraProps {
  status: PartStatus;
}

const StatusBadge = ({ status, ...rest }: Props) => {
  let colorScheme;
  if (status === PartStatus.RELEASED) colorScheme = 'blue';
  if (status === PartStatus.DELETED) colorScheme = 'black';

  return (
    <Badge colorScheme={colorScheme} variant="solid" {...rest}>
      {status}
    </Badge>
  );
};

export default StatusBadge;
