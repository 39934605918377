import { FormProvider, useForm } from 'react-hook-form';
import PartsLibraryDashboard from '../../components/PartsLibraryDashboard.tsx';
import { Backshell, PartType } from '@senrasystems/senra-ui';

const BackshellsDashboard = () => {
  const methods = useForm<Backshell>({ defaultValues: { type: PartType.BACKSHELL } });

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.BACKSHELL} />
    </FormProvider>
  );
};

export default BackshellsDashboard;
