import { FieldValues, useController, useForm } from 'react-hook-form';
import { apiCreateOrderComment } from '../../api/order-comments-api';
import CancelButton from '../buttons/CancelButton';
import { Box, Button, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import TextareaWithAttachment from '../form/TextareaWithAttachment';

interface Props {
  isEmployee: boolean;
  orderId: string;
  reloadComments: () => void;
}

export default function PostOrderCommentForm({ isEmployee, orderId, reloadComments }: Props) {
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isSubmitting },
  } = useForm({ defaultValues: { body: '', attachments: [], internal: true } });
  const { field } = useController({ name: 'internal', control });
  const isInternal = getValues('internal');

  async function onSubmit(formData: FieldValues) {
    const comment = await apiCreateOrderComment(orderId, {
      body: formData.body,
      attachments: formData.attachments,
      internal: isEmployee ? formData.internal : false,
    });
    if (comment) {
      reset();
      reloadComments();
    }
  }

  function onCancel() {
    reset();
  }

  return (
    <Box pb={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextareaWithAttachment<{
            body: string;
            attachments: never[];
          }>
            required
            control={control}
            register={register}
            textareaName="body"
            textareaPlaceholder="Comment…"
          />
          <HStack gap={4}>
            {!isEmployee && (
              <>
                <Button isLoading={isSubmitting} isDisabled={!isValid} size="sm" type="submit">
                  {isInternal ? 'Comment' : 'External Comment'}
                </Button>
                <CancelButton size="sm" onClick={onCancel} />
              </>
            )}
            {isEmployee && (
              <>
                <Button
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                  size="sm"
                  type="submit"
                  colorScheme={isInternal ? 'gray' : 'red'}
                >
                  {isInternal ? 'Comment' : 'External Comment'}
                </Button>
                <CancelButton size="sm" onClick={onCancel} />
                <HStack>
                  <Switch size="sm" isChecked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                  {isInternal ? (
                    <Text>Internal comment (only visible to Senra employees)</Text>
                  ) : (
                    <Text>External comment (visible to customer and sends email notification)</Text>
                  )}
                </HStack>
              </>
            )}
          </HStack>
        </Stack>
      </form>
    </Box>
  );
}
