import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { appName } from '../common/names';
import { pageTitles } from '../AppRoutes';

const separator = ' · ';

export function setDocumentTitle(breadcrumbs: string[] | null[] | undefined, includeAppName = true) {
  const envPrefix =
    import.meta.env.VITE_ENVIRONMENT === 'staging'
      ? `[${import.meta.env.VITE_ENVIRONMENT.toUpperCase() as string}] `
      : '';

  if (!breadcrumbs || breadcrumbs.length === 0 || (breadcrumbs.length === 1 && breadcrumbs[0] === null)) {
    document.title = envPrefix + appName;
  } else {
    let title = breadcrumbs.join(separator);
    if (includeAppName) title += separator + appName;
    document.title = envPrefix + title;
  }
}

export default function Root() {
  const location = useLocation();

  useEffect(() => {
    const breadcrumbs = pageTitles.get(location.pathname);
    setDocumentTitle(breadcrumbs);
  }, [location]);

  return <Outlet />;
}
