import { CellContext } from '@tanstack/react-table';
import { usdCurrencyFormatter } from '../../../common/util.ts';
import { Text } from '@chakra-ui/react';

export const CurrencyUSD = <T, U extends string | number | undefined>({ getValue }: CellContext<T, unknown>) => {
  let val = getValue<U>() ?? '';

  const parsed = parseFloat(val.toString());

  if (!isNaN(parsed)) {
    val = usdCurrencyFormatter.format(parsed);
  }

  return <Text>{val}</Text>;
};
