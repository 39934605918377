import { useCallback, useMemo, useState } from 'react';
import { Box, Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import ConnectorIcon from '../../components/ConnectorIcon.tsx';
import { useDesign } from '../../hooks/useDesign.tsx';
import ConnectionItem from './components/ConnectionItem.tsx';
import { Connection, isConnectionTarget, UUID } from '@senrasystems/senra-ui';
import { useWiringList } from './hooks/useWiringList.tsx';

/**
 * WiringList component displays the selected design part and a list of connections.
 * @constructor
 */
const WiringList = () => {
  // Get the selected design part from the context.
  const { selectedDesignPart, selectedConnection, setSelectedConnection, isLoading, error } = useDesign();

  // Get this map of connections by source ID to lookup existing connections.
  const { wiringList } = useWiringList();

  // State for the active connection point being edited.
  const [activeEditRow, setActiveEditRow] = useState<UUID | null>(null);

  // State for the draft connection to add, the value is the row id the draft will be placed under.
  const [draftRow, setDraftRow] = useState<UUID | null>(null);

  // Enter edit mode for a connection point.
  const startRowEdit = useCallback((id: UUID) => setActiveEditRow(id), []);

  // Enter edit mode for an additional connection to a connection point.
  const startDraftEdit = useCallback((id: string) => setDraftRow(id), []);

  // Exit editing.
  const stopEditing = useCallback(() => {
    setActiveEditRow(null);
    setDraftRow(null);
  }, []);

  // Handle selecting a connection.
  const handleSelectConnection = useCallback(
    (connection: Connection | undefined) => {
      if (connection) {
        setSelectedConnection(connection);
      }
    },
    [setSelectedConnection],
  );

  /**
   * Render the selected design part.
   */
  const renderSelectedDesignPart = useMemo(
    () => (
      <HStack w="full" p={4}>
        <Text color="gray.900" fontWeight="bold" mr={2}>
          {selectedDesignPart?.name}
        </Text>
        <Box display="flex">
          <ConnectorIcon color="gray.500" w="8px" h="8px" />
        </Box>
        <Box display="flex">{selectedDesignPart?.partData.partNumber}</Box>
      </HStack>
    ),
    [selectedDesignPart],
  );

  /**
   * Render the header for the wiring list.
   */
  const renderHeader = useMemo(
    () => (
      <HStack
        fontWeight="500"
        color="gray.600"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.300"
        px={4}
        py={2}
      >
        <Text flex={1}>Cavity</Text>
        <Text flex={1}>Destination</Text>
        <Text flex={1}>Conductor</Text>
        <Text flex={1}>Signal</Text>
        <Text flex={1}>Contact</Text>
        <Text flex={1}>Dest. Contact</Text>
        <Spacer />
        <Text w="100px" display="flex" justifyContent="flex-end"></Text>
      </HStack>
    ),
    [],
  );

  /**
   * Render the list of connections.
   */
  const renderConnections = () => {
    return (
      <Flex flex={1} flexDirection="column" overflow="auto">
        {renderHeader}
        <Box flex={1} overflow="auto">
          {wiringList.map((row) => {
            const isEditing = activeEditRow === row.id;
            const isAnyConnectionEditing = !!activeEditRow || !!draftRow;

            return (
              <Box
                key={row.id}
                role="group"
                color="gray.500"
                borderBottom="1px solid"
                borderColor="gray.200"
                bg={
                  !isEditing &&
                  selectedConnection &&
                  row.existingConnection &&
                  row.existingConnection.id === selectedConnection.id
                    ? 'blue.100'
                    : 'transparent'
                }
                onClick={() => {
                  if (!activeEditRow) {
                    handleSelectConnection(row.existingConnection);
                  }
                }}
              >
                <ConnectionItem
                  id={row.id}
                  connectionPoint={row.connectionPoint}
                  connection={row.existingConnection}
                  isEditing={isEditing}
                  isAnyConnectionEditing={isAnyConnectionEditing}
                  onEdit={startRowEdit}
                  onClear={stopEditing}
                  onCancel={stopEditing}
                  onSave={stopEditing}
                  onAdd={startDraftEdit}
                />
                {draftRow && draftRow === row.id && (
                  <ConnectionItem
                    id={'draft'}
                    connectionPoint={row.connectionPoint}
                    isEditing={true}
                    isAnyConnectionEditing={isAnyConnectionEditing}
                    onCancel={stopEditing}
                    onSave={stopEditing}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Flex>
    );
  };

  // If the selected design part is not a connection target, return null.
  if (isLoading || error || !selectedDesignPart || !isConnectionTarget(selectedDesignPart)) return null;

  return (
    <Flex h="40vh" flexDirection="column">
      {renderSelectedDesignPart}
      {renderConnections()}
    </Flex>
  );
};

export default WiringList;
