import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  color: string;
}

/**
 * ConductorSVG is a simple SVG component that renders a horizontal line.
 * @param color
 * @param rest
 * @constructor
 */
const ConductorLine = ({ color, ...rest }: Props) => (
  <Box {...rest}>
    <svg
      height="54"
      width="calc(100% - 27px)"
      style={{ position: 'absolute', left: '15px', top: '50%', transform: 'translateY(-50%)' }}
    >
      <line x1="0" y1="27" x2="100%" y2="27" style={{ stroke: color, strokeWidth: 1 }} />
    </svg>
  </Box>
);

export default ConductorLine;
