import { useDisclosure } from '@chakra-ui/react';
import { ContextPanelWithTabs } from '../../components/ContextPanel';
import Layout from './Layout';
import BuildNotesView from './components/notes/BuildNotesView';
import DetailNotesView from './components/notes/DetailNotesView';

export enum NoteView {
  BuildNotes = 'buildNotes',
  Details = 'details',
}

const tabs = [
  { value: NoteView.BuildNotes, headerLabel: 'Build Notes', content: <BuildNotesView /> },
  {
    value: NoteView.Details,
    headerLabel: 'Details',
    content: <DetailNotesView />,
  },
];

const LayoutWithNotes = () => {
  const { isOpen, onToggle, onOpen } = useDisclosure();

  return (
    <>
      <Layout />
      <ContextPanelWithTabs isOpen={isOpen} togglePanel={onToggle} openPanel={onOpen} tabs={tabs} />
    </>
  );
};

export default LayoutWithNotes;
