import AdminPage from '../AdminPage';
import { useForm } from 'react-hook-form';
import { successToast } from '../../../common/toasts';
import { apiPostUser } from '../../../api/admin-api';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { E164Number } from 'libphonenumber-js/core';
import UserForm from './UserForm';

const AdminNewUser = () => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState<E164Number>('');

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      tenantId: '',
    },
  });

  const onSubmit = async (data: { name: string; email: string; tenantId: string }) => {
    const results = await apiPostUser({ ...data, phone });
    if (results) {
      successToast(`Sent invite to ${data.email}`);
      navigate(RouteNames.USERS.INDEX);
    }
  };

  return (
    <AdminPage title="New User">
      <UserForm
        isNew
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        isSubmitting={isSubmitting}
        isValid={isValid}
        phone={phone}
        setPhone={setPhone}
      />
    </AdminPage>
  );
};

export default AdminNewUser;
