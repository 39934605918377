import { FormProvider, useForm } from 'react-hook-form';
import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';
import { minMaxAwgFormatter, MinMaxAwg } from '../../components/MinMaxAwgFields.tsx';
import { Part, PartType, Splice } from '@senrasystems/senra-ui';
import { Text, Checkbox } from '@chakra-ui/react';

const SplicesDashboard = () => {
  const methods = useForm<Splice>({ defaultValues: { type: PartType.SPLICE } });
  const columns: CustomColumnDef[] = [
    {
      header: 'Type',
      renderFn: (part: Part) => {
        const splice = part as Splice;
        return <Text>{splice.subtype}</Text>;
      },
    },
    {
      header: 'AWG',
      renderFn: (part: Part) => {
        const splice = part as Splice;
        const minMaxAwg: MinMaxAwg = {
          gaugeMinAwg: splice.gaugeMinAwg,
          gaugeMaxAwg: splice.gaugeMaxAwg,
        };
        return <Text>{minMaxAwgFormatter(minMaxAwg)}</Text>;
      },
    },

    {
      header: 'Insulated?',
      renderFn: (part: Part) => {
        const splice = part as Splice;
        return <Checkbox isChecked={splice.insulated}></Checkbox>;
      },
    },
    {
      header: 'Made on Assembly?',
      renderFn: (part: Part) => {
        const splice = part as Splice;
        return <Checkbox isChecked={splice.madeOnAssembly}></Checkbox>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.SPLICE} columnDefs={columns} />
    </FormProvider>
  );
};

export default SplicesDashboard;
