import { Box, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { FaSadTear } from 'react-icons/fa';

interface Props {
  errorMessage?: string;
}

const ErrorPage = ({ errorMessage }: Props) => {
  return (
    <Center position="absolute" top="20%" left="50%" transform="translateX(-50%)" p={4}>
      <VStack spacing={4} textAlign="center">
        <Box boxSize="100px" color="blue.700">
          <FaSadTear size="100%" />
        </Box>
        <Heading as="h1" size="xl">
          Oops! Something went wrong.
        </Heading>
        <Text fontSize="lg" color="gray.600">
          {errorMessage || 'We encountered an unexpected error. Please try again.'}
        </Text>
      </VStack>
    </Center>
  );
};

export default ErrorPage;
