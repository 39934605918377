import { useCallback } from 'react';
import { Design, NoteType } from '@senrasystems/senra-ui';
import { useDesignQuery } from '../api/queries';
import { useDesignId } from './useDesignId';

export const useDesignBuildNotes = (noteType?: NoteType) => {
  // Get the design ID from the URL params
  const designId = useDesignId();

  const getNotes = useCallback(
    (design?: Design) => {
      if (design) {
        const { buildNotes } = design;
        return noteType ? buildNotes.filter((note) => note.type === noteType) : buildNotes;
      }

      return [];
    },
    [noteType],
  );

  return useDesignQuery(designId, getNotes);
};
