import { Controller, useForm } from 'react-hook-form';
import { Box, HStack, IconButton, Input, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { DesignPart } from '@senrasystems/senra-ui';
import { useUpdateDesignPartQuantityMutation } from '../../../api/queries.ts';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { useEffect } from 'react';
import { BiSolidCheckCircle } from 'react-icons/bi';
import { useDesignId } from '../../../hooks/useDesignId.tsx';

interface Props {
  part: DesignPart;
  initialQuantity?: number;
  unit?: string;
  editMode?: boolean;
}

/**
 * UpdateQuantity component allows the user to update the quantity of a part.
 * @param part
 * @param initialQuantity
 * @param unit
 * @param editMode
 * @constructor
 */
const QuantityUpdater = ({ part, initialQuantity = 0, unit = '', editMode = false }: Props) => {
  const designId = useDesignId();
  const { showSuccessToast, showErrorToast } = useDesignToast();

  // Form to update the quantity
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      quantity: initialQuantity,
    },
  });

  // Reset the form when the initial quantity changes
  useEffect(() => {
    reset({ quantity: initialQuantity });
  }, [initialQuantity, reset]);

  const currentValue = watch('quantity');
  const isDisabled = initialQuantity?.toString() === currentValue.toString();

  // Mutation to update the quantity of a part
  const { mutate: updateDesignPartQuantity, isPending, isSuccess, error } = useUpdateDesignPartQuantityMutation();

  // Show a success toast when the quantity is updated
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Success', 'Quantity updated successfully');
    }
  }, [isSuccess, showSuccessToast]);

  // Show an error toast when there is an error updating the quantity
  useEffect(() => {
    if (error) {
      showErrorToast('Error updating quantity', error.message);
      reset();
    }
  }, [error, reset, showErrorToast]);

  // Handle the form submission
  const handleUpdate = (data: { quantity: number }) => {
    updateDesignPartQuantity({ designId, partId: part.id, data: { quantity: data.quantity.toString() } });
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      {editMode ? (
        <HStack w="75px" display="flex" justifyContent="flex-end">
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <InputGroup size="xs">
                <Input
                  {...field}
                  id="quantity"
                  type="decimal"
                  variant="outline"
                  borderColor="gray.300"
                  textAlign="right"
                  autoComplete="off"
                  w="40px"
                />
                {unit ? <InputRightAddon children={unit} /> : null}
              </InputGroup>
            )}
          />
          <IconButton
            aria-label="Update quantity"
            icon={<BiSolidCheckCircle />}
            variant="unstyled"
            type="submit"
            fontSize="1.5em"
            color={isDisabled ? 'gray.500' : 'green.400'}
            styleConfig={{ _disabled: { color: 'gray.500', opacity: 1 } }}
            transition="color .3s ease-in-out"
            isDisabled={isDisabled}
            isLoading={isPending}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
          />
        </HStack>
      ) : (
        <Box w="75px" display="flex" justifyContent="flex-end">
          {part.quantity} {unit}
        </Box>
      )}
    </form>
  );
};

export default QuantityUpdater;
