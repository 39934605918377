import { useNavigate } from 'react-router-dom';
import Hero from '../Hero.tsx';
import { ChakraProvider, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import theme from '../../apps/themes.ts';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={theme}>
      <Hero>
        <Stack textAlign="center" spacing={5}>
          <Heading fontSize="5xl">
            <Flex>
              <Text color="primary" fontWeight="bold">
                Houston
              </Text>
              <Text fontWeight="thin">, we have a 404.</Text>
            </Flex>
          </Heading>
          <Text>
            The page that you requested doesn't exist!{' '}
            <Text
              as="span"
              onClick={() => navigate(-1)}
              textDecoration="underline"
              fontWeight="bold"
              cursor="pointer"
              _hover={{ opacity: 0.5 }}
            >
              Go back
            </Text>
            .
          </Text>
        </Stack>
      </Hero>
    </ChakraProvider>
  );
};

export default NotFound;
