import { ParseResult } from 'papaparse';
import { immerable } from 'immer';

export interface IBestOfferRequest {
  partNumber: string;
  quantity?: number;
}

export const defaultBestOfferRequest: IBestOfferRequest = {
  partNumber: '',
  quantity: 1,
};

export interface IBestOffersRequest {
  parts: IBestOfferRequest[];
}

export class BestOffersRequest {
  requestItems: IBestOfferRequest[];

  constructor(bestOfferRequests: IBestOfferRequest[]) {
    this.requestItems = bestOfferRequests;
  }

  get stringifiedRequest(): string {
    return JSON.stringify(this.requestItems);
  }

  static fromParsedCsv(parsedResult: ParseResult<IBestOfferRequest>): BestOffersRequest {
    const validItems: IBestOfferRequest[] = parsedResult.data.reduce((acc, reqItem) => {
      if (!reqItem.partNumber) return acc;
      if (reqItem.quantity && isNaN(reqItem.quantity)) return acc;

      acc.push({
        partNumber: reqItem.partNumber,
        quantity: Math.ceil(reqItem.quantity || 1),
      });

      return acc;
    }, [] as IBestOfferRequest[]);

    return new BestOffersRequest(validItems);
  }

  toDto(): IBestOffersRequest {
    return {
      parts: this.requestItems,
    };
  }
}

export interface IPriceBreak {
  quantity: number;
  price: number;
  currency?: string;
  convertedCurrency?: string;
  convertedPrice?: number;
  conversionRate?: number;
}

export interface IProcurementOffer {
  id?: string;
  distributor: string;
  manufacturer?: string;
  desiredQuantity: string;
  inventoryLevel?: string;
  unitPriceUsd: string;
  totalPriceUsd: string;
  leadDays?: string;
  purchaseLink: string;
  distributorSku?: string;
  desiredPartNumber: string;
  priceBreaks: IPriceBreak[];
}

export class ProcurementOffer implements IProcurementOffer {
  [immerable] = true;

  id: string;
  distributor: string;
  manufacturer?: string;
  desiredQuantity: string;
  inventoryLevel?: string;
  unitPriceUsd: string;
  totalPriceUsd: string;
  leadDays?: string;
  purchaseLink: string;
  distributorSku?: string;
  desiredPartNumber: string;
  selected?: boolean;
  priceBreaks: IPriceBreak[];

  constructor(offer: IProcurementOffer) {
    this.id = offer.id ?? window.crypto.randomUUID();
    this.distributor = offer.distributor;
    this.manufacturer = offer.manufacturer;
    this.desiredQuantity = offer.desiredQuantity;
    this.inventoryLevel = offer.inventoryLevel;
    this.unitPriceUsd = offer.unitPriceUsd;
    this.totalPriceUsd = offer.totalPriceUsd;
    this.leadDays = offer.leadDays;
    this.purchaseLink = ProcurementOffer.urlOrNone(offer.purchaseLink);
    this.distributorSku = offer.distributorSku;
    this.desiredPartNumber = offer.desiredPartNumber;
    this.selected = false;
    this.priceBreaks = offer.priceBreaks;
  }

  static urlOrNone(val: string) {
    return val.includes('http') ? val : 'Not Found';
  }
}

const makeBaseOffer = (desiredPartNumber: string, desiredQuantity: string): ProcurementOffer => {
  return new ProcurementOffer({
    distributor: '',
    manufacturer: '',
    desiredQuantity: desiredQuantity,
    inventoryLevel: '0',
    unitPriceUsd: '0',
    totalPriceUsd: '0',
    leadDays: '0',
    purchaseLink: '',
    distributorSku: '',
    desiredPartNumber: desiredPartNumber,
    priceBreaks: [],
  });
};

export interface IPartOffers {
  partNumber: string;
  offers: IProcurementOffer[];
}

export interface IBestOffersResponse {
  message?: string;
  data?: IPartOffers[];
}

export class PartOffers {
  [immerable] = true;

  partNumber: string;
  desiredQuantity: string;
  bestOffers: ProcurementOffer[];
  nonBestOffers: ProcurementOffer[];

  constructor(partOffers: IPartOffers, bestOffersQty: number | undefined = 3) {
    this.partNumber = partOffers.partNumber;

    if (partOffers.offers.length) {
      this.desiredQuantity = partOffers.offers[0].desiredQuantity;
    } else {
      this.desiredQuantity = '0';
    }

    this.bestOffers = [makeBaseOffer(this.partNumber, this.desiredQuantity)];
    this.nonBestOffers = [];

    if (partOffers.offers.length >= bestOffersQty) {
      this.bestOffers.push(...partOffers.offers.slice(0, bestOffersQty).map((o) => new ProcurementOffer(o)));
      this.nonBestOffers.push(...partOffers.offers.slice(bestOffersQty).map((o) => new ProcurementOffer(o)));
    } else {
      this.bestOffers.push(...partOffers.offers.map((o) => new ProcurementOffer(o)));
    }

    if (this.bestOffers.length > 1) {
      this.bestOffers[1].selected = true;
    } else if (this.bestOffers.length === 1) {
      this.bestOffers[0].selected = true;
    }
  }
}
