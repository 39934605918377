import { Outlet, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { WindowManagerProvider } from '../hooks/useWindowManager.tsx';
import { DesignProvider } from '../hooks/useDesign.tsx';
import theme from '../../themes.ts';
import ErrorBoundary from './ErrorBoundary.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { css, Global } from '@emotion/react';
import { useEffect } from 'react';

/**
 * Global styles for the application
 */
const DesignStyle = () => (
  <Global
    styles={css`
      /* Allow text selection for all elements */

      * {
        -webkit-user-select: text; /* Chrome, Safari, Opera */
        -moz-user-select: text; /* Firefox */
        -ms-user-select: text; /* Internet Explorer/Edge */
        user-select: text; /* Non-prefixed version */
      }
    `}
  />
);

/**
 * Layout for the design feature. It's wrapped by the WindowManagerProvider to manage areas of the Design that can be
 * opened in a new window.
 * @constructor
 */
const DesignLayout = () => {
  // Hack to set the color mode to light by default, we're doing this because Chakra's theme support isn't that great.
  // Chakra doesn't support setting the color mode in the theme object, so we have to set it in localStorage.
  localStorage.setItem('chakra-ui-color-mode', 'light');

  // Check if the current route is the index route. The index route is a special route that doesn't need to be wrapped
  // by the DesignProvider.
  const location = useLocation();
  const isIndex = location.pathname === RouteNames.DESIGNS.INDEX;

  // Prevent text selection on double click
  useEffect(() => {
    const handleDoubleClick = (event: MouseEvent) => {
      event.preventDefault();

      const selection = document.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    };

    document.addEventListener('dblclick', handleDoubleClick);

    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <WindowManagerProvider>
        <ErrorBoundary>
          {isIndex ? (
            <Outlet />
          ) : (
            <DesignProvider>
              <DesignStyle />
              <Outlet />
            </DesignProvider>
          )}
        </ErrorBoundary>
      </WindowManagerProvider>
    </ChakraProvider>
  );
};

export default DesignLayout;
