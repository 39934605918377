import { doRequest, getUrl } from '../common/api';
import { OrderCommentCreateDto, OrderCommentEditDto } from '../common/lib';

/**
 * Creates a comment on an order
 * @param orderId Order id
 * @param comment Comment data
 * @returns `OrderCommentDto`
 */
export async function apiCreateOrderComment(orderId: string, comment: OrderCommentCreateDto) {
  return doRequest('POST', getUrl(`/api/v1/orders/${orderId}/comments`), JSON.stringify({ comment }));
}

/**
 * Gets all comments for an order
 * @param orderId Order id
 * @returns `OrderCommentDto[]`
 */
export async function apiGetOrderComments(orderId: string) {
  return doRequest('GET', getUrl(`/api/v1/orders/${orderId}/comments`));
}

/**
 * Edits a comment on an order
 * @param orderId Order id
 * @param commentId Comment id
 * @param comment Comment data
 * @returns `OrderCommentDto`
 */
export async function apiEditOrderComment(orderId: string, commentId: number, comment: OrderCommentEditDto) {
  return doRequest('PUT', getUrl(`/api/v1/orders/${orderId}/comments/${commentId}`), JSON.stringify({ comment }));
}

/**
 * Soft deletes a comment from an order.
 * @param orderId Order id
 * @param commentId Comment id
 * @returns The `OrderCommentDto` that was soft-deleted
 */
export async function apiDeleteOrderComment(orderId: string, commentId: number) {
  return doRequest('DELETE', getUrl(`/api/v1/orders/${orderId}/comments/${commentId}`));
}
