import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Card,
  CardBody,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { SenraTable } from '../../../components/table/SenraTable';
import { useSenraTable } from '../../../components/table/hooks/useSenraTable';
import { ProcurementOffer } from '../../../models/Procurement.model';

interface Props {
  partNumber: string;
  bestOffers: ProcurementOffer[];
  nonBestOffers: ProcurementOffer[];
  onRadioGroupChange: (nextVal: string) => void;
  onBestOffersUpdate: (offers: ProcurementOffer[]) => void;
}

const OffersResultsCard = ({
  partNumber,
  bestOffers,
  nonBestOffers,
  onRadioGroupChange,
  onBestOffersUpdate,
}: Props) => {
  const selectedBestOffer = bestOffers.find((offer) => offer.selected);
  const [selectedOfferId, setSelectedOfferId] = useState<string>(selectedBestOffer?.id ?? '');
  const { CellText, CellInputString } = useSenraTable<ProcurementOffer>();
  const columnHelper = createColumnHelper<ProcurementOffer>();

  const memoizedResultsColumns = useMemo<ColumnDef<ProcurementOffer>[]>(
    () => [
      {
        id: 'resultsColumns',
        columns: [
          {
            header: 'Distributor',
            accessorKey: 'distributor',
            cell: CellInputString,
            enableColumnFilter: true,
          },
          {
            header: 'Purchase Link',
            accessorKey: 'purchaseLink',
            cell: (props) => CellInputString(props, { textTransform: 'none' }),
            enableColumnFilter: false,
          },
          {
            id: 'purchaseLinkButton',
            cell: ({ row }) => {
              const purchaseLink = row.original.purchaseLink;
              if (purchaseLink.includes('http')) {
                return (
                  <Link href={purchaseLink} isExternal>
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                );
              }

              return (
                <Tooltip label="Purchase link not found">
                  <Text>
                    <InfoOutlineIcon mx="2px" />
                  </Text>
                </Tooltip>
              );
            },
            size: 100,
          },
          {
            header: 'Desired Quantity',
            accessorKey: 'desiredQuantity',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          {
            header: 'Available Quantity',
            accessorKey: 'inventoryLevel',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          {
            header: 'Unit Price USD',
            accessorKey: 'unitPriceUsd',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          {
            header: 'Total Price USD',
            accessorKey: 'totalPriceUsd',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          {
            header: 'Distributor Lead Days',
            accessorKey: 'leadDays',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          {
            header: 'Manufacturer',
            accessorKey: 'manufacturer',
            cell: CellInputString,
            enableColumnFilter: true,
          },
          {
            header: 'Price Breaks',
            accessorKey: 'priceBreaks',
            enableColumnFilter: false,
            cell: ({ getValue: getPriceBreaks }) => {
              const priceBreaks = getPriceBreaks();

              if (Array.isArray(priceBreaks) && priceBreaks.length > 0) {
                return (
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="right">
                          <AccordionIcon />
                        </Box>
                      </AccordionButton>
                      <AccordionPanel>
                        <SenraTable
                          tableDefinition={[
                            {
                              header: 'Quantity',
                              accessorKey: 'quantity',
                              cell: CellText,
                              enableColumnFilter: false,
                            },
                            {
                              header: 'Price USD',
                              accessorKey: 'price',
                              cell: CellText,
                              enableColumnFilter: false,
                            },
                          ]}
                          data={priceBreaks}
                          isNewRowEnabled={false}
                          supportNestedKeys={false}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              }
            },
          },
        ],
      },
    ],
    [CellInputString, CellText],
  );

  const resultsColumns = [
    columnHelper.display({
      id: 'selectOffer',
      cell: ({ row }) => {
        return (
          <Radio
            // row.original.id is the offer id. Added to target with cypress
            id={row.original.id}
            value={row.original.id}
          />
        );
      },
    }),
    ...memoizedResultsColumns,
  ];

  const resultCardColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem>
        <Card variant="outline" marginTop="40px" bg={resultCardColor} width="full">
          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Heading as="h2">
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {partNumber}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel>
                <Tabs>
                  <TabList>
                    <Tab>Best Offers</Tab>
                    <Tab>Other Offers</Tab>
                  </TabList>

                  <RadioGroup
                    onChange={(offerId: string) => {
                      onRadioGroupChange(offerId);
                      setSelectedOfferId(offerId);
                    }}
                    value={selectedOfferId}
                  >
                    <TabPanels>
                      <TabPanel>
                        <Box>
                          {!!bestOffers.length && (
                            <SenraTable
                              tableDefinition={resultsColumns}
                              data={bestOffers}
                              onDataUpdate={onBestOffersUpdate}
                              isNewRowEnabled={false}
                              supportNestedKeys={false}
                              justify="left"
                            />
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Box>
                          {!!nonBestOffers.length && (
                            <SenraTable
                              tableDefinition={resultsColumns}
                              data={nonBestOffers}
                              isNewRowEnabled={false}
                              supportNestedKeys={false}
                              justify="left"
                            />
                          )}
                          {!nonBestOffers.length && (
                            <Alert status="info" variant="left-accent">
                              <AlertIcon />
                              No other offers for this part
                            </Alert>
                          )}
                        </Box>
                      </TabPanel>
                    </TabPanels>
                  </RadioGroup>
                </Tabs>
              </AccordionPanel>
            </Stack>
          </CardBody>
        </Card>
      </AccordionItem>
    </Accordion>
  );
};

export default OffersResultsCard;
