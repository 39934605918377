import { Edge } from '@xyflow/react';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge.tsx';
import { EdgeType, Graph, isBreakoutPointNode, isMeasurementEdge, isSegmentEdge, NodeType } from '../../types.ts';
import { Operations } from '../../graph/Operations.ts';
import { findNodeById, removeNodeIfExists, updateNodesOfType } from '../NodeFactory.ts';
import { UUID } from '@senrasystems/senra-ui';
import { MeasurementEdgeData } from '../../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { mergeBundles } from '../../utils/bundles.ts';

// Operation to merge two control points
export type MergeBreakoutPointsOperation = {
  type: 'MergeBreakoutPoints';
  params: {
    fromNodeId: UUID;
    toNodeId: UUID;
  };
};

/**
 * Merges breakout points from two nodes into one node.
 */
export class MergeBreakoutPoints implements Operations<MergeBreakoutPointsOperation> {
  // Execute the operation
  execute(graph: Graph, operation: MergeBreakoutPointsOperation): Graph {
    const { nodes, edges } = graph;
    const { fromNodeId, toNodeId } = operation.params;

    // Validate parameters
    if (
      fromNodeId === toNodeId ||
      !findNodeById(nodes, fromNodeId, isBreakoutPointNode) ||
      !findNodeById(nodes, toNodeId, isBreakoutPointNode)
    ) {
      // eslint-disable-next-line no-console
      console.warn('Invalid parameters for MergeBreakoutPoints operation.', fromNodeId, toNodeId);
      return graph;
    }

    // Step 1:
    // Any segments or measurements that are connected to fromNodeId should be redirected to toNodeId. This will produce
    // duplicate edges, which will be merged in the next step.
    this.redirectEdges(edges, fromNodeId, toNodeId);

    // Step 2:
    // Merge segment and measurement edges with the same source-target pairs
    this.findAndMergeDuplicateEdges<SegmentEdgeData>(edges, EdgeType.Segment, this.mergeSegmentEdgeData);
    this.findAndMergeDuplicateEdges<MeasurementEdgeData>(edges, EdgeType.Measurement, this.mergeMeasurementEdgeData);

    // Step 3:
    // Remove the fromNodeId and return the final graph
    removeNodeIfExists(nodes, fromNodeId);

    // Step 4: Remove mergeCandidate property from breakout points
    const updatedNodes = updateNodesOfType(nodes, NodeType.BreakoutPoint, { mergeCandidate: false });

    return { nodes: updatedNodes, edges: edges };
  }

  /**
   * Redirects segments and measurement edges connected to fromNodeId to toNodeId. This is done to ensure that all edge
   * connected to fromNodeId are redirected to toNodeId before merging. This will produce duplicate edges, which will be
   * merged in the next step.
   * @param edges
   * @param fromNodeId
   * @param toNodeId
   */
  private redirectEdges = (edges: Edge[], fromNodeId: UUID, toNodeId: UUID): void => {
    edges.forEach((edge) => {
      if (isSegmentEdge(edge) || isMeasurementEdge(edge)) {
        // Redirect to toNodeId
        if (edge.source === fromNodeId) {
          edge.source = toNodeId;
        } else if (edge.target === fromNodeId) {
          edge.target = toNodeId;
        }
      }
    });
  };

  /**
   * Finds duplicate edges of the same type in an undirected graph, and merges their data.
   * @param edges - The array of edges to process.
   * @param edgeType - The type of edges to consider for merging.
   * @param mergeFn - A function that takes two edge data objects and merges them.
   */
  private findAndMergeDuplicateEdges<T extends Record<string, unknown>>(
    edges: Edge[],
    edgeType: EdgeType,
    mergeFn: (data1: T, data2: T) => T,
  ): void {
    const edgeMap = new Map<string, Edge<T>>();
    const nonMatchingEdges: Edge[] = [];

    // Populate the edge map and merge duplicates
    for (const edge of edges) {
      // If the edge type does not match, add it to nonMatchingEdges
      if (edge.type !== edgeType) {
        nonMatchingEdges.push(edge);
        continue;
      }

      // Skip edges with undefined data
      if (edge.data === undefined) {
        continue;
      }

      // At this point, we know edge.data is defined and of type T
      const typedEdge: Edge<T> & { data: T } = edge as Edge<T> & { data: T };

      // Normalize the edge key to ensure consistency in undirected graphs
      const normalizedKey =
        typedEdge.source < typedEdge.target
          ? `${typedEdge.source}-${typedEdge.target}`
          : `${typedEdge.target}-${typedEdge.source}`;

      const existingEdge = edgeMap.get(normalizedKey);
      if (existingEdge?.data !== undefined) {
        // If the edge already exists and has data, merge the data using the provided merge function
        existingEdge.data = mergeFn(existingEdge.data, typedEdge.data);
      } else {
        // If the edge is new or existing edge has no data, add/update it in the map
        edgeMap.set(normalizedKey, typedEdge);
      }
    }

    // Replace the contents of the original array with merged edges and non-matching edges
    edges.length = 0;
    edges.push(...edgeMap.values(), ...nonMatchingEdges);
  }

  /**
   * Merges two sets of segment edge data, ensuring that only unique bundles are retained.
   * @param data1
   * @param data2
   */
  private mergeSegmentEdgeData = (data1: SegmentEdgeData, data2: SegmentEdgeData): SegmentEdgeData => {
    return {
      ...data1,
      bundles: mergeBundles(data1.bundles || [], data2.bundles || []),
    };
  };

  /**
   * Merges two sets of measurement edge data, ensuring that the maximum measurement is retained.
   * @param data1
   * @param data2
   */
  private mergeMeasurementEdgeData = (data1: MeasurementEdgeData, data2: MeasurementEdgeData): MeasurementEdgeData => {
    return {
      ...data1,
      measurement: Math.max(data1.measurement, data2.measurement),
    };
  };
}
