import { NavigateFunction } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';
import { apiTransitionOrder } from '../api/admin-api';
import EventActionButton from './buttons/EventActionButton';
import { HStack } from '@chakra-ui/react';
import { Order, OrderDeliverTransition, OrderRequoteTransition, OrderStateEvent } from '@web/common/lib';
import { successToast } from '../common/toasts';
import Confirm from './Confirm';
import { useState } from 'react';

interface Props {
  order: Order;
  navigate: NavigateFunction;
  reloadOrders: () => void;
}

const OrderStateEventButtons = ({ order, navigate, reloadOrders }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const handleRequote = async () => {
    setSubmitting(true);
    const transition: OrderRequoteTransition = { orderId: order.id, event: OrderStateEvent.Requote };
    const response = await apiTransitionOrder(transition);
    if (response) {
      reloadOrders();
      successToast('Order state updated');
    }
    setSubmitting(false);
  };

  const handleUploadPurchaseOrder = () => {
    navigate({ pathname: RouteNames.ORDERS.UPLOAD_PURCHASE_ORDER.replace(':orderId', order.id) });
  };

  const handleDeliver = async () => {
    setSubmitting(true);
    const transition: OrderDeliverTransition = {
      orderId: order.id,
      event: OrderStateEvent.Deliver,
    };
    const response = await apiTransitionOrder(transition);
    if (response) {
      reloadOrders();
      successToast('Order state updated');
    }
    setSubmitting(false);
  };

  const handleDecline = () => {
    navigate({
      pathname: RouteNames.DECLINE_ORDER,
      search: `?o=${order.id}`,
    });
  };

  const handleUploadQuote = () => {
    navigate({ pathname: RouteNames.MANUAL_QUOTE_UPLOAD.replace(':orderId', order.id) });
  };

  const getStateButtonJSX = (event: OrderStateEvent) => {
    switch (event) {
      case OrderStateEvent.Requote: {
        return (
          <Confirm
            key={OrderStateEvent.Requote}
            title="Are you sure you want to requote this order?"
            body='This will reset its status to "pending quote".'
            onConfirm={() => handleRequote()}
          >
            <EventActionButton event={event} submitting={submitting} />
          </Confirm>
        );
      }
      case OrderStateEvent.UploadPurchaseOrder:
        return <EventActionButton key={event} event={event} onClick={handleUploadPurchaseOrder} />;
      case OrderStateEvent.Deliver: {
        return (
          <Confirm
            key={OrderStateEvent.Deliver}
            title="Are you sure you want to deliver this order?"
            body='You should only do this once the order has been delivered to the customer. The order will be marked as "delivered".'
            onConfirm={() => handleDeliver()}
          >
            <EventActionButton event={event} submitting={submitting} />
          </Confirm>
        );
      }
      case OrderStateEvent.Decline:
        return <EventActionButton key={event} event={event} onClick={handleDecline} />;
      default:
        return <EventActionButton key={event} event={event} onClick={handleUploadQuote} />;
    }
  };

  if (order.stateEvents.length === 0) {
    return null;
  }

  return <HStack spacing={2}>{order.stateEvents.map((event) => getStateButtonJSX(event))}</HStack>;
};

export default OrderStateEventButtons;
