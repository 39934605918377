import React, { forwardRef } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js/core';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import ErrorText from './ErrorText';

interface Props {
  label: string;
  error?: string | null;
  name: string;
  value: E164Number;
  onChange: (phoneNumber: E164Number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextInput = forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
  return <Input {...props} ref={ref} ml={3} variant="outline" w="full" />;
});

const PhoneInputField = (props: Props) => {
  const { label, error, name, value, onChange } = props;

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <PhoneInput defaultCountry="US" value={value} onChange={onChange} name={name} inputComponent={TextInput} />
      {error && <ErrorText>{error}</ErrorText>}
    </FormControl>
  );
};

export default PhoneInputField;
