import { NavLink, useLocation } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';
import { feedbackFormUrl } from '../../common/socials';
import SignInButton from '../auth/SignInButton';
import { AuthenticatedTemplate, EmployeeTemplate, UnauthenticatedTemplate } from '../auth/RenderTemplates';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Tooltip,
  UnorderedList,
  useColorMode,
} from '@chakra-ui/react';
import { RiSettings5Fill } from 'react-icons/ri';
import LogoText from '../svg/LogoText';
import { FaMoon, FaSun } from 'react-icons/fa';

const LogoWrapper = () => (
  <NavLink to={RouteNames.ROOT}>
    <Box mx={4}>
      <LogoText />
    </Box>
  </NavLink>
);

export default function TopbarNav() {
  const { colorMode, toggleColorMode } = useColorMode();

  const location = useLocation();

  return (
    <Flex
      padding="7px"
      alignItems="center"
      width="full"
      borderBottom="1px"
      borderColor="gray.300"
      backgroundColor={
        import.meta.env.VITE_ENVIRONMENT === 'staging' ? 'red.200' : colorMode === 'light' ? 'gray.200' : 'gray.800'
      }
    >
      <LogoWrapper />
      <Show above="md">
        <Flex flex={1} fontSize="sm">
          <UnorderedList listStyleType="none">
            <HStack spacing={8} mt={4} mb={4}>
              <AuthenticatedTemplate>
                <li>
                  <NavbarLink text="Orders" to={RouteNames.ORDERS.INDEX} />
                </li>
              </AuthenticatedTemplate>
              <EmployeeTemplate>
                <li>
                  <NavbarLink text="Designs" to={RouteNames.DESIGNS.INDEX} />
                </li>
                <li>
                  <NavbarLink text="Parts Library" to={RouteNames.PARTS.INDEX} />
                </li>
                <li>
                  <NavbarLink text="Procurement" to={RouteNames.PROCUREMENT.FIND_BEST_OFFERS} />
                </li>
                <li>
                  <NavbarLink text="Tenants" to={RouteNames.TENANTS.INDEX} />
                </li>
                <li>
                  <NavbarLink text="Users" to={RouteNames.USERS.INDEX} />
                </li>
              </EmployeeTemplate>
              {!location.pathname.startsWith(RouteNames.DESIGNS.INDEX) && (
                <Tooltip label={`Toggle ${colorMode === 'light' ? 'dark' : 'light'} mode`}>
                  <IconButton onClick={toggleColorMode} aria-label={'colorModeToggle'}>
                    {colorMode === 'light' ? <FaMoon /> : <FaSun />}
                  </IconButton>
                </Tooltip>
              )}
            </HStack>
          </UnorderedList>
        </Flex>
      </Show>
      <Show above="md">
        <Flex display={{ base: 'none', md: 'flex' }} fontSize="sm">
          <AuthenticatedTemplate>
            <Menu>
              <MenuButton
                tabIndex={0}
                cursor="pointer"
                _hover={{ bg: 'whiteAlpha.300', borderRadius: '50%' }}
                data-cy="menu-button"
              >
                <RiSettings5Fill size={26} />
              </MenuButton>
              <MenuList bg="background" borderRadius={0}>
                <MenuItem bg="background" _hover={{ bg: 'whiteAlpha.100' }}>
                  <NavLink to={RouteNames.PROFILE}>Personal settings</NavLink>
                </MenuItem>
                <MenuItem bg="background" _hover={{ bg: 'whiteAlpha.100' }}>
                  <NavLink to={RouteNames.COMPANY_PROFILE}>Company settings</NavLink>
                </MenuItem>
                <MenuItem
                  as="a"
                  href={feedbackFormUrl}
                  target="_blank"
                  rel="noreferrer"
                  bg="background"
                  _hover={{ bg: 'whiteAlpha.100' }}
                >
                  Submit feedback
                </MenuItem>
                <MenuItem bg="background" color="red.300" _hover={{ bg: 'whiteAlpha.100' }}>
                  <NavLink to={RouteNames.SIGN_OUT}>Sign Out</NavLink>
                </MenuItem>
              </MenuList>
            </Menu>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <li>
              <SignInButton />
            </li>
          </UnauthenticatedTemplate>
        </Flex>
      </Show>
    </Flex>
  );
}

const NavbarLink = ({ text, to }: { text: string; to: string; showActive?: boolean }) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => {
        return {
          opacity: isActive ? 1 : 0.6,
          fontSize: 'xs',
        };
      }}
    >
      {text}
    </NavLink>
  );
};
