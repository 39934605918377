import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Vars, envVars } from '../../common/environment';
import AuthServiceWrapper from './AuthServiceWrapper';

interface Props {
  children: ReactNode;
}

export const Auth0ProviderWithNavigate = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const domain = envVars.get(Vars.Auth0Domain);
  const clientId = envVars.get(Vars.Auth0ClientId);
  const audience = envVars.get(Vars.Auth0Audience);

  if (!domain) throw new Error('Missing required env var: VITE_AUTH0_DOMAIN');
  if (!clientId) throw new Error('Missing required env var: VITE_AUTH0_CLIENT_ID');
  if (!audience) throw new Error('Missing required env var: VITE_AUTH0_AUDIENCE');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain.toString()}
      clientId={clientId.toString()}
      authorizationParams={{
        audience: audience.toString(),
        redirect_uri: window.location.origin, // TODO: do not use window object here
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthServiceWrapper>{children}</AuthServiceWrapper>
    </Auth0Provider>
  );
};
