interface Params {
  [key:string]: string | number | null | undefined
}

// Takes in an object mapping keys to values and returns a search string like ?page=1&partNumber=12
const generateURlSearch = (params: Params): string => {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, val]) => val && urlParams.set(key, val.toString()));
  return urlParams.size > 0 ? `?${urlParams.toString()}` : '';
} 

export default generateURlSearch;