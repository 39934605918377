import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Center, Stack, Table, Tbody, Th, Tr, Thead, Tooltip } from '@chakra-ui/react';
import { IncludedAccessory, Part, PartType } from '@senrasystems/senra-ui';
import IncludedAccessoryRow from './IncludedAccessoryRow.tsx';
import { pluralize } from '@web/common/lib/string-utils/string-utils.ts';

interface Props {
  partType: PartType;
  editing: boolean;
  labelId?: string;
  maxRows?: number;
  customColumns?: { header: string; renderFn: (part: Part) => React.ReactNode }[];
}

const IncludedAccessoryTable = ({ partType, editing, labelId, maxRows, customColumns }: Props) => {
  const { control } = useFormContext<Part>();
  const { fields: accessories, append, remove, update } = useFieldArray({ control, name: 'includedAccessories' });

  const isActiveAndMatchesType = (accessory: IncludedAccessory) => !accessory._destroy && accessory.type === partType;
  const shownAccessories = accessories.filter(isActiveAndMatchesType);

  const addRowAllowed = maxRows === undefined || shownAccessories.length < maxRows;
  const addRowDisabledMessage = `Only ${maxRows} ${partType.toLowerCase() + pluralize(maxRows || 0)} is allowed`;

  const handleAddRow = () => {
    append({ type: partType });
  };

  return (
    <Stack spacing={2}>
      <Center>
        <Table aria-labelledby={labelId} size="sm">
          <Thead w="full">
            <Tr>
              <Th>Part</Th>
              {customColumns?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
              {editing && (
                <Th>
                  <Tooltip isDisabled={addRowAllowed} label={addRowDisabledMessage} shouldWrapChildren mt="3">
                    <Button onClick={handleAddRow} isDisabled={!addRowAllowed}>
                      +
                    </Button>
                  </Tooltip>
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {accessories.map((item, index) => (
              <IncludedAccessoryRow
                key={item.id}
                index={index}
                partType={partType}
                editing={editing}
                remove={remove}
                update={update}
                customColumns={customColumns}
              />
            ))}
          </Tbody>
        </Table>
      </Center>
    </Stack>
  );
};

export default IncludedAccessoryTable;
