import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import { CableData, PartType, ResolvedConnection, ResolvedConnectionPoint, WireData } from '@senrasystems/senra-ui';
import { ConnectionGroup } from '../hooks/useSchematic';
import DestinationPin from './DestinationPin';
import ConductorLine from './ConductorLine';
import SourcePin from './SourcePin';
import { useSchematicSelectionState } from '../hooks/useSchematicSelectionState.tsx';

// Mapping for connection point names
const connectionPointNameMap: Record<string, string> = {
  Housing: 'HS',
  Shield: 'SHLD',
  Splice: 'SPL',
};

interface Props extends BoxProps {
  connectionGroup: ConnectionGroup;
}

/**
 * ConnectorAssembly component displays a connector assembly view of a group of connections that have the same source
 * and destination connector.
 */
const ConnectorAssembly = ({ connectionGroup, ...rest }: Props) => {
  const {
    isSourceSelected,
    isDestinationSelected,
    isConductorSelected,
    handleSourceSelect,
    handleDestinationSelect,
    handleConductorSelect,
  } = useSchematicSelectionState(connectionGroup);
  // Get the label for a connection point
  const getConnectionPointLabel = (name: string | undefined) => (name ? connectionPointNameMap[name] || name : '?');

  // Render a single connection point with styling based on its state
  const ConnectionPoint = ({ cp }: { cp: ResolvedConnectionPoint }) => {
    if (!cp) return null;
    const color = cp.name === 'Housing' ? 'gray.100' : 'gray.600';
    const bgColor =
      cp.name === 'Housing'
        ? 'transparent'
        : isSourceSelected(cp) || isDestinationSelected(cp)
          ? 'orange.300'
          : 'gray.100';
    const fontWeight = connectionPointNameMap[cp.name] ? 'bold' : 'normal';

    return (
      <Box
        display="flex"
        h="30px"
        w="40px"
        bg={bgColor}
        border="1px solid"
        borderColor="gray.500"
        borderRadius="md"
        alignItems="center"
        justifyContent="center"
        my={2}
      >
        <Text color={color} fontWeight={fontWeight}>
          {getConnectionPointLabel(cp.name)}
        </Text>
      </Box>
    );
  };

  // Render source connection point
  const SourceConnectionPoint = ({ source }: { source: ResolvedConnectionPoint | null }) =>
    source && (
      <HStack pl={4} bg="gray.500" position="relative" onClick={() => handleSourceSelect(source)}>
        <ConnectionPoint cp={source} />
        <SourcePin />
      </HStack>
    );

  // Render destination connection point
  const DestinationConnectionPoint = ({ destination }: { destination: ResolvedConnectionPoint | null }) =>
    destination && (
      <HStack pr={4} bg="gray.500" position="relative" onClick={() => handleDestinationSelect(destination)}>
        <DestinationPin />
        <ConnectionPoint cp={destination} />
      </HStack>
    );

  // Render conductor with associated information
  const Conductor = ({ connection }: { connection: ResolvedConnection }) => {
    let partNumber: string = '';
    let gauge: string = '';

    if (connection.conductor?.designPart.partData.type === PartType.CABLE) {
      const partData = connection.conductor.designPart.partData as CableData;
      partNumber = partData.partNumber;
      gauge = partData.partWires?.[0]?.wire?.gauge ?? '';
    }
    if (connection.conductor?.designPart.partData.type === PartType.WIRE) {
      const partData = connection.conductor.designPart?.partData as WireData;
      partNumber = partData.partNumber;
      gauge = partData.gauge;
    }

    const conductorColor = connection.conductor
      ? isConductorSelected(connection.conductor)
        ? 'orange'
        : 'gray'
      : 'transparent';

    return (
      <Box flex="1" position="relative" onClick={() => handleConductorSelect(connection.conductor)}>
        <ConductorLine color={conductorColor} />
        {connection.conductor && (
          <HStack position="absolute" top="0" left="50px" transform="translateY(-50%)">
            <Text bg="white" px={4} mx={4}>
              {partNumber}
            </Text>
            <Text color="gray.500" bg="white" px={4} mx={4}>
              {gauge ? `${gauge} AWG` : ''}
            </Text>
            <Box h="10px" w="25px" bg={connection.conductor.name} border="1px solid" borderColor="gray.300" mx={4} />
            <Text bg="white" px={4} mx={4}>
              {connection.conductor.displayName}
            </Text>
          </HStack>
        )}
      </Box>
    );
  };

  return (
    <Box {...rest}>
      {connectionGroup.connections.map((c) => (
        <Box key={c.id}>
          <HStack h="full" w="full">
            <SourceConnectionPoint source={c.source} />
            <Conductor connection={c} />
            <DestinationConnectionPoint destination={c.destination} />
            <Box w="75px" bg="orange.100" borderRadius="md">
              <Text color="gray.600" px={2}>
                {c.signalName || ''}
              </Text>
            </Box>
          </HStack>
        </Box>
      ))}
    </Box>
  );
};

export default ConnectorAssembly;
