import { useCallback } from 'react';
import { isConductor, UUID } from '@senrasystems/senra-ui';
import { useUpdateDesignPartsQuantitiesMutation } from '../../../api/queries';
import { useConnections } from '../../../hooks/useConnections';
import { sumConductorLengths } from '../utils/graph';
import { useDesignParts } from '../../../hooks/useDesignParts';
import { useReactFlow } from '@xyflow/react';

interface Quantity {
  id: UUID;
  quantity: number;
}

interface UpdateConductorLengthsOptions {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

/**
 * Hook to update conductor lengths in the design.
 * @param designId - The ID of the design to update
 * @returns A function to update conductor lengths based on the provided graph
 */
export const useUpdateConductorLengths = (designId: UUID) => {
  const { mutateAsync } = useUpdateDesignPartsQuantitiesMutation();
  const { designParts } = useDesignParts();
  const { connectionPointsById } = useConnections();
  const { getNodes, getEdges } = useReactFlow();

  const mergeQuantities = useCallback((baseQuantities: Quantity[], updatedQuantities: Quantity[]): Quantity[] => {
    const quantitiesMap = new Map(baseQuantities.map(({ id, quantity }) => [id, quantity]));
    updatedQuantities.forEach(({ id, quantity }) => quantitiesMap.set(id, quantity));
    return Array.from(quantitiesMap, ([id, quantity]) => ({ id, quantity }));
  }, []);

  const getBaseConductorQuantities = useCallback(
    (): Quantity[] =>
      designParts
        .filter((designPart) => isConductor(designPart.partData.type))
        .map((designPart) => ({
          id: designPart.id,
          quantity: 0,
        })),
    [designParts],
  );

  const getLayoutQuantities = useCallback((): Quantity[] => {
    const conductorLengths = sumConductorLengths(getNodes(), getEdges());
    return Object.entries(conductorLengths).map(([key, value]) => ({
      id: connectionPointsById[key].designPart.id,
      quantity: value,
    }));
  }, [connectionPointsById, getEdges, getNodes]);

  return useCallback(
    (options: UpdateConductorLengthsOptions = {}) => {
      const { onSuccess, onError } = options;

      const baseQuantities = getBaseConductorQuantities();
      const layoutQuantities = getLayoutQuantities();
      const mergedQuantities = mergeQuantities(baseQuantities, layoutQuantities);

      mutateAsync({ designId, data: mergedQuantities })
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          if (onError) onError(error);
          // eslint-disable-next-line no-console
          else console.error('Failed to update design parts quantities:', error);
        });
    },
    [designId, mutateAsync, getBaseConductorQuantities, getLayoutQuantities, mergeQuantities],
  );
};
