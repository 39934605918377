import {
  Cable,
  Connector,
  Contact,
  GenericPart,
  Part,
  PartStatus,
  PartType,
  Passive,
  Pigtail,
  Splice,
  translatePartType,
  Wire,
} from '@senrasystems/senra-ui';
import {
  AccordionButton,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeletePartMutation, useUpdatePartMutation } from '../../../api/queries.ts';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { PartFields } from '../../../../../apps/Parts-Library';
import { StatusBadge } from '../../../../../apps/Parts-Library';
import { minMaxAwgFormatter } from '../../../../Parts-Library/components/MinMaxAwgFields.tsx';
import { usePartSearchModal } from '../../../hooks/usePartSearchModal.tsx';

interface Props extends AccordionItemProps {
  part: Part;
  afterDeleteCallback: () => void;
  afterUpdateCallback: (partId: string) => void;
}

/**
 * PartSearchListItem component displays a single part in a list of parts.
 * @param part
 * @param afterDeleteCallback
 * @param afterUpdateCallback
 * @param rest
 * @constructor
 */
const PartSearchListItem = ({ part, afterDeleteCallback, afterUpdateCallback, ...rest }: Props) => {
  const { renderPartAction } = usePartSearchModal();
  const [isEditing, setIsEditing] = useBoolean();
  const formMethods = useForm<Part>({ defaultValues: part });
  const { showErrorToast, showSuccessToast } = useDesignToast();

  const updatePart = useUpdatePartMutation();

  const isDraft = part.status === PartStatus.DRAFT;

  useEffect(() => {
    if (updatePart.error) {
      showErrorToast('Error updating part', updatePart.error.message);
      formMethods.resetField('status');
    }
  }, [formMethods, updatePart.error, showErrorToast]);

  const deletePart = useDeletePartMutation();

  useEffect(() => {
    if (deletePart.error) {
      showErrorToast('Error deleting part', deletePart.error.message);
    }
  }, [deletePart.error, showErrorToast]);

  const handleDeletePartClick = async (partId: string) => {
    if (confirm('Are you sure you want to delete this part?')) {
      await deletePart.mutateAsync({ partId });
      afterDeleteCallback();
      showSuccessToast('Success', 'Part deleted');
    }
  };

  const handleUpdatePart = async (newPart: Part) => {
    const updatedPart = await updatePart.mutateAsync({ partId: part.id, data: newPart });
    if (updatedPart) {
      afterUpdateCallback(updatedPart.id);
      showSuccessToast('Success', 'Part updated');
      setIsEditing.off();
      formMethods.reset(updatedPart);
    }
  };

  const handleAccordionClick = (isExpanded: boolean) => {
    if (!isExpanded) {
      setIsEditing.off();
      formMethods.reset();
    }
  };

  // Render a summary of the part
  const renderPartSummary = (part: Part) => {
    // Always display the manufacturer
    const values = [];
    if (part.manufacturer) {
      values.push(part.manufacturer.name);
    }

    // Display additional values based on the part type
    switch (part.type) {
      case PartType.CABLE:
        const cable = part as Cable;
        if (cable.jacket) {
          values.push(cable.jacket);
        }
        if (cable.shielding) {
          values.push(cable.shielding);
        }
        if (cable.shieldType) {
          values.push(cable.shieldType);
        }
        if (cable.partWires.length) {
          values.push(`${cable.partWires.length} Wires`);
        }
        break;
      case PartType.CONNECTOR:
        const connector = part as Connector;
        if (connector.insertArrangement?.cavities.length) {
          if (connector.gender) {
            values.push(connector.gender);
          }
          if (connector.insertArrangement.cavities.length) {
            values.push(`${connector.insertArrangement.cavities.length} Cavities`);
          }
        }
        break;
      case PartType.CONTACT:
        const contact = part as Contact;
        if (contact.gender) {
          values.push(contact.gender);
        }
        if (contact.gaugeMinAwg && contact.gaugeMaxAwg) {
          values.push(`${minMaxAwgFormatter(contact)} AWG`);
        }
        if (contact.termination) {
          values.push(contact.termination);
        }
        break;
      case PartType.GENERIC:
        const generic = part as GenericPart;
        if (generic.subtype) {
          values.push(generic.subtype);
        }
        if (generic.genericPartUnit) {
          values.push(generic.genericPartUnit);
        }
        break;
      case PartType.PASSIVE:
        const passive = part as Passive;
        if (passive.subtype) {
          values.push(passive.subtype);
        }
        if (passive.passiveValue) {
          values.push(passive.passiveValue);
        }
        if (passive.connections.length) {
          values.push(`${passive.connections.length} Connections`);
        }
        break;
      case PartType.PIGTAIL:
        const pigtail = part as Pigtail;
        if (pigtail.partWires) {
          values.push(`${pigtail.partWires.length} Wires`);
        }
        break;
      case PartType.SPLICE:
        const splice = part as Splice;
        if (splice.subtype) {
          values.push(splice.subtype);
        }
        if (splice.gaugeMinAwg && splice.gaugeMaxAwg) {
          values.push(`${minMaxAwgFormatter(splice)} AWG`);
        }
        break;
      case PartType.WIRE:
        const wire = part as Wire;
        if (wire.gauge) {
          values.push(wire.gauge);
        }
        break;
      default:
        break;
    }
    return values.join(', ');
  };

  return (
    <AccordionItem borderTop="1px solid" borderColor="gray.300" _hover={{ bg: 'gray.50' }} {...rest}>
      {({ isExpanded }) => (
        <Box>
          <HStack p={4} spacing={4} w="full" alignItems="center">
            <AccordionButton
              as={Flex}
              justifyContent="space-between"
              p={4}
              _hover={{ bg: 'inherit' }}
              onClick={() => handleAccordionClick(isExpanded)}
              role="button"
              aria-label="Expand Part Details"
            >
              <HStack spacing={2}>
                <Box textAlign="left" color="gray.500" w="65px">
                  <Text aria-label="Part Type" role="note" fontWeight="semibold">
                    {translatePartType(part.type)}
                  </Text>
                </Box>
                <Icon as={isExpanded ? ChevronDownIcon : ChevronRightIcon} mx={2} />
                <Box color="gray.900">
                  <Text aria-label="Part Number" role="note" fontSize="lg">
                    {part.partNumber} {isDraft && <StatusBadge status={part.status} ml={1} />}
                  </Text>
                  <Text color="gray.500">{renderPartSummary(part)}</Text>
                </Box>
              </HStack>
            </AccordionButton>
            <Box
              visibility="hidden"
              _groupHover={{ visibility: 'visible' }}
              onClick={(e) => e.stopPropagation()}
              alignItems="center"
              w="175px"
              display="flex"
              justifyContent="flex-end"
            >
              <Box
                visibility="hidden"
                _groupHover={{ visibility: 'visible' }}
                onClick={(e) => e.stopPropagation()}
                alignItems="center"
                w="175px"
                display="flex"
                justifyContent="flex-end"
              >
                {renderPartAction?.(part)}
              </Box>
            </Box>
          </HStack>
          <AccordionPanel>
            <Box p={4} borderRadius="md">
              <FormProvider {...formMethods}>
                <form
                  onSubmit={async (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    await formMethods.handleSubmit(handleUpdatePart)(event);
                  }}
                >
                  <PartFields
                    partType={part.type}
                    isEditing={isEditing}
                    isUpdateMode={isEditing}
                    isCreateMode={false}
                    theme="light"
                    toggleEdit={setIsEditing.toggle}
                    onDeletePart={handleDeletePartClick}
                  />
                </form>
              </FormProvider>
            </Box>
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
};

export default PartSearchListItem;
