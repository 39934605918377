import { useTheme } from '@chakra-ui/react';
import { get } from '@chakra-ui/utils';
import { useEffect, useRef } from 'react';

interface Props {
  svgPattern: string;
  color: string;
  patternId: string;
}

const OverwrapBrick = ({ color, svgPattern, patternId }: Props) => {
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement>(null);

  const getHexColor = (color: string): string => {
    return get(theme, `colors.${color}`) as string;
  };

  //getting hex colors from chakra theme colors
  const BgColor = getHexColor(color.toLowerCase() + '.600');
  const LineColor = getHexColor(color.toLowerCase() + '.500');

  //updating pattern to have the specified colors we want
  useEffect(() => {
    if (svgRef.current) {
      const patternElement = svgRef.current.querySelector('#' + patternId);
      if (patternElement) {
        const lines = patternElement.querySelectorAll('line');
        lines.forEach((line) => {
          line.style.stroke = LineColor;
        });
        const rect = patternElement.querySelector('rect');
        if (rect) rect.style.fill = BgColor;
      }
    }
  }, [patternId, BgColor, LineColor]);

  return (
    <div>
      <svg ref={svgRef} width="64" height="32" viewBox="0 0 32 16">
        <defs>
          <g dangerouslySetInnerHTML={{ __html: svgPattern }} />
        </defs>
        <rect width="32" height="16" fill={`url(#${patternId})`} rx="4" ry="4" />
      </svg>
    </div>
  );
};

export default OverwrapBrick;
