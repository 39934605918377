import { SubmitHandler, useForm } from 'react-hook-form';
import S3FileInput from '../../components/form/S3FileInput';
import { Box, Button, Stack, NumberInput, NumberInputField, FormControl, FormLabel } from '@chakra-ui/react';
import Title from '../../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';
import { apiUploadPurchaseOrder } from '../../api/order-api';
import { successToast } from '../../common/toasts';
import { useOrder } from '../../queries/orders';
import Loading from '../../components/Loading';
import { OrderDocumentTypes } from '../../common/lib/common-types/common-types';
import TextInput from '../../components/form/TextInput';
import { S3Upload } from '../../common/lib';

interface FormValues {
  purchaseOrderAmount: string;
  purchaseOrderNumber: string;
  documents: S3Upload[];
}

interface Props {
  orderId: string;
}

const UploadPurchaseOrderForm = ({ orderId }: Props) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting, isValid },
  } = useForm({ defaultValues: { purchaseOrderNumber: '', purchaseOrderAmount: '', documents: [] } });

  const onConfirm = async () => {
    await handleSubmit(onSubmit)();
    navigate(RouteNames.ORDERS.SHOW.replace(':orderId', orderId));
  };

  const validateDocuments = (documents: S3Upload[]) => {
    return documents.length > 0;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const response = await apiUploadPurchaseOrder({
      documentableId: orderId,
      documentableType: 'Order',
      s3Key: data.documents[0].s3Key,
      type: OrderDocumentTypes.PurchaseOrder,
      purchaseOrderNumber: data.purchaseOrderNumber,
      purchaseOrderAmount: data.purchaseOrderAmount,
    });
    if (response) {
      navigate(RouteNames.ORDERS.INDEX);
      successToast('Order state updated');
    }
  };

  return (
    <div>
      <Stack spacing={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <TextInput label="Purchase order number *" {...register('purchaseOrderNumber', { required: true })} />
            <FormControl>
              <FormLabel>Purchase order amount ($) *</FormLabel>
              <NumberInput step={0.01} precision={2} min={0}>
                <NumberInputField {...register('purchaseOrderAmount', { required: true, valueAsNumber: true })} />
              </NumberInput>
            </FormControl>
            <S3FileInput
              label="Purchase Order document (PDF) *"
              hint="Upload the purchase order. This should be a single PDF document."
              control={control}
              name="documents"
              multiple={false}
              {...register('documents', { validate: validateDocuments })}
            />
          </Stack>
        </form>
        <Box>
          <Button isLoading={isSubmitting} isDisabled={!isValid} onClick={onConfirm}>
            Upload Purchase Order
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

export default function UploadPurchaseOrder() {
  const { orderId = '' } = useParams();
  const { data: order } = useOrder(orderId);

  if (!order) {
    return <Loading message="Please wait while we load your order…" />;
  }

  return (
    <Stack p={8} spacing={8} maxW="32rem">
      <Title title={`Upload Purchase Order to Accept Order ${order.orderNumber}`} />
      <UploadPurchaseOrderForm orderId={orderId} />
    </Stack>
  );
}
