import { DesignPart } from '@senrasystems/senra-ui';
import { Part } from '@senrasystems/senra-ui';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useReplaceDesignPartMutation } from '../../../api/queries.ts';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { MouseEventHandler, useEffect } from 'react';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';

interface Props extends ButtonProps {
  part: Part;
  partToReplace: DesignPart;
}

/**
 * ReplacePartButton component displays a button to replace a part.
 * @param part
 * @param partToReplace
 * @param onClick
 * @param rest
 * @constructor
 */
const ReplacePartButton = ({ part, partToReplace, onClick, ...rest }: Props) => {
  const designId = useDesignId();
  const { showErrorToast, showSuccessToast } = useDesignToast();
  const { mutate: replaceDesignPart, isPending, isSuccess, error } = useReplaceDesignPartMutation();

  // These don't actually show a toast because when we replace a part, the modal is already closed. This needs a little
  // bit of refactoring to show the toast in the parent component.
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Part replaced', 'The part was successfully replaced');
    }
  }, [isSuccess, showSuccessToast]);

  useEffect(() => {
    if (error) {
      showErrorToast('Error replacing part', error.message);
    }
  }, [error, showErrorToast]);

  // Handle replacing a part
  const handleReplacePart: MouseEventHandler<HTMLButtonElement> = (e) => {
    replaceDesignPart({ designId, partId: partToReplace.id, newPartId: part.id });
    if (onClick) {
      onClick(e);
    }
  };

  return part.partNumber === partToReplace.partData.partNumber ? null : (
    <Button onClick={handleReplacePart} isLoading={isPending} {...rest}>
      Replace part
    </Button>
  );
};

export default ReplacePartButton;
