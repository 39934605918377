import { Input } from '@chakra-ui/react';

const FilterBox = ({
  placeholder = 'Type to filter',
  autoFocus = false,
  value,
  onChange,
}: {
  placeholder?: string;
  autoFocus?: boolean;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <Input
      autoFocus={autoFocus}
      type="text"
      onChange={(event) => onChange(event.target.value)}
      value={value}
      placeholder={placeholder}
      maxW="20rem"
    />
  );
};

export default FilterBox;
