import { Operations } from '../Operations.ts';
import { Graph, isBreakoutPointNode, isMeasurementEdge } from '../../types.ts';
import { getConnectedMeasurements, getConnectedSegments, shouldCreateMeasurementEdge } from '../../utils/graph.ts';
import { createAndAddBreakoutPointNode, findNodeById } from '../NodeFactory.ts';
import { createAndAddMeasurementEdge, createAndAddSegmentEdge, findEdge, removeEdgeIfExists } from '../EdgeFactory.ts';
import { findBundle, removeBundleFromSegment } from '../../utils/bundles.ts';
import { UUID } from '@senrasystems/senra-ui';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge.tsx';
import { Edge } from '@xyflow/react';

// Operation to unmerge a bundle into individual segments
export type UnmergeBundleOperation = {
  type: 'UnmergeBundle';
  params: {
    breakoutPointId: UUID;
    bundleId: string;
  };
};

/**
 * Unmerges a bundle into individual segments.
 */
export class UnmergeBundle implements Operations<UnmergeBundleOperation> {
  // Execute the operation
  execute(graph: Graph, operation: UnmergeBundleOperation): Graph {
    const { nodes, edges } = graph;
    const { breakoutPointId, bundleId } = operation.params;

    // Step 1: Find the breakout point node
    const breakoutPointNode = findNodeById(nodes, breakoutPointId, isBreakoutPointNode);

    if (!breakoutPointNode) {
      // eslint-disable-next-line no-console
      console.warn(`Breakout point node not found: ${breakoutPointId}`);
      return graph;
    }

    // Step 2: Find connected segments
    const connectedSegments = getConnectedSegments(edges, breakoutPointNode.id, bundleId);

    if (connectedSegments.length === 0) {
      // eslint-disable-next-line no-console
      console.warn(`No connected segments found for breakout point node: ${breakoutPointId}`);
      return graph;
    }

    // Step 3: Create a new breakout point node
    const newBreakoutPointNode = createAndAddBreakoutPointNode(nodes, {
      x: breakoutPointNode.position.x,
      y: breakoutPointNode.position.y + 50,
    });

    if (!newBreakoutPointNode) {
      // eslint-disable-next-line no-console
      console.warn('Failed to create a new breakout point node.');
      return graph;
    }

    // Step 4: For each segment, create a new segment with the new breakout point node, and transfer the bundle
    connectedSegments.forEach((edge: Edge<SegmentEdgeData>) => {
      // Skip edges without data, this should not happen
      if (!edge.data) {
        return;
      }

      // Find the bundle to split out to the new breakout point node
      const bundle = findBundle(edge.data.bundles, bundleId);

      if (bundle) {
        // Create a new segment with the new breakout point node
        if (edge.source === breakoutPointNode.id) {
          createAndAddSegmentEdge(edges, newBreakoutPointNode.id, edge.target, {
            ...edge.data,
            bundles: [bundle],
          });
          const oldMeasurementEdge = findEdge(edges, breakoutPointNode.id, edge.target, isMeasurementEdge);
          if (oldMeasurementEdge) {
            createAndAddMeasurementEdge(edges, newBreakoutPointNode.id, edge.target, oldMeasurementEdge.data);
          }
        } else if (edge.target === breakoutPointNode.id) {
          createAndAddSegmentEdge(edges, newBreakoutPointNode.id, edge.source, { ...edge.data, bundles: [bundle] });
          const oldMeasurementEdge = findEdge(edges, breakoutPointNode.id, edge.source, isMeasurementEdge);
          if (oldMeasurementEdge) {
            createAndAddMeasurementEdge(edges, newBreakoutPointNode.id, edge.source, oldMeasurementEdge.data);
          }
        }
        // Remove the bundle from the segment
        removeBundleFromSegment(edges, edge.id, bundleId);
      }
    });

    // Step 5: Cleanup measurements
    const connectedMeasurements = getConnectedMeasurements(edges, breakoutPointNode.id);
    connectedMeasurements.forEach((edge) => {
      if (!shouldCreateMeasurementEdge(nodes, edges, edge.source, edge.target)) {
        removeEdgeIfExists(edges, edge.id);
      }
    });

    return { nodes, edges };
  }
}
