import { InputProps, NumberInputProps } from '@chakra-ui/react';
import { useMemo } from 'react';
import { CellContext } from '@tanstack/react-table';
import { Input } from '../cells/Input.tsx';
import { Dropdown, TSelectOption } from '../cells/Dropdown.tsx';
import { Text } from '../cells/Text.tsx';
import { Checkbox } from '../cells/Checkbox.tsx';
import { CurrencyUSD } from '../cells/CurrencyUSD.tsx';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { SenraNumberInput } from '../cells/NumberInput.tsx';

export const useSenraTable = <T,>() => {
  const isReadonly = false;

  const CellInputString = useMemo(
    () => (props: CellContext<T, string>, overrides?: InputProps) => Input(props, isReadonly, overrides),
    [isReadonly],
  );

  const CellNumberInput = useMemo(
    () => (props: CellContext<T, number>, overrides?: NumberInputProps) =>
      SenraNumberInput(props, isReadonly, overrides),
    [isReadonly],
  );

  const CellText = useMemo(() => Text<T, string>, []);

  const CellDropdownInput = useMemo(
    () =>
      (
        props: CellContext<T, string | number | readonly string[] | undefined>,
        options: TSelectOption<unknown>[],
        onCreate?: (inputValue: string) => TSelectOption<unknown>,
        createLabel?: (inputValue: string) => string,
        onChange?: (
          inputValue: string,
        ) => Promise<OptionsOrGroups<TSelectOption<unknown>, GroupBase<TSelectOption<unknown>>>>,
      ) =>
        Dropdown({ props, options, onCreate, onChange, createLabel, isReadonly }),
    [isReadonly],
  );

  const CellCurrencyUSD = useMemo(() => CurrencyUSD<T, string>, []);

  const CellCheckbox = useMemo(() => (props: CellContext<T, boolean>) => Checkbox(props), []);

  const removeRow = (data: T[], rowIndex: number) => {
    return data.filter((_data, index) => index !== rowIndex);
  };

  const addRow = (data: T[], newRow: T, rowIndex: number) => {
    const dataCopy = [...data];
    dataCopy.splice(rowIndex, 0, newRow);
    return dataCopy;
  };

  return {
    CellInputString,
    CellNumberInput,
    CellText,
    CellDropdownInput,
    CellCurrencyUSD,
    CellCheckbox,
    addRow,
    removeRow,
  };
};
