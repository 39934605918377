import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useDesignToast = () => {
  const toast = useToast();

  const showErrorToast = useCallback(
    (title: string, description: string) => {
      const toastId = 'error-toast';
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: title,
          description: description,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast],
  );

  const showSuccessToast = useCallback(
    (title: string, description: string) => {
      const toastId = 'success-toast';
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: title,
          description: description,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast],
  );

  const showWarningToast = useCallback(
    (title: string, description: string) => {
      const toastId = 'warning-toast';
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: title,
          description: description,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast],
  );

  const showInfoToast = useCallback(
    (title: string, description: string) => {
      const toastId = 'info-toast';
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: title,
          description: description,
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast],
  );

  return {
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    showInfoToast,
  };
};
