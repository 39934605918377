import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Box } from '@chakra-ui/react';
import { defaultLayoutConfig } from '../../config.ts';
import { centerAbsolute } from '@web/apps/styles.ts';

export type LayoutPointNodeData = {
  value: string;
};

export const defaultLayoutPointNodeData: LayoutPointNodeData = {
  value: '',
};

export type LayoutPointNode = Node<LayoutPointNodeData>;

/**
 * Layout point node component.
 * @param props
 * @constructor
 */
const LayoutPointNode = (props: NodeProps<LayoutPointNode>) => {
  const color = props.selected ? defaultLayoutConfig.selectedNodeColor : defaultLayoutConfig.nodeColor;

  return (
    <Box position="relative">
      <LayoutPointShape color={color} />
      <Box sx={{ ...centerAbsolute, visibility: 'hidden' }}>
        <Handle type="source" position={Position.Top} />
      </Box>
    </Box>
  );
};

const LayoutPointShape = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="6.5" fill="white" stroke={color} />
    </svg>
  );
};

export default LayoutPointNode;
