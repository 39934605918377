import CreateOrderForm from './CreateOrderForm';
import Title from '../../../components/Title';
import { Stack } from '@chakra-ui/react';

const CreateOrder = () => {
  return (
    <Stack p={8} spacing={8}>
      <Title title="Create New Order" />
      <CreateOrderForm />
    </Stack>
  );
};

export default CreateOrder;
