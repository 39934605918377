import { Box, BoxProps, HStack, IconButtonProps, Select, SelectProps, Text } from '@chakra-ui/react';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { useCreateDesignPartMutation, useDesignQuery } from '../../../api/queries.ts';
import { Part, PartType } from '@senrasystems/senra-ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { DesignPart } from '@senrasystems/senra-ui';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';

interface Props extends BoxProps {
  part: Part;
  connectorPart?: DesignPart;
  iconButtonProps?: IconButtonProps;
  selectProps?: SelectProps;
}

const AddAccessoryButton = ({ part, connectorPart, iconButtonProps, selectProps, ...rest }: Props) => {
  const designId = useDesignId();
  const { showErrorToast, showSuccessToast } = useDesignToast();
  const { data: design } = useDesignQuery(designId);
  const designParts = design?.designParts || [];
  const connectors = designParts.filter((part) => part.partData.type === PartType.CONNECTOR);
  const [selectedConnector, setSelectedConnector] = useState<DesignPart | null>(null);

  // Mutation to add accessory to connector
  const { mutate: addDesignPart, isPending, isSuccess, error } = useCreateDesignPartMutation();

  // Handle success adding a part
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        'Accessory added',
        `The ${part.type.toLowerCase()} was successfully added to connector ${selectedConnector?.name}`,
      );
    }
  }, [isSuccess, part.type, selectedConnector?.name, showSuccessToast]);

  // Handle errors adding a part
  useEffect(() => {
    if (error) {
      showErrorToast('Error adding part', error.message);
    }
  }, [error, showErrorToast]);

  useEffect(() => {
    setSelectedConnector(connectors[0]);
  }, [connectors]);

  if (connectors.length === 0) {
    return;
  }

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const connector = connectors.find((connector) => connector.id === event.target.value);
    if (connector) {
      setSelectedConnector(connector);
    }
  };

  const handleAddClick = () => {
    const designPartId = connectorPart?.id || selectedConnector?.id;
    if (designPartId) {
      addDesignPart({
        designId,
        data: { name: window.crypto.randomUUID(), partId: part.id, designPartId: designPartId, quantity: 1 },
      });
    }
  };

  return (
    <Box {...rest}>
      <HStack alignItems="center" px={0} py={0}>
        {connectorPart ? (
          <HStack>
            <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {connectorPart.name}
            </Text>
            <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="blue.500">
              {connectorPart.partData.partNumber}
            </Text>
          </HStack>
        ) : (
          <Select onChange={handleSelect} {...selectProps}>
            {connectors.map((connector) => (
              <option
                key={connector.id}
                value={connector.id}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {`${connector.name} ${connector.partData.partNumber}`}
              </option>
            ))}
          </Select>
        )}
        <AddIconButton
          aria-label="Add"
          onClick={handleAddClick}
          bg="gray.300"
          _hover={{ color: 'white', bg: 'blue.500' }}
          isLoading={isPending || !selectedConnector}
          {...iconButtonProps}
        />
      </HStack>
    </Box>
  );
};

export default AddAccessoryButton;
