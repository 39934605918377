import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import ManagedWindow from '../../components/ManagedWindow.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import PartSearchModal from '../PartSearch';
import PartList from './components/PartList.tsx';
import { useState } from 'react';
import { DesignPart, isAccessory, Part, PartStatus, PartType } from '@senrasystems/senra-ui';
import Title from '@web/components/Title.tsx';
import AddIconButton from '../../components/AddIconButton.tsx';
import AddPartButton from './components/AddPartButton.tsx';
import AddAccessoryButton from './components/AddAccessoryButton.tsx';
import ReplacePartButton from './components/ReplacePartButton.tsx';

/**
 * AssemblyNavigator component displays the parts used to build the Design.
 * @constructor
 */
const AssemblyNavigator = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [partTypeFilters, setPartTypeFilters] = useState<PartType[]>([]);
  const [connectorPart, setConnectorPart] = useState<DesignPart | undefined>();
  const [replacementPart, setReplacementPart] = useState<DesignPart | undefined>();
  const [expandAll, setExpandAll] = useState(true);

  // If we're adding a part, allow the caller determine the part types to filter by
  const handleAddPart = (filters: PartType[], connector?: DesignPart) => {
    setPartTypeFilters(filters);
    if (connector) setConnectorPart(connector);
    setReplacementPart(undefined);
    setExpandAll(true);
    onOpen();
  };

  // If we're replacing a part, set the part type filters to be the type of the part we're replacing
  const handleReplacePart = (part: DesignPart) => {
    setPartTypeFilters([part.partData.type]);
    setConnectorPart(undefined);
    setReplacementPart(part);
    onOpen();
  };

  // Close the modal and reset the filters and replacement part
  const handleOnClose = () => {
    setPartTypeFilters([]);
    setConnectorPart(undefined);
    setReplacementPart(undefined);
    setExpandAll(false);
    onClose();
  };

  // Render the action button for search result from the part search modal
  const renderPartAction = (part: Part) => {
    const isDraft = part.status === PartStatus.DRAFT;
    const visibility = isDraft ? 'hidden' : 'inherit';

    // If we're replacing a part, show the replace part button
    if (replacementPart) {
      return (
        <ReplacePartButton
          part={part}
          partToReplace={replacementPart}
          onClick={onClose}
          colorScheme="blue"
          size="sm"
          visibility={visibility}
        />
      );
    }

    // If we're adding a part, and we have a connector part, show the add accessory button
    if (connectorPart) {
      return <AddAccessoryButton part={part} connectorPart={connectorPart} visibility={visibility} />;
    }

    // If it's an accessory, and we're adding a general part (not an ungrouped part), show the add accessory button
    // that has a connector selection
    if (isAccessory(part.type) && partTypeFilters.length === Object.values(PartType).length) {
      return <AddAccessoryButton part={part} visibility={visibility} />;
    }

    // Otherwise, show the add part button
    return <AddPartButton part={part} visibility={visibility} />;
  };

  return (
    <ManagedWindow title="Assembly Navigator" routeName={RouteNames.DESIGNS.DESIGN_PARTS} showTitle={false}>
      {({ editMode, renderWindowIcon }) => (
        <Box>
          <Box h="60px" p={4}>
            <Title
              title={
                <HStack>
                  <Box>Assembly Navigator</Box>
                  <Box>{renderWindowIcon?.()}</Box>
                </HStack>
              }
              as="h2"
              size="md"
            >
              <AddIconButton
                aria-label="Add Part"
                variant="primary"
                color={editMode && !isOpen ? 'blue.500' : 'gray.500'}
                onClick={() => handleAddPart(Object.values(PartType))}
                opacity={editMode && !isOpen ? 1 : 0}
                transition="opacity .3s ease-in-out"
              />
            </Title>
          </Box>
          <PartList
            editMode={editMode && !isOpen}
            onAddPart={handleAddPart}
            onReplacePart={handleReplacePart}
            expandAllItems={expandAll}
          />
          <PartSearchModal
            isOpen={isOpen}
            onClose={handleOnClose}
            partTypeFilters={partTypeFilters}
            renderPartAction={renderPartAction}
          />
        </Box>
      )}
    </ManagedWindow>
  );
};

export default AssemblyNavigator;
