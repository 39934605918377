import { Node, XYPosition } from '@xyflow/react';
import { DesignPart, ResolvedConnection } from '@senrasystems/senra-ui';
import { Operations } from '../Operations.ts';
import { Graph, isControlPointNode } from '../../types.ts';
import { createAndAddSegmentEdge } from '../EdgeFactory.ts';
import PositionManager from '../PositionManager.ts';
import { createAndAddDesignPartNode } from '../NodeFactory.ts';
import { findDirectSegment, findPath } from '../../utils/graph.ts';
import { createBundle, createBundleId, updateBundleWithConnection } from '../../utils/bundles.ts';

// Operation to build a graph
export type BuildLayoutOperation = {
  type: 'BuildLayout';
  params: {
    connections: ResolvedConnection[];
    positionManager?: PositionManager;
  };
};

/**
 * Builds the layout from the connections. This operation will work with a new graph or an existing graph and add any
 * missing nodes and edges based on the connections from the design.
 */
export class BuildLayout implements Operations<BuildLayoutOperation> {
  // Execute the operation
  execute(graph: Graph, operation: BuildLayoutOperation): Graph {
    const { nodes, edges } = graph;
    const { connections, positionManager: pm } = operation.params;

    // Validate parameters
    if (!connections || connections.length === 0) {
      return graph;
    }

    // Create a map of existing nodes for easy access
    const nodesMap: Map<string, Node> = new Map(nodes.map((node) => [node.id, node]));

    // Keep track of added connections to avoid duplicates
    const addedConnections = new Set<string>();

    // For each connection:
    // 1. Create and add design part nodes if they don't exist
    // 2. Check if a path between the nodes exists
    // 3. If a path doesn't exist, create a new edge
    connections.forEach((conn) => {
      const source = conn.source?.designPart;
      const target = conn.destination?.designPart;

      if (!source || !target || !conn.conductor) return;

      // Add design parts to the graph
      const connectionKey = `${source.id}:${target.id}`;

      if (!addedConnections.has(connectionKey)) {
        // Add source node if it doesn't exist
        const n1 = createAndAddDesignPartNode(Array.from(nodesMap.values()), source, this.getPosition(source, pm));
        if (n1) nodesMap.set(source.id, n1);

        // Add destination node if it doesn't exist
        const n2 = createAndAddDesignPartNode(Array.from(nodesMap.values()), target, this.getPosition(target, pm));
        if (n2) nodesMap.set(target.id, n2);

        // Mark the connection as added
        addedConnections.add(connectionKey);
      }

      // Find a direct segment edge between the source and target design parts
      const directSegment = findDirectSegment(edges, source.id, target.id);

      if (directSegment && directSegment.data) {
        // If a direct segment exists, it means the source and target design parts are already connected. It was added
        // in a previous iteration, so we just need to add the connection to the segment.
        const bundleId = createBundleId(source.id, target.id);
        directSegment.data.bundles = updateBundleWithConnection(directSegment.data.bundles, bundleId, conn.original);
        return;
      }

      // Create a bundle for the connection
      const bundle = createBundle(source.id, target.id, [conn.original]);

      // Check if source and destination are connected either directly or through a path of control points. If a path
      // exists, we don't need to create a new edge. New edges were added in the previous iteration, and control points
      // were added by the user.
      const { exists: pathExists } = findPath(nodes, edges, source.id, target.id, isControlPointNode, bundle);
      if (pathExists) {
        return;
      }

      // By this point, any existing path between the source and target design part has been added to the graph in a
      // previous iteration. This is a new connection that is not represented by a segment, so we can now create a new
      // edge between the source and target design parts. We'll create a new segment edge and add the connection to a
      // new bundle.
      // eslint-disable-next-line no-console
      console.debug('BuildLayout: Creating new edge for connection', source.id, target.id, bundle);
      createAndAddSegmentEdge(edges, source.id, target.id, { bundles: [bundle] });
    });

    return { nodes: Array.from(nodesMap.values()), edges: edges };
  }

  /**
   * Helper function to get the position of a design part. If a position manager is provided, it will be used to get the
   * next position for the part type.
   * @param part
   * @param positionManager
   */
  private getPosition = (part: DesignPart, positionManager?: PositionManager): XYPosition => {
    return positionManager ? positionManager.getNextPosition(part.partData.type) : { x: 0, y: 0 };
  };
}
