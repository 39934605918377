import {
  Box,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ClipboardEvent,
  CSSProperties,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  Cell,
  CellContext,
  Column,
  ColumnDef,
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  GroupColumnDef,
  Header,
  Row,
  RowData,
  Table as ReactTable,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { UseMutationResult } from '@tanstack/react-query';
import Paginate from '../Paginate';

export const enum SenraDataUpdateType {
  EDIT = 'EDIT',
  ADD = 'ADD',
}

interface ISenraTable<T extends RowData> extends Partial<TableOptions<T>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableDefinition: (GroupColumnDef<T, any> | ColumnDef<T, any>)[];
  data: T[];
  testId?: string;
  className?: string;
  newRowTemplate?: T;
  isNewRowEnabled?: boolean;
  justify?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellDefinition?: Partial<GroupColumnDef<T, any>>;
  subrowCellsDefinition?: Partial<Record<keyof T, ISubrowCell<T>>>;
  containerRef?: HTMLDivElement;
  defaultSize?: number;
  isStickyActionColumn?: boolean;
  showFilterColumn?: boolean;
  getSubRows?: (originalRow: T, index?: number) => T[] | undefined;
  onNewRow?: (index: number) => void;
  onSelectedRow?: (row: Row<T>) => void;
  onPaste?: (e: ClipboardEvent<HTMLDivElement>, cell: Cell<T, unknown>) => void;
  onDataUpdate?:
    | React.Dispatch<React.SetStateAction<T[]>>
    | ((data: T[]) => UseMutationResult<T[] | undefined, unknown, void>)
    | ((data: T[], index: number, type: SenraDataUpdateType) => void);
  showHeader?: boolean;
  supportNestedKeys?: boolean;
  isColumnResizable?: boolean;
}

type TSubrowCell<T> = (props: CellContext<T, unknown>) => JSX.Element;

export interface ISubrowCell<T extends RowData> {
  cell: TSubrowCell<T>;
}

declare module '@tanstack/table-core' {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, depth: number, columnId: string, value: unknown) => void;
    getSubrowCell?: (key: keyof TData) => TSubrowCell<TData> | undefined;
    getHoveredRow: () => { index: number; row?: Row<TData> };
  }
}

export const SenraTable: <T extends object>(s: ISenraTable<T>) => React.ReactElement<ISenraTable<T>> = ({
  className,
  cellDefinition,
  tableDefinition,
  data,
  newRowTemplate,
  isNewRowEnabled = true,
  justify = 'center',
  subrowCellsDefinition,
  containerRef,
  defaultSize,
  onDataUpdate,
  onNewRow,
  onSelectedRow,
  onPaste,
  getSubRows,
  showHeader = true,
  showFilterColumn = false,
  supportNestedKeys = true,
  isStickyActionColumn = false,
  isColumnResizable = true,
  testId = 'senra-table',
  ...options
}) => {
  type SenraDataType = (typeof data)[0];
  const [columnResizeMode] = useState<ColumnResizeMode>('onChange');
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>(-1);
  const [hoveredRow, setHoveredRow] = useState<Row<SenraDataType>>();
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const tempColumnMapping: Record<string, boolean> = {};
    tableDefinition.forEach((col: ColumnDef<SenraDataType, unknown>) => {
      if (col.id) {
        tempColumnMapping[col.id] = true;
      }
    });

    setColumnVisibility(tempColumnMapping);
  }, [tableDefinition]);

  const tableContainer = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLTableCellElement>, rowIndex: number, columnIndex: number) => {
    const currentRow: HTMLElement = tbodyRef.current?.children.item(rowIndex) as HTMLElement;
    switch (event.key) {
      case 'ArrowUp':
        // previousElementSibling is null when the current row is the first row
        const previousRow = currentRow.previousElementSibling as HTMLElement | null;
        previousRow?.children.item(columnIndex)?.querySelector('input')?.focus();
        break;
      case 'ArrowDown':
        // nextElementSibling is null when the current row is the last row
        const nextRow: HTMLElement | null = currentRow.nextElementSibling as HTMLElement | null;
        nextRow?.children.item(columnIndex)?.querySelector('input')?.focus();
        break;
      default:
        break;
    }
  };

  const table = useReactTable({
    manualPagination: true,
    ...options,
    data,
    columns: tableDefinition,
    columnResizeMode,
    defaultColumn: {
      ...cellDefinition,
      ...{
        size: defaultSize,
        minSize: 20,
        maxSize: Number.MAX_SAFE_INTEGER,
      },
    },
    enableExpanding: true,
    state: {
      expanded: true,
      columnVisibility,
      ...options.state,
    },
    getSubRows: getSubRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex: number, depth: number, columnId: string, value: unknown) => {
        // Skip page index reset until after next rerender
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            // nested row
            if (depth) {
              if (getSubRows) {
                const subrow = getSubRows(row);
                if (supportNestedKeys && subrow) {
                  const nestedKey = columnId.split('_');
                  _.set<SenraDataType>(subrow, nestedKey, value);
                }
              }
              return row;
            }

            // nested key
            if (supportNestedKeys && columnId.includes('_')) {
              const nestedKey = columnId.split('_');
              _.set<SenraDataType>(row, nestedKey, value);
              return row;
            } else {
              return {
                ...data[rowIndex],
                [columnId]: value,
              };
            }
          }
          return row;
        });
        if (onDataUpdate) {
          onDataUpdate(newData, rowIndex, SenraDataUpdateType.EDIT);
        }
      },
      getSubrowCell: (columnId: keyof SenraDataType) => {
        const subrowCellForColumn = subrowCellsDefinition?.[columnId];
        if (subrowCellForColumn) {
          return subrowCellForColumn.cell;
        }
      },
      getHoveredRow: () => {
        return { index: hoveredRowIndex, row: hoveredRow };
      },
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainer.current,
    estimateSize: () => 70,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 10,
  });

  const onAddNewRow = (index: number) => {
    if (onDataUpdate && newRowTemplate) {
      onDataUpdate(
        [...data.slice(0, index + 1), newRowTemplate, ...data.slice(index + 1)],
        index + 1,
        SenraDataUpdateType.ADD,
      );
    }
    if (onNewRow) {
      onNewRow(index + 1);
    }
  };

  const onRowHover = (index: number, row: Row<SenraDataType>) => {
    setHoveredRowIndex(index);
    setHoveredRow(row);
  };

  const onSelectRow = (_index: number, row: Row<SenraDataType>) => {
    if (onSelectedRow) {
      onSelectedRow(row);
    }
  };

  const SenraTableRow = useCallback(TableRow, [isNewRowEnabled, columnVisibility]);

  const headerColor = useColorModeValue('gray.400', 'gray.700');
  const bgColor = useColorModeValue('gray.200', 'gray.900');

  return (
    <Flex height="full" align="center" justify={justify} flexDirection={'column'} overflowY="auto">
      <TableContainer
        className={className}
        height="full"
        maxH={parent.innerHeight}
        width="full"
        overflowY="auto"
        overflowX="auto"
        ref={tableContainer}
      >
        <Table
          data-testid={testId}
          variant="unstyled"
          width={isColumnResizable ? table.getCenterTotalSize() : '100%'}
          ref={tableRef}
        >
          {showHeader && (
            <Thead bg={headerColor} position="sticky" top="0">
              <SenraTableHeader
                table={table}
                tableContainerRef={tableContainer}
                columnVisibility={columnVisibility}
                columnResizeMode={columnResizeMode}
                isStickyActionColumn={isStickyActionColumn}
                showFilterColumn={showFilterColumn}
                onVisibilityChange={setColumnVisibility}
              />
            </Thead>
          )}
          <Tbody position="relative" ref={tbodyRef} h={`${rowVirtualizer.getTotalSize()}px`}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const index = virtualRow.index;
              const row = rows[virtualRow.index] as Row<SenraDataType>;
              return (
                <SenraTableRow
                  key={row.id}
                  index={index}
                  isNewRowEnabled={isNewRowEnabled}
                  virtualRow={virtualRow}
                  onSelectRow={() => onSelectRow(index, row)}
                  onAddNewRow={onAddNewRow}
                  onRowHover={() => onRowHover(index, row)}
                  isStickyActionColumn={isStickyActionColumn}
                  onRef={(e) => rowVirtualizer.measureElement(e)}
                >
                  {row.getVisibleCells().map((cell, columnIndex) => {
                    const isNested = row.depth > 0;
                    const nestedCell =
                      table.options.meta?.getSubrowCell?.(cell.column.id as keyof SenraDataType) ??
                      cell.column.columnDef.cell;
                    return cell.column.getIsVisible() ? (
                      <Td
                        height="100%"
                        paddingLeft="16px"
                        display={'inline-block'}
                        overflow={'hidden'}
                        bg={bgColor}
                        zIndex={1}
                        key={cell.id}
                        minWidth={
                          cell.column.id === 'actions'
                            ? !isStickyActionColumn
                              ? cell.column.getSize()
                              : '50px'
                            : cell.column.getSize()
                        }
                        onKeyDown={(e) => handleKeyDown(e, index, columnIndex)}
                        style={getCommonPinningStyles(cell.column, isStickyActionColumn)}
                        onPaste={(e) => onPaste?.(e, cell)}
                      >
                        {flexRender(isNested ? nestedCell : cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ) : null;
                  })}
                </SenraTableRow>
              );
            })}
            {isNewRowEnabled && !table.getRowModel().rows.length && (
              <RowAction index={0} onAddNewRow={onAddNewRow} rowHeight={60} />
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {table.getPageCount() > 0 ? (
        <Paginate
          onPageChange={({ selected }) => {
            if (selected < table.getState().pagination.pageIndex) {
              table.previousPage();
            } else {
              table.nextPage();
            }
          }}
          selectedPage={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
        />
      ) : null}
    </Flex>
  );
};

const TableHeaderResizer = <T,>({
  header,
  columnResizeMode,
  table,
  props,
}: {
  header: Header<T, unknown>;
  columnResizeMode: string;
  table: ReactTable<T>;
  props?: HTMLAttributes<HTMLDivElement>;
}) => {
  const bgColor = useColorModeValue('gray.200', 'gray.900');
  return (
    <Box
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
      className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
      bg={bgColor}
      style={{
        position: 'absolute',
        right: '0',
        top: '0',
        height: '100%',
        width: '2px',
        cursor: 'col-resize',
        userSelect: 'none',
        touchAction: 'none',
        transform:
          columnResizeMode === 'onEnd' && header.column.getIsResizing()
            ? `translateX(${table.getState().columnSizingInfo.deltaOffset ?? 0}px)`
            : '',
        ...{
          ...(header.column.getIsResizing() && {
            background: 'blue.500',
            opacity: 1,
          }),
        },
      }}
      {...props}
    />
  );
};

const TableRow = ({
  children,
  index,
  isNewRowEnabled = true,
  virtualRow,
  onRef,
  onAddNewRow,
  onRowHover,
  onSelectRow,
}: {
  children: React.ReactNode;
  index: number;
  isNewRowEnabled: boolean;
  isStickyActionColumn: boolean;
  virtualRow?: VirtualItem<never>;
  onRef: (ele: HTMLTableRowElement) => void;
  onAddNewRow: (index: number) => void;
  onRowHover: () => void;
  onSelectRow: () => void;
}) => {
  const [showActions, setShowActions] = useState(false);

  const hoverColor = useColorModeValue('gray.100', 'gray.800');

  return (
    <>
      <Box
        as="tr"
        data-index={virtualRow?.index}
        ref={(e: HTMLTableRowElement) => onRef(e)} //measure dynamic row height
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          minWidth: '100%',
          transform: `translateY(${virtualRow?.start}px)`,
        }}
        cursor="pointer"
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          if ((e.target as HTMLElement).tagName.toLowerCase() !== 'td') {
            return;
          }
          onSelectRow();
        }}
        onMouseEnter={() => {
          setShowActions(true);
          onRowHover();
        }}
        onMouseLeave={() => {
          setShowActions(false);
        }}
        backgroundColor={`${showActions ? hoverColor : 'transparent'}`}
        borderBottom={`2px solid var(${
          isNewRowEnabled && showActions ? '--chakra-colors-blue-600' : '--chakra-colors-gray-900'
        })`}
      >
        {children}
      </Box>
      {isNewRowEnabled && showActions && (
        <RowAction
          virtualRow={virtualRow}
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
          index={index}
          onAddNewRow={onAddNewRow}
          rowHeight={virtualRow?.size ?? 70}
        />
      )}
    </>
  );
};

const RowAction = ({
  index,
  virtualRow,
  rowHeight,
  onAddNewRow,
  onMouseEnter,
  onMouseLeave,
}: {
  rowHeight: number;
  index: number;
  onAddNewRow: (index: number) => void;
  virtualRow?: VirtualItem<never>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => (
  <Tr
    style={{
      display: 'flex',
      position: 'absolute',
      right: 0,
      padding: 0,
      transform: `translateY(${virtualRow?.start}px)`,
      zIndex: 1,
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <IconButton
      as="td"
      size="xs"
      bg="blue.500"
      top={`${rowHeight - 10}px`}
      right={0}
      zIndex={1}
      aria-label="Insert new row"
      icon={<AddIcon />}
      onClick={() => onAddNewRow(index)}
    />
  </Tr>
);

const ColumnVisibilityMenuItem = <T,>({
  column,
  columnVisibility,
  index,
}: {
  column: Column<T, unknown>;
  columnVisibility: Record<string, boolean>;
  index: number;
}) => {
  const [showEnabled, setShowEnabled] = useState(columnVisibility[column.id]);

  useEffect(() => {
    setShowEnabled(columnVisibility[column.id]);
  }, [columnVisibility, column.id]);

  return typeof column?.columnDef?.header === 'string' ? (
    <MenuItem
      icon={showEnabled ? <ViewIcon boxSize={4} /> : <ViewOffIcon boxSize={4} />}
      command={`(⌘ | ⊞) + ${index}`}
      onClick={() => {
        columnVisibility[column.id] = !columnVisibility[column.id];
        setShowEnabled(!showEnabled);
      }}
    >
      {column?.columnDef?.header ?? ''}
    </MenuItem>
  ) : null;
};

const SenraTableHeader = <T,>({
  table,
  tableContainerRef,
  columnVisibility,
  columnResizeMode,
  isStickyActionColumn,
  showFilterColumn,
  onVisibilityChange,
}: {
  table: ReactTable<T>;
  tableContainerRef: RefObject<HTMLDivElement>;
  columnVisibility: Record<string, boolean>;
  columnResizeMode: ColumnResizeMode;
  isStickyActionColumn: boolean;
  showFilterColumn: boolean;
  onVisibilityChange: (columnVisibility: Record<string, boolean>) => void;
}) => {
  const menuButton = useRef<HTMLButtonElement>();
  const columns = table.getAllColumns();
  const [columnSetting, setColumnSetting] = useState({ ...columnVisibility });
  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    setColumnSetting({ ...columnVisibility });
  }, [columnVisibility]);

  const SenraTableFilter = useCallback(({ column, table }: { column: Column<T>; table: ReactTable<T> }) => {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue() || '';

    return typeof firstValue === 'number' ? (
      <Flex gap="4px">
        <Input
          type="number"
          value={(columnFilterValue as [number, number])[0]}
          onChange={(e) => column.setFilterValue((old: [number, number]) => [e.target.valueAsNumber, old?.[1] || ''])}
          placeholder="Min"
          borderRadius="4px"
          tabIndex={0}
        />
        <Input
          type="number"
          value={(columnFilterValue as [number, number])[1]}
          onChange={(e) => column.setFilterValue((old: [number, number]) => [old?.[0] || 0, e.target.valueAsNumber])}
          placeholder="Max"
          borderRadius="4px"
          tabIndex={0}
        />
      </Flex>
    ) : (
      <Input
        type="text"
        value={columnFilterValue as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder="Search..."
        borderRadius="4px"
        tabIndex={0}
      />
    );
  }, []);

  const textColor = useColorModeValue('gray.900', 'gray.400');

  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id} borderBottom={`2px solid var(--chakra-colors-gray-200)`}>
          {headerGroup.headers.map((header) =>
            showFilterColumn && header.id === 'actions' ? (
              <Th key={header.id} style={getCommonPinningStyles(header.column, isStickyActionColumn)} bg={'gray.200'}>
                <Flex flexDir={'row'} gap={2} justifyContent={'space-around'}>
                  <Box marginLeft="auto" cursor="pointer">
                    <IconButton
                      aria-label={'Search'}
                      variant="outline"
                      icon={<box-icon name="search" color="var(--chakra-colors-gray-400)" />}
                      onClick={() => {
                        setShowFilter(!showFilter);
                      }}
                    ></IconButton>
                  </Box>
                  <Menu
                    closeOnSelect={false}
                    onClose={() => {
                      columns.forEach((c) => {
                        if (columnVisibility[c.id]) {
                          c.toggleVisibility();
                        }
                      });
                      onVisibilityChange(columnSetting);
                    }}
                  >
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<box-icon name="slider-alt" color="var(--chakra-colors-gray-400)"></box-icon>}
                      variant="outline"
                      ref={menuButton}
                    />
                    <Portal containerRef={tableContainerRef}>
                      <MenuList
                        onKeyDown={(e) => {
                          if (e.metaKey) {
                            if (e.code.includes('Digit') && columns[Number(e.key)]) {
                              const col = columns[Number(e.key)];
                              columnSetting[col.id] = !columnSetting[col.id];
                              setColumnSetting({ ...columnSetting });
                            }
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Meta') {
                            onVisibilityChange(columnSetting);
                          }

                          menuButton?.current?.click();
                        }}
                      >
                        <DndContext>
                          <SortableContext items={[]}>
                            {columns.map((column, index) =>
                              column.columnDef.id !== 'actions' ? (
                                <ColumnVisibilityMenuItem
                                  key={column.id}
                                  column={column}
                                  columnVisibility={columnSetting}
                                  index={index}
                                />
                              ) : null,
                            )}
                          </SortableContext>
                        </DndContext>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Flex>
              </Th>
            ) : (
              <Th
                borderRight={`2px solid var(--chakra-colors-gray-200)`}
                style={{
                  fontWeight: 'initial',
                  fontSize: '12px',
                  width: header.getSize(),
                }}
                key={header.id}
                colSpan={header.colSpan}
                position="relative"
                textAlign="start"
                paddingLeft="16px"
                letterSpacing="normal"
                color={textColor}
                display={'inline-block'}
                overflow={'hidden'}
              >
                {header.isPlaceholder ? null : (
                  <>
                    <div>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanFilter() && showFilter && (
                        <Box marginTop="8px">
                          <SenraTableFilter column={header.column} table={table} />
                        </Box>
                      )}
                    </div>
                    <TableHeaderResizer header={header} columnResizeMode={columnResizeMode} table={table} />
                  </>
                )}
              </Th>
            ),
          )}
        </Tr>
      ))}
    </>
  );
};

const getCommonPinningStyles = <T,>(column: Column<T>, isStickyActionColumn: boolean): CSSProperties => {
  const isPinned = column.id === 'actions' && isStickyActionColumn ? 'right' : column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right';
  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-1px 0 1px -1px var(--chakra-colors-gray-700) inset'
      : isFirstRightPinnedColumn
        ? '1px 0 1px -1px var(--chakra-colors-gray-700) inset'
        : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: 1,
    position: isPinned ? 'sticky' : 'relative',
    padding: isPinned ? '16px' : '16px',
    background: isPinned ? 'var(--chakra-colors-gray-900)' : 'transparent',
    width: column.getSize(),
  };
};
