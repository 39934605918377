import { ReactNode } from 'react';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';

interface Props {
  headerLabel: string;
  children: ReactNode;
}

const NotesAccordionItem = ({ headerLabel, children }: Props) => {
  return (
    <AccordionItem>
      <AccordionButton display="flex" gap={4} fontWeight="600" alignItems="center">
        <AccordionIcon />
        {headerLabel}
      </AccordionButton>
      <AccordionPanel borderTopWidth={1} p={0}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default NotesAccordionItem;
