import { Badge } from '@chakra-ui/react';

interface Props {
  count: number;
}

/**
 * CountBadge component displays a badge with a count.
 * @param count
 * @constructor
 */
const CountBadge = ({ count }: Props) => {
  return (
    <Badge
      color="gray.50"
      bg="blue.700"
      boxSize="2em"
      mx={2}
      fontSize="xs"
      borderRadius="md"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      {count}
    </Badge>
  );
};

export default CountBadge;
