/**
 * Environment variables. Variables are defined for local dev in .env.development.
 * For staging and production, the variables are defined in the respective Vercel environment.
 */
export enum Vars {
  ApiBaseUrl,
  Auth0Domain,
  Auth0ClientId,
  Auth0Audience,
  FactoryWebBaseUrl,
}

export const envVars = new Map<number, string | number | undefined>([
  [Vars.ApiBaseUrl, import.meta.env.VITE_API_BASE_URL],
  [Vars.Auth0Domain, import.meta.env.VITE_AUTH0_DOMAIN],
  [Vars.Auth0ClientId, import.meta.env.VITE_AUTH0_CLIENT_ID],
  [Vars.Auth0Audience, import.meta.env.VITE_AUTH0_AUDIENCE],
  [Vars.FactoryWebBaseUrl, import.meta.env.VITE_FACTORY_WEB_BASE_URL],
]);
