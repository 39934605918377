import React from 'react';
import { BOMItem, isLinear, PartType } from '@senrasystems/senra-ui';
import { Box, HStack, StackProps, Text } from '@chakra-ui/react';
import FieldValueList from '../../../components/FieldValueList';
import { usePartData } from '../../../hooks/usePartData.tsx';
import RemoveAlternateButton from '../components/RemoveAlternateButton.tsx';
import QuantityUpdater from './QuantityUpdater.tsx';

interface Props extends StackProps {
  bomItem: BOMItem;
  editMode?: boolean;
}

/**
 * BOMItemDetails component displays the details of a BOM item.
 * @param bomItem
 * @param editMode
 * @param rest
 * @constructor
 */
const BOMItemDetails: React.FC<Props> = ({ bomItem, editMode = false, ...rest }) => {
  const { part, usages, alternates } = bomItem;
  const data = usePartData(part);

  // A bit of a hack, but we need to use displayType from BOMItem.displayType, and I don't want to make the more generic
  // usePartData hook more complex to handle this one-off case. If there are more cases like this, we can refactor.
  data[0].value = bomItem?.displayType || data[0].value;

  const renderUsages = () => {
    if (usages.length === 0) {
      return <Text>No usages</Text>;
    }

    const updatable =
      isLinear(part.partData.type) ||
      part.partData.type === PartType.CONTACT ||
      part.partData.type === PartType.GENERIC;

    return usages.map((usage) => (
      <HStack key={usage.name} w="full" mb={2}>
        <Box as={Text} w="100px">
          {usage.name}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <QuantityUpdater
            key={usage.name}
            part={usage.designPart}
            initialQuantity={parseFloat(usage.designPart.quantity)}
            unit={bomItem?.unit}
            editMode={editMode && updatable}
          />
        </Box>
      </HStack>
    ));
  };

  const renderAlternates = () => {
    if (alternates.length === 0) {
      return <Text>No alternates</Text>;
    }

    return alternates.map((alternate) => (
      <HStack key={alternate.id} mb={2}>
        <Text w="100px">{alternate.alternatePart.partNumber}</Text>
        <RemoveAlternateButton
          aria-label="Remove alternate part"
          part={alternate}
          visibility={editMode ? 'visible' : 'hidden'}
          opacity={editMode ? 1 : 0}
          transition="opacity .3s ease-in-out, color .3s ease-in-out"
        />
      </HStack>
    ));
  };

  return (
    <HStack alignItems="flex-start" gap={4} p={4} {...rest}>
      <HStack flex={2}>
        <FieldValueList data={data} fieldProps={{ w: '120px' }} />
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Usage</Box>
        <Box>{renderUsages()}</Box>
      </HStack>
      <HStack alignItems="flex-start" pb={2} flex={1}>
        <Box fontWeight={500}>Alternates</Box>
        <Box>{renderAlternates()}</Box>
      </HStack>
    </HStack>
  );
};
export default BOMItemDetails;
