import { OrderCommentDto } from '../../common/lib';
import { useEffect, useRef, useState } from 'react';
import { OrderCommentBody, OrderCommentEditingBody } from './OrderCommentBody';
import { OrderCommentHeader } from './OrderCommentHeader';
import { OrderCommentFooter } from './OrderCommentFooter';
import { OrderCommentReply } from './OrderCommentReply';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import AttachmentsStack from '../AttachmentsStack';

enum UrlParamKeys {
  commentId = 'c',
}

interface Props {
  orderId: string;
  data: OrderCommentDto;
  userId?: string;
  nestingLevel: number;
  canReply: boolean;
  isEmployee: boolean;
  onDeleteComment: () => void;
  reloadComments: () => void;
}

/**
 * Order comment component
 */
export default function OrderComment({
  orderId,
  data,
  userId,
  nestingLevel,
  canReply,
  isEmployee,
  onDeleteComment,
  reloadComments,
}: Props) {
  const ref = useRef<null | HTMLDivElement>(null);
  const [urlParams] = useSearchParams();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const isOwnedComment = !!userId && data.user?.id === userId;
  const selectedCommentId = urlParams.get(UrlParamKeys.commentId) ?? undefined;
  const isCurrentId = selectedCommentId === data.id;

  useEffect(() => {
    if (isCurrentId) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCurrentId]);

  return (
    <Box
      id={`comment-${data.id}`}
      ref={ref}
      py={3}
      pl={3}
      borderBottomWidth={1}
      ml={[0, 8, 16][nestingLevel]}
      bgColor={isCurrentId ? 'gray.600' : 'inherit'}
    >
      <OrderCommentHeader data={data} isOwnedComment={isOwnedComment} isEmployee={isEmployee} />
      {isEditing ? (
        <OrderCommentEditingBody
          orderId={orderId}
          data={data}
          setIsEditing={setIsEditing}
          reloadComments={reloadComments}
        />
      ) : (
        <OrderCommentBody data={data} />
      )}
      {isReplying && (
        <OrderCommentReply
          orderId={orderId}
          data={data}
          setIsReplying={setIsReplying}
          reloadComments={reloadComments}
        />
      )}
      <AttachmentsStack attachments={data.attachments} />
      <OrderCommentFooter
        onDeleteComment={onDeleteComment}
        setIsEditing={setIsEditing}
        setIsReplying={setIsReplying}
        isEditing={isEditing}
        canEdit={isOwnedComment}
        canReply={canReply}
      />
    </Box>
  );
}
