/* eslint-disable no-console */
import { useEffect } from 'react';
import { NoteType } from '@senrasystems/senra-ui';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import PositionManager from '../graph/PositionManager.ts';
import { GraphOperation, operationsMap } from '../graph/Operations.ts';
import { Graph } from '../types.ts';
import { BuildLayout as BuildLayoutOperation } from '../graph/operations/BuildLayout.ts';
import { RebuildMeasurements as RebuildMeasurementsOperation } from '../graph/operations/RebuildMeasurements.ts';
import { UpdateBuildNotes as UpdateBuildNotesOperation } from '../graph/operations/UpdateBuildNotes.ts';
import { useUpdateDesignMutation } from '../../../api/queries.ts';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';
import { useDesignOverview } from '../../../hooks/useDesignOverview.tsx';
import { useLayoutData } from '../../../hooks/useLayoutData.tsx';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useUpdateConductorLengths } from './useUpdateConductorLengths.tsx';

/**
 * Hook for building the layout, and executing operations on the layout.
 */
export const useLayoutBuilder = () => {
  // Get the design, layout data, and connections
  const design = useDesignOverview();
  const { layoutData } = useLayoutData();
  const { connections } = useConnections();
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);

  // Get the ReactFlow API methods
  const { getNodes, setNodes, getEdges, setEdges, getViewport, setViewport } = useReactFlow();

  // Get the update design mutation and error handler
  const { mutate: updateDesign, error: updateDesignError } = useUpdateDesignMutation();
  const { showErrorToast } = useDesignToast();

  // Get the update conductor lengths function
  const updateConductorLengths = useUpdateConductorLengths(design.id);

  useEffect(() => {
    if (updateDesignError) {
      showErrorToast('Error updating design', updateDesignError.message);
    }
  }, [updateDesignError, showErrorToast]);

  // Load the layout from design.layoutData
  const loadLayout = async () => {
    console.time('Load Layout');
    try {
      const { nodes = [], edges = [], viewport } = layoutData;
      if (viewport) {
        console.debug('Loading layout from design.layoutData', layoutData);
        const { x = 0, y = 0, zoom = 1 } = viewport;
        await setViewport({ x, y, zoom });
      } else {
        console.debug('No existing layout found, building new graph with empty nodes and edges.');
      }
      updateLayout(nodes, edges);
      updateConductorLengths();
    } catch (error) {
      console.error('Failed to parse layout:', error);
      updateLayout([], []);
    } finally {
      console.timeEnd('Load Layout');
    }
  };

  // Save the layout to design.layoutData
  const saveLayout = (nodes: Node[], edges: Edge[]) => {
    console.time('Save Layout');
    const layoutData = { nodes, edges, viewport: getViewport() };
    console.debug('Saving layout to design.layoutData', layoutData);
    updateDesign({
      designId: design.id,
      data: {
        name: design.name,
        partNumber: design.partNumber,
        partRevision: design.partRevision,
        description: design.description,
        lengthUnit: design.lengthUnit,
        layoutData,
      },
    });
    console.timeEnd('Save Layout');
  };

  // Reset the layout to the default state
  const resetLayout = () => {
    console.debug('Resetting layout and reloading the page');
    saveLayout([], []);
    window.location.reload();
  };

  /**
   * Helper to update the graph with the given nodes and edges.
   * @param nodes
   * @param edges
   */
  const updateLayout = (nodes: Node[] = getNodes(), edges: Edge[] = getEdges()) => {
    console.time('Update Layout');
    console.debug('Starting update', { nodes, edges });

    // Step 1: Construct the graph by adding missing nodes and edges based on the design connections.
    console.time('BuildLayout');
    const buildGraphOperation = new BuildLayoutOperation();
    const builtGraph = buildGraphOperation.execute(
      { nodes: nodes, edges: edges },
      { type: 'BuildLayout', params: { connections, positionManager: new PositionManager() } },
    );
    console.debug('Graph after BuildLayout', builtGraph);
    console.timeEnd('BuildLayout');

    // Step 2: Rebuild the measurement edges, ensuring all necessary edges are present with their data restored.
    console.time('RebuildMeasurements');
    const refreshMeasurementsOperation = new RebuildMeasurementsOperation();
    const refreshedGraph = refreshMeasurementsOperation.execute(
      { nodes: builtGraph.nodes, edges: builtGraph.edges },
      { type: 'RebuildMeasurements', params: {} },
    );
    console.debug('Graph after RebuildMeasurements', builtGraph);
    console.timeEnd('RebuildMeasurements');

    // Step 3: Update Build notes
    console.time('UpdateBuildNotes');
    const updateBuildNotesOperation = new UpdateBuildNotesOperation();
    const finalizedGraph = updateBuildNotesOperation.execute(
      { nodes: refreshedGraph.nodes, edges: refreshedGraph.edges },
      { type: 'UpdateBuildNotes', params: { flagNotes } },
    );
    console.debug('Graph after UpdateBuildNotes', finalizedGraph);
    console.timeEnd('UpdateBuildNotes');

    // Step 4: Update the ReactFlow state with the finalized graph.
    console.debug('Final graph state before saving', finalizedGraph);
    setNodes(finalizedGraph.nodes);
    setEdges(finalizedGraph.edges);
    saveLayout(finalizedGraph.nodes, finalizedGraph.edges);
    console.timeEnd('Update Layout');
  };

  /**
   * Execute the given graph operation.
   * @param operation - The operation to execute.
   */
  const executeGraphOperation = <T extends GraphOperation>(operation: T): void => {
    const { type, params } = operation;

    console.time(`Execute Operation: ${type}`);
    console.debug(`Preparing to execute graph operation: ${type}`, params);

    const OperationClass = operationsMap[type];

    if (!OperationClass) {
      console.error('Unsupported operation type:', type);
      throw new Error(`Unsupported operation type: ${type}`);
    }

    const operationInstance = new OperationClass();
    const graph: Graph = {
      nodes: getNodes(),
      edges: getEdges(),
    };

    console.debug(`Executing operation: ${type}`, params, graph);

    // Execute the operation and get the updated graph
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const updatedGraph = operationInstance.execute(graph, operation);

    console.debug(`Finished operation: ${type}`, updatedGraph);

    // Determine if a rebuild is required, then update the graph state
    const requiresRebuild = operationInstance.requiresRebuild;
    if (requiresRebuild) {
      console.debug('Operation requires graph rebuild, updating graph', updatedGraph);
      updateLayout(updatedGraph.nodes, updatedGraph.edges);
    } else {
      console.debug('Operation does not require rebuild, saving graph state', updatedGraph);
      setNodes(updatedGraph.nodes);
      setEdges(updatedGraph.edges);
      saveLayout(updatedGraph.nodes, updatedGraph.edges);
    }

    console.timeEnd(`Execute Operation: ${type}`);
  };

  return {
    loadLayout,
    saveLayout,
    resetLayout,
    updateLayout,
    executeGraphOperation,
  };
};
