import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { GraphOperation } from '../../graph/Operations.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { BreakoutPointNode } from '../../components/nodes/BreakoutPointNode.tsx';
import { useReactFlow } from '@xyflow/react';
import { getConnectedSegments } from '../../utils/graph.ts';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useFlagNoteActions } from './useFlagNoteActions.tsx';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';

/**
 * Hook for breakout point actions.
 */
export const useBreakoutPointActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const { getDesignPartById } = useDesignParts();
  const { getEdges } = useReactFlow();

  // Extract the node from the element
  const node = element as BreakoutPointNode;

  // Extract unique bundles connected to the node
  const bundles = node
    ? Array.from(
        new Map(
          getConnectedSegments(getEdges(), node.id)
            .flatMap((edge) => (edge.data as SegmentEdgeData).bundles || [])
            .map((bundle) => [bundle.id, bundle]), // Create entries for the Map
        ).values(),
      )
    : [];

  // Add Flag note actions
  const { flagNoteActions } = useFlagNoteActions(node?.id);

  const onRemove = () => {
    const params: GraphOperation = { type: 'RemoveControlPoint', params: { nodeId: node.id } };
    executeOperation(params);
    closeMenu();
  };

  const onUnmerge = (bundleId: string) => {
    const params: GraphOperation = { type: 'UnmergeBundle', params: { breakoutPointId: node.id, bundleId } };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        <MenuItem onClick={onRemove}>Remove</MenuItem>
      </MenuGroup>
      {bundles.length > 1 && (
        <MenuGroup title="Unmerge Bundle">
          {bundles.map((bundle) => (
            <MenuItem key={bundle.id} onClick={() => onUnmerge(bundle.id)}>
              {getDesignPartById(bundle.sourceDesignPartId)?.name} to{' '}
              {getDesignPartById(bundle.destinationDesignPartId)?.name}
            </MenuItem>
          ))}
        </MenuGroup>
      )}
      <MenuDivider />
      {flagNoteActions}
    </>,
  );

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.BreakoutPoint,
  } as MenuActions;
};
