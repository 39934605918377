import { MouseEvent, Fragment } from 'react';
import { Node, Edge } from '@xyflow/react';
import { MenuActions } from './actionsTypes';
import { useBreakoutPointActions } from './useBreakoutPointActions';
import { useConnectorActions } from './useConnectorActions';
import { useLayoutPointActions } from './useLayoutPointActions';
import { useMeasurementActions } from './useMeasurementActions';
import { usePaneActions } from './usePaneActions';
import { usePassiveActions } from './usePassiveActions';
import { useSegmentActions } from './useSegmentActions';
import { useSpliceActions } from './useSpliceActions';

export const useLayoutActions = () => {
  // Element Context menu actions
  const paneActions = usePaneActions();
  const breakoutPointActions = useBreakoutPointActions();
  const connectorActions = useConnectorActions();
  const layoutPointActions = useLayoutPointActions();
  const passiveActions = usePassiveActions();
  const spliceActions = useSpliceActions();
  const measurementActions = useMeasurementActions();
  const segmentActions = useSegmentActions();

  const actionsArray: MenuActions[] = [
    paneActions,
    breakoutPointActions,
    connectorActions,
    layoutPointActions,
    passiveActions,
    spliceActions,
    measurementActions,
    segmentActions,
  ];

  // Close all context menus
  const closeAllMenus = () => {
    actionsArray.forEach((actions) => actions.closeMenu());
  };

  // Generic context menu handler
  const onGraphElementContextMenu = (event: MouseEvent, element: Node | Edge) => {
    event.preventDefault();
    closeAllMenus();

    const elementTypeActions = actionsArray.find((actions) => actions.elementType === element.type);

    if (elementTypeActions) {
      elementTypeActions.showMenu(event, element);
    } else {
      throw new Error('Invalid type.');
    }
  };

  const onPaneContextMenu = (event: MouseEvent | globalThis.MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    paneActions.showMenu(event);
  };

  // Context menus
  const contextMenus = (
    <>
      {actionsArray.map(({ menu, elementType }, index) => (
        <Fragment key={elementType || index}>{menu}</Fragment>
      ))}
    </>
  );

  return {
    closeAllMenus,
    onGraphElementContextMenu,
    onPaneContextMenu,
    contextMenus,
  };
};
