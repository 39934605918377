import { apiGetInvitedUser, apiGetProfile } from '../api/profile-api';
import { User } from '@senrasystems/senra-ui';
import { useQuery } from '@tanstack/react-query';

const userCacheKey = 'currentUser';
const invitedUserCacheKey = (userId: string) => ['users', userId];

export const useCurrentUser = () =>
  useQuery({
    queryKey: [userCacheKey],
    queryFn: () => apiGetProfile().then((data) => data as User),
  });

export const useInvitedUser = (userId: string) =>
  useQuery({
    queryKey: invitedUserCacheKey(userId),
    queryFn: () => apiGetInvitedUser(userId).then((data) => data as User),
  });
