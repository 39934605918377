import { Box, Button, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { RouteNames } from '@web/consts/routeNames.ts';

interface NavigablePartType {
  name: string;
  path: string;
  isSubType?: boolean;
}

interface NavigablePartTypes {
  [key: string]: NavigablePartType & { subType?: NavigablePartType };
}

const PartsSideNav = () => {
  const navigablePartTypes: NavigablePartTypes = {
    CONNECTORS: {
      name: 'Connectors',
      path: RouteNames.PARTS.CONNECTORS,
      subType: { name: 'Insert Arrangements', path: RouteNames.PARTS.INSERT_ARRANGEMENTS, isSubType: true },
    },
    CONTACTS: { name: 'Contacts', path: RouteNames.PARTS.CONTACTS },
    WIRES: { name: 'Wires', path: RouteNames.PARTS.WIRES },
    CABLES: { name: 'Cables', path: RouteNames.PARTS.CABLES },
    BACKSHELLS: { name: 'Backshells', path: RouteNames.PARTS.BACKSHELLS },
    SPLICES: { name: 'Splices', path: RouteNames.PARTS.SPLICES },
    PIGTAILS: { name: 'Pigtails', path: RouteNames.PARTS.PIGTAILS },
    PASSIVES: { name: 'Passives', path: RouteNames.PARTS.PASSIVES },
    OVERWRAPS: { name: 'Overwraps', path: RouteNames.PARTS.OVERWRAPS },
    GENERICS: { name: 'Generic', path: RouteNames.PARTS.GENERICS },
  };

  return (
    <Box minW="225px" h="full" borderRight="2px" borderColor="gray.200">
      <Box borderBottom="2px" borderColor="gray.200" p={8}>
        <Heading as="h1" size="md">
          Part Types
        </Heading>
      </Box>

      <Stack spacing={0}>
        {Object.values(navigablePartTypes).map((type) => (
          <Box key={type.name}>
            <PartTypeButton type={type} />
            {type.subType && <PartTypeButton type={type.subType} />}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

const PartTypeButton = ({ type }: { type: NavigablePartType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = matchPath(location.pathname, type.path);
  const activeBlue = useColorModeValue('blue.100', 'blue.800');
  const borderColor = useColorModeValue('white', 'gray.800');

  return (
    <Button
      justifyContent="start"
      variant="ghost"
      width="full"
      height="50px"
      borderRadius="0"
      borderWidth="2px"
      borderColor={isActive ? activeBlue : borderColor}
      boxSizing="border-box"
      onClick={() => navigate(type.path)}
      backgroundColor={isActive ? activeBlue : 'initial'}
      pl={type.isSubType ? 8 : undefined}
      _hover={{ borderColor: 'blue.400' }}
    >
      {type.name}
    </Button>
  );
};

export default PartsSideNav;
