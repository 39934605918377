import { Connection, ConnectionPoint } from '@senrasystems/senra-ui';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useCallback } from 'react';

/**
 * To render a list of connections for the Wiring List, we need to merge the connections and connection points into a
 * single list and sort them by the connection point name. We also need to handle the case where a connection point has
 * multiple connections (hence the sortKey).
 */
export interface WiringListRow {
  id: string;
  connectionPoint: ConnectionPoint;
  existingConnection: Connection | undefined;
}

/**
 * useWiringList hook returns a list of connection rows for the selected design part.
 */
export const useWiringList = () => {
  // Get the selected design part from the context.
  const { selectedDesignPart } = useDesign();

  // Get connection, and a map of connections by source ID to lookup existing connections.
  const { bidirectionalConnections: connections, connectionsBySourceId } = useConnections();

  /**
   * Build the wiring list by merging connections and connection points into a single list. The backend returns the
   * selected design part's connection points in a specific order, and that order should be maintained.
   * @returns {WiringListRow[]} The list of connection rows.
   */
  const buildWiringList = useCallback(() => {
    // Create a list of connection rows.
    const wiringList: WiringListRow[] = [];

    // Maintain server returned order by mapping the connection points to their order
    const connectionPointOrder = new Map();
    selectedDesignPart?.connectionPoints?.forEach((cp, index) => {
      connectionPointOrder.set(cp.id, index);
    });

    // Add connections that have a source ID that matches a connection point ID.
    connections.forEach((c) => {
      if (c.source) {
        const connectionPoint = selectedDesignPart?.connectionPoints?.find((cp) => cp.id === c.source?.id);
        if (connectionPoint) {
          wiringList.push({
            id: `${c.id}:${c.source.id}`,
            connectionPoint,
            existingConnection: c.original,
          });
        }
      }
    });

    // Add connection points that do not have a connection.
    selectedDesignPart?.connectionPoints?.forEach((cp) => {
      const existingConnection = connectionsBySourceId[cp.id];
      if (!existingConnection) {
        wiringList.push({
          id: cp.id,
          connectionPoint: cp,
          existingConnection: undefined,
        });
      }
    });

    // Sort the list by the order of connection points in selectedDesignPart
    wiringList.sort((a, b) => {
      const orderA = connectionPointOrder.get(a.connectionPoint.id) ?? Infinity;
      const orderB = connectionPointOrder.get(b.connectionPoint.id) ?? Infinity;
      return orderA - orderB;
    });

    return wiringList;
  }, [selectedDesignPart, connections, connectionsBySourceId]);

  return { wiringList: buildWiringList() };
};
