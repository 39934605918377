import { FormProvider, useForm } from 'react-hook-form';
import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';
import { Overwrap, Part, PartType } from '@senrasystems/senra-ui';
import { Text } from '@chakra-ui/react';

const OverwrapsDashboard = () => {
  const methods = useForm<Overwrap>({ defaultValues: { type: PartType.OVERWRAP } });
  const columns: CustomColumnDef[] = [
    {
      header: 'Consumable',
      renderFn: (part: Part) => {
        const overwrap = part as Overwrap;
        return <Text>{overwrap.consumable ? 'Yes' : 'No'}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.OVERWRAP} columnDefs={columns} />
    </FormProvider>
  );
};

export default OverwrapsDashboard;
