import OrderComment from '../../../components/comments/OrderComment';
import { apiDeleteOrderComment } from '@web/api/order-comments-api.ts';
import PostOrderCommentForm from '../../../components/comments/PostOrderCommentForm';
import { useCurrentUser } from '@web/queries/users.ts';
import { orderCommentsCacheKey, useOrderComments } from '@web/queries/orders.ts';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  orderId: string;
}

export function OrderComments({ orderId }: Props) {
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();
  const { data: comments = [] } = useOrderComments(orderId);
  const isEmployee = user?.employee ?? false;

  const reloadComments = () => queryClient.invalidateQueries({ queryKey: orderCommentsCacheKey(orderId) });

  const handleDeleteComment = async (commentId: number) => {
    await apiDeleteOrderComment(orderId, commentId);
    await reloadComments();
  };

  if (!user) return <div>Unable to load user comments.</div>;

  return (
    <div>
      <PostOrderCommentForm isEmployee={isEmployee} orderId={orderId} reloadComments={() => reloadComments()} />
      <div>
        {comments.map((data) => (
          <div key={`level0-${data.id}`}>
            <OrderComment
              key={data.id}
              orderId={orderId}
              data={data}
              userId={user.id}
              canReply={true}
              nestingLevel={0}
              isEmployee={isEmployee}
              onDeleteComment={() => handleDeleteComment(data.id)}
              reloadComments={reloadComments}
            />
            {!!data.replies &&
              data.replies.map((reply) => (
                <div key={`level1-${reply.id}`}>
                  <OrderComment
                    key={reply.id}
                    orderId={orderId}
                    data={reply}
                    userId={user.id}
                    canReply={true}
                    nestingLevel={1}
                    isEmployee={isEmployee}
                    onDeleteComment={() => handleDeleteComment(reply.id)}
                    reloadComments={reloadComments}
                  />
                  {!!reply.replies &&
                    reply.replies.map((reply2) => (
                      <OrderComment
                        key={reply2.id}
                        orderId={orderId}
                        data={reply2}
                        userId={user.id}
                        canReply={false} // disallow replies after level 2
                        nestingLevel={2}
                        isEmployee={isEmployee}
                        onDeleteComment={() => handleDeleteComment(reply2.id)}
                        reloadComments={reloadComments}
                      />
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}
