import { Edge, Node } from '@xyflow/react';
import { Connection } from '@senrasystems/senra-ui';
import BreakoutPointNode, { BreakoutPointNodeData } from './components/nodes/BreakoutPointNode.tsx';
import LayoutPointNode, { LayoutPointNodeData } from './components/nodes/LayoutPointNode.tsx';
import ConnectorNode, { ConnectorNodeData } from './components/nodes/ConnectorNode.tsx';
import PassiveNode, { PassiveNodeData } from './components/nodes/PassiveNode.tsx';
import SpliceNode, { SpliceNodeData } from './components/nodes/SpliceNode.tsx';
import SegmentEdge, { SegmentEdgeData } from './components/edges/SegmentEdge.tsx';
import MeasurementEdge, { MeasurementEdgeData } from './components/edges/MeasurementEdge/MeasurementEdge.tsx';
import NoteGroupNode, { NoteGroupNodeData } from './components/nodes/NoteGroupNode.tsx';
import NoteEdge, { NoteEdgeData } from './components/edges/NoteEdge.tsx';

// =======================
// Graph Definitions
// =======================

// Graph (nodes, and edges)
export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

// Define the graph element types
export type GraphElement = Node | Edge | null;

// =======================
// Node Type Definitions
// =======================

// Define an empty array of nodes
export const emptyNodes: Node[] = [];

// Define the node types
export enum NodeType {
  BreakoutPoint = 'BreakoutPoint',
  Connector = 'Connector',
  LayoutPoint = 'LayoutPoint',
  Passive = 'Passive',
  Splice = 'Splice',
  NoteGroup = 'NoteGroup',
}

// These are the types of nodes that are control points
export type ControlPointNodeType = NodeType.BreakoutPoint | NodeType.LayoutPoint;

// These are the types of nodes that are design parts
export type DesignPartNodeType = NodeType.Connector | NodeType.Passive | NodeType.Splice;

// Define the node data types
export type CustomNodeData =
  | BreakoutPointNodeData
  | ConnectorNodeData
  | LayoutPointNodeData
  | NoteGroupNodeData
  | PassiveNodeData
  | SpliceNodeData;

// A utility type to map NodeType to the corresponding CustomNodeData type
export type CustomNodeDataMap = {
  [NodeType.BreakoutPoint]: BreakoutPointNodeData;
  [NodeType.Connector]: ConnectorNodeData;
  [NodeType.LayoutPoint]: LayoutPointNodeData;
  [NodeType.NoteGroup]: NoteGroupNodeData;
  [NodeType.Passive]: PassiveNodeData;
  [NodeType.Splice]: SpliceNodeData;
};

// Register node types with specific component associations
export const nodeTypes = {
  BreakoutPoint: BreakoutPointNode,
  Connector: ConnectorNode,
  LayoutPoint: LayoutPointNode,
  NoteGroup: NoteGroupNode,
  Passive: PassiveNode,
  Splice: SpliceNode,
};

// Define the node type guards
export const isBreakoutPointNode = (node: Node): node is Node<BreakoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint;
};

export const isConnectorNode = (node: Node): node is Node<ConnectorNodeData> => {
  return node.type === NodeType.Connector;
};

export const isLayoutPointNode = (node: Node): node is Node<LayoutPointNodeData> => {
  return node.type === NodeType.LayoutPoint;
};

export const isNoteGroupNode = (node: Node): node is Node<NoteGroupNodeData> => {
  return node.type === NodeType.NoteGroup;
};

export const isPassiveNode = (node: Node): node is Node<PassiveNodeData> => {
  return node.type === NodeType.Passive;
};

export const isSpliceNode = (node: Node): node is Node<SpliceNodeData> => {
  return node.type === NodeType.Splice;
};

// Type guard to determine if a node is a design part node
export const isDesignPartNode = (
  node: Node,
): node is Node<ConnectorNodeData> | Node<PassiveNodeData> | Node<SpliceNodeData> => {
  return node.type === NodeType.Connector || node.type === NodeType.Passive || node.type === NodeType.Splice;
};

// Type guard to determine if a node is a control point node
export const isControlPointNode = (node: Node): node is Node<BreakoutPointNodeData> | Node<LayoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint || node.type === NodeType.LayoutPoint;
};

// Type guard to determine if a node is a measurement node
export const isMeasurableNode = (
  node: Node,
): node is Node<BreakoutPointNodeData | ConnectorNodeData | PassiveNodeData | SpliceNodeData> => {
  return node.type === NodeType.BreakoutPoint || isDesignPartNode(node);
};

// Type guard to determine if a node is a layout node
export const isLayoutNode = (
  node: Node,
): node is
  | Node<ConnectorNodeData>
  | Node<PassiveNodeData>
  | Node<SpliceNodeData>
  | Node<BreakoutPointNodeData>
  | Node<LayoutPointNodeData> => {
  return isDesignPartNode(node) || isControlPointNode(node);
};

// =======================
// Edge Type Definitions
// =======================

// Define an empty array of edges
export const emptyEdges: Edge[] = [];

// Define the edge types
export enum EdgeType {
  Segment = 'Segment',
  Measurement = 'Measurement',
  Note = 'Note',
}

// Define the edge data types
export type CustomEdgeData = MeasurementEdgeData | SegmentEdgeData | NoteEdgeData;

// A utility type to map EdgeType to the corresponding CustomEdgeData type
export type CustomEdgeDataMap = {
  [EdgeType.Measurement]: MeasurementEdgeData;
  [EdgeType.Segment]: SegmentEdgeData;
  [EdgeType.Note]: NoteEdgeData;
};

// Register edge types with specific component associations
export const edgeTypes = {
  Segment: SegmentEdge,
  Measurement: MeasurementEdge,
  Note: NoteEdge,
};

// Define the edge type guards
export const isSegmentEdge = (edge: Edge): edge is Edge<SegmentEdgeData> & { data: SegmentEdgeData } => {
  return edge.type === EdgeType.Segment && edge.data !== undefined;
};

export const isMeasurementEdge = (edge: Edge): edge is Edge<MeasurementEdgeData> & { data: MeasurementEdgeData } => {
  return edge.type === EdgeType.Measurement && edge.data !== undefined;
};

export const isNoteEdge = (edge: Edge): edge is Edge<NoteEdgeData> => {
  return edge.type === EdgeType.Note;
};

// =======================
// Other Definitions
// =======================

// A collection of bundles
export type Bundles = Bundle[];

// A bundle is a collection of connections between two design parts
export type Bundle = {
  id: string;
  sourceDesignPartId: string;
  destinationDesignPartId: string;
  connections: Connection[];
};
