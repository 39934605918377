import { FocusEventHandler, ReactNode, SetStateAction, useMemo } from 'react';
import { Select, Options, SingleValue, SelectInstance } from 'chakra-react-select';
import { debounce } from 'lodash';

interface TypeAheadSelectInputProps {
  options: Options<{ label: string; value: string }>;
  onChange: (value: SingleValue<{ label: string; value: string }>) => void;
  querySetter: React.Dispatch<SetStateAction<string>>;
  value: SingleValue<{ label: string; value: string }>;
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  ref?: React.RefCallback<SelectInstance>;
}

const TypeAheadSelectInput = ({
  options,
  onChange,
  querySetter,
  value,
  noOptionsMessage,
  onBlur,
  placeholder,
  ref,
}: TypeAheadSelectInputProps) => {
  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        querySetter(inputValue);
      }, 500),
    [querySetter],
  );

  const handleInputChange = (inputValue: string) => {
    debouncedSearch(inputValue);
  };

  return (
    <Select
      colorScheme="primary"
      isClearable
      value={value}
      placeholder={placeholder}
      options={options}
      filterOption={null}
      noOptionsMessage={noOptionsMessage}
      onBlur={onBlur}
      onChange={(newValue: unknown) => onChange && onChange(newValue as SingleValue<{ label: string; value: string }>)}
      ref={ref}
      onInputChange={handleInputChange}
    />
  );
};

export default TypeAheadSelectInput;
