import { BaseEdge, Edge, EdgeProps, getSmoothStepPath, Position } from '@xyflow/react';
import { defaultLayoutConfig } from '../../config.ts';

export type NoteEdgeData = {
  sourcePosition: Position;
  targetPosition: Position;
};

export const defaultNoteEdgeData: NoteEdgeData = {
  sourcePosition: Position.Right,
  targetPosition: Position.Bottom,
};

export type NoteEdge = Edge<NoteEdgeData>;

// Define the style for the segment
const segmentStyle = {
  stroke: defaultLayoutConfig.edgeColor,
  strokeWidth: 1,
};

/**
 * Note edge component connecting the flag notes to the indicated element.
 * @constructor
 * @param props
 */
const NoteEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  data: { sourcePosition, targetPosition } = defaultNoteEdgeData,
}: EdgeProps<NoteEdge>) => {
  // Get the edge path for the note edge
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: targetY + 15,
    targetPosition,
  });

  return <BaseEdge path={edgePath} markerEnd={'url(#measurement-arrow)'} style={segmentStyle} />;
};

export default NoteEdge;
