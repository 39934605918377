import {
  ConnectionMap,
  ConnectionPoint,
  isConnectionTarget,
  ResolvedConnectionPoint,
  UUID,
} from '@senrasystems/senra-ui';
import { useMemo } from 'react';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { Option } from '../../../components/SearchableDropdown.tsx';

export interface UseConnectionsData {
  connectionPoints: ResolvedConnectionPoint[];
  connectionsBySourceId: ConnectionMap;
}

/**
 * useDestinationOptions hook returns a list of destination options for a given connection point. Valid destinations are
 * connection points that are connection targets and are not the same as the source connection point. The list is sorted
 * by disabled status.
 * @param connectionPoint
 * @param _currentValue
 * @param injectedData
 */
export const useDestinationOptions = (
  connectionPoint: ConnectionPoint,
  _currentValue?: UUID,
  injectedData?: UseConnectionsData,
) => {
  const { connectionPoints, connectionsBySourceId } = useConnections();

  const data = useMemo(
    () => injectedData || { connectionPoints, connectionsBySourceId },
    [connectionPoints, connectionsBySourceId, injectedData],
  );

  const options = useMemo(
    () =>
      data.connectionPoints
        .filter((cp) => isConnectionTarget(cp.designPart) && cp.id !== connectionPoint.id)
        .map(
          (cp): Option => ({
            label: cp.displayName,
            value: cp.id,
          }),
        )
        .sort((a: Option, b: Option) => (a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1)),
    [connectionPoint.id, data.connectionPoints],
  );

  return { options };
};
