import { Box, Button, ButtonGroup, Flex, Grid, HStack } from '@chakra-ui/react';
import { useWindowManager } from '../hooks/useWindowManager.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import BOM from '../features/BOM';
import { ReactNode, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { AssemblyNavigator } from '@web/apps/Design';
import { LayoutWithNotes } from '../features/Layout';
import { SchematicWithWiringList } from '../features/Schematic';
import { ReactFlowProvider } from '@xyflow/react';
import { useDesignOverview } from '../hooks/useDesignOverview.tsx';
import TopbarNav from '@web/components/navigation/TopbarNav.tsx';

const BORDER_LINE = '2px solid';
const BORDER_COLOR = 'gray.300';

const components: Record<ComponentName, Component> = {
  BOM: { render: () => <BOM />, route: RouteNames.DESIGNS.BOM },
  Layout: {
    render: () => (
      <ReactFlowProvider>
        <LayoutWithNotes />
      </ReactFlowProvider>
    ),
    route: RouteNames.DESIGNS.LAYOUT,
  },
  Schematic: { render: () => <SchematicWithWiringList />, route: RouteNames.DESIGNS.SCHEMATIC },
};

type ComponentName = 'BOM' | 'Layout' | 'Schematic';

interface Component {
  render: () => ReactNode;
  route: string;
}

/**
 * Design component displays the Assembly Navigator on the left, and main content on the right.
 * @constructor
 */
const Design = () => {
  const { id: designId, partNumber } = useDesignOverview();
  const { isWindowOpened } = useWindowManager();
  const [topComponent, setTopComponent] = useState<ComponentName>('BOM');

  const renderTopNavigation = () => {
    return (
      <HStack width="full" justifyContent="center">
        <ButtonGroup h="60px" spacing={10}>
          {(Object.keys(components) as (keyof typeof components)[]).map((key) => (
            <Button
              key={`top-${key}`}
              color={topComponent === key ? 'gray.900' : 'gray.500'}
              onClick={() => setTopComponent(key)}
              variant="unstyled"
              height="54px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {key}
            </Button>
          ))}
        </ButtonGroup>
      </HStack>
    );
  };

  const renderMainContent = () => (
    <Flex direction="column" height="full">
      {renderTopNavigation()}
      {components[topComponent].render()}
    </Flex>
  );

  return (
    <Flex direction="column" overflow="hidden" height="full">
      {/* Header Section */}
      <TopbarNav />
      {/* Content Section */}
      {isWindowOpened(generatePath(RouteNames.DESIGNS.DESIGN_PARTS, { designId })) ? (
        <Box flex="1" overflow="auto">
          {renderMainContent()}
        </Box>
      ) : (
        <Grid templateColumns="1fr 3fr" flex="1" overflow="auto">
          <Box overflow="auto" bg="gray.50" borderRight={BORDER_LINE} borderColor={BORDER_COLOR}>
            <AssemblyNavigator />
          </Box>
          <Box overflow="hidden">{renderMainContent()}</Box>
        </Grid>
      )}
    </Flex>
  );
};

export default Design;
