import { extendTheme } from '@chakra-ui/react';
// Supports weights 100-900
import '@fontsource-variable/inter';
// Supports weights 200-900
import '@fontsource-variable/source-code-pro';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    fonts: {
      heading: `'Inter Variable', sans-serif`,
      body: `'Inter Variable', sans-serif`,
      mono: `'Source Code Pro Variable', monospace`,
      code: `'Source Code Pro Variable', monospace`,
    },
    styles: {
      global: {
        'html, body': {
          fontFamily: `'Inter Variable', sans-serif`,
          fontSize: '12px',
          lineHeight: '1.5rem',
          letterSpacing: '.22px',
        },
        '.pagination-container': {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        },
        '.pagination-item': {
          margin: '0 5px',
          cursor: 'pointer',
          listStyle: 'none',
        },
        '.pagination-item:hover': {
          textDecoration: 'underline',
        },
        '.active-pagination-item': {
          textDecoration: 'underline',
        },
        '.disabled-pagination-item': {
          color: 'gray.500',
        },
        '.disabled-pagination-item:hover': {
          textDecoration: 'none',
        },
      },
    },
    components: {
      Table: {
        variants:{
          senraTable: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
            table:{
              variant: 'simple',
              width: '100%',
            },
            thead: {
              fontweight: '500',
              tr: {
                bg: colorMode === 'light' ? 'gray.100' : 'gray.700',              
              },
            },
            tbody: {
              fontweight: 'normal',
              tr: {
                _hover: {
                  bg: colorMode === 'light' ? 'gray.50' : 'gray.600',
                },
                cursor: 'pointer',
              }
            },
          }),
        }
      }
    }
  },
  { config },
);

export default theme;
