import { useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';
import TextInput from '../../../components/form/TextInput.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

export interface MinMaxAwg {
  gaugeMinAwg?: number;
  gaugeMaxAwg?: number;
}

export const minMaxAwgFormatter = (minMaxAwg: MinMaxAwg) => {
  const thisOrNone = (val: number | undefined) => (val ? val : '');
  return `${thisOrNone(minMaxAwg.gaugeMinAwg)} - ${thisOrNone(minMaxAwg.gaugeMaxAwg)}`;
};

const MinMaxAwgFields = ({ editing }: Props) => {
  const { register, getValues } = useFormContext<MinMaxAwg>();

  return (
    <EditableFormText
      editing={editing}
      label="AWG"
      value={(getValues('gaugeMinAwg')?.toString() ?? '') + '-' + (getValues?.('gaugeMaxAwg')?.toString() ?? '')}
    >
      <FormControl>
        <FormLabel id="AWG">AWG</FormLabel>
        <HStack>
          <Stack spacing={0}>
            <TextInput
              aria-labelledby="AWG"
              formControlProps={{ isDisabled: !editing }}
              placeholder="Min"
              type="number"
              width={75}
              {...register('gaugeMinAwg')}
            />
            <FormHelperText>
              <Text>Min (e.g. 30)</Text>
            </FormHelperText>
          </Stack>

          <Stack spacing={0}>
            <TextInput
              aria-labelledby="AWG"
              formControlProps={{ isDisabled: !editing }}
              placeholder="Max"
              type="number"
              width={75}
              {...register('gaugeMaxAwg')}
            />
            <FormHelperText>
              <Text>Max (e.g. 20)</Text>
            </FormHelperText>
          </Stack>
        </HStack>
      </FormControl>
    </EditableFormText>
  );
};

export default MinMaxAwgFields;
