import { Box, Button, HStack, Spacer, StackProps, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { acceptsContacts, Connection, ConnectionPoint, UUID } from '@senrasystems/senra-ui';
import EditableField from './EditableField.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useConductorOptions } from '../hooks/useConductorOptions.tsx';
import { useDestinationOptions } from '../hooks/useDestinationOptions.tsx';
import { useSourceContactOptions } from '../hooks/useSourceContactOptions.tsx';
import { useDestinationContactOptions } from '../hooks/useDestinationContactOptions.tsx';
import { BiPlus } from 'react-icons/bi';

// Define the field key type
type FieldKey = keyof Fields;

// Fields for a connection
interface Fields {
  destinationId: UUID;
  conductorId: UUID;
  signalName: string;
  sourceContactId: UUID;
  destinationContactId: UUID;
}

// Initial state for connection fields
const INITIAL_STATE: Fields = {
  destinationId: '',
  conductorId: '',
  signalName: '',
  sourceContactId: '',
  destinationContactId: '',
};

// ConnectionItem component props
interface Props extends StackProps {
  id: UUID;
  connectionPoint: ConnectionPoint;
  connection?: Connection;
  isEditing: boolean;
  isAnyConnectionEditing: boolean;
  onEdit?: (id: UUID) => void;
  onClear?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  onAdd?: (id: UUID) => void;
}

/**
 * ConnectionItem component displays a single row of the WiringList feature. A connection is from the perspective of
 * the selected design part's connection point.
 * @param id
 * @param connectionPoint
 * @param existingConnection
 * @param isEditing
 * @param isAnyConnectionEditing
 * @param onEdit
 * @param onClear
 * @param onCancel
 * @param onSave
 * @param onAdd
 * @param rest
 * @constructor
 */
const ConnectionItem = ({
  id,
  connectionPoint,
  connection,
  isEditing,
  isAnyConnectionEditing,
  onEdit,
  onClear,
  onCancel,
  onSave,
  onAdd,
  ...rest
}: Props) => {
  // selectedDesignPart is used to get contacts, and is assumed to be set when this component renders
  const { designId, selectedDesignPart, selectedConnection } = useDesign();

  // State to manage connection data
  const [state, setState] = useState(INITIAL_STATE);

  // Manage initial state so that we can revert to it when canceling
  const [initialState, setInitialState] = useState(INITIAL_STATE);

  // Options for dropdowns
  const { options: destinationOptions } = useDestinationOptions(connectionPoint, connection?.destinationId ?? '');
  const { options: conductorOptions } = useConductorOptions(connection?.conductorId ?? '');
  const { options: sourceContactOptions } = useSourceContactOptions();
  const { options: destinationContactOptions, setDestinationDesignPartId } = useDestinationContactOptions();

  // Hook to manage connections
  const { connectionsBySourceId, createConnection, updateConnection, deleteConnection } = useConnections();

  // Set the current state based on an existing connection
  useEffect(() => {
    if (connection) {
      setState({
        destinationId: connection.destinationId ?? '',
        conductorId: connection.conductorId ?? '',
        signalName: connection.signalName ?? '',
        sourceContactId: connection.sourceContactId ?? '',
        destinationContactId: connection.destinationContactId ?? '',
      });
    } else {
      setState(INITIAL_STATE);
    }
  }, [connection]);

  // Set the source contact automatically if it is the only contact
  useEffect(() => {
    if (!state.sourceContactId && state.conductorId && sourceContactOptions.length === 1) {
      setState((prevState) => ({
        ...prevState,
        sourceContactId: sourceContactOptions[0].value,
      }));
    }
  }, [connectionPoint, sourceContactOptions, state, state.conductorId, state.sourceContactId]);

  // Set the destination design part and destination contact automatically
  useEffect(() => {
    if (state.destinationId) {
      // Set the destination design part
      setDestinationDesignPartId(state.destinationId);
      // Set the destination contact automatically if it is the only contact
      const connection = connectionsBySourceId[connectionPoint.id];
      if (!connection?.destinationContact && state.conductorId && destinationContactOptions.length === 1) {
        setState((prevState) => ({
          ...prevState,
          destinationContactId: destinationContactOptions[0].value,
        }));
      }
    } else {
      setDestinationDesignPartId(null);
    }
  }, [
    connectionPoint.id,
    connectionsBySourceId,
    destinationContactOptions,
    setDestinationDesignPartId,
    state.conductorId,
    state.destinationId,
  ]);

  // Edit the connection
  const handleEdit = () => {
    setInitialState(state);
    onEdit && onEdit(id);
  };

  // Clear the connection
  const handleClear = () => {
    if (connection) {
      deleteConnection.mutate({ designId: designId, connectionId: connection.id });
    }
    onClear && onClear();
  };

  // Cancel the edit
  const handleCancel = () => {
    setState(initialState);
    onCancel && onCancel();
  };

  // Save the connection
  const handleSave = () => {
    // Build the connection data
    const buildConnectionData = () => ({
      sourceId: connectionPoint.id,
      destinationId: state.destinationId,
      conductorId: state.conductorId,
      signalName: state.signalName,
      sourceContactId: state.sourceContactId,
      destinationContactId: state.destinationContactId,
    });

    if (!connection) {
      // Create a new connection
      createConnection.mutate({ designId: designId, data: buildConnectionData() });
    }

    if (connection) {
      // Update an existing connection
      updateConnection.mutate({ designId: designId, connectionId: connection.id, data: buildConnectionData() });
    }

    onSave && onSave();
  };

  // Add a new connection into the same connection point
  const handleAddConnection = () => {
    onAdd && onAdd(id);
  };

  // Handle changes to the connection data
  const handleChange = (field: FieldKey) => (value: string | boolean) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  // Renders the actions for a connection
  const renderActions = () => {
    return (
      <HStack h="30px" w="100px" justifyContent="flex-end" pr={4}>
        {isEditing ? (
          <HStack>
            <Button variant="unstyled" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="unstyled"
              onClick={handleSave}
              isDisabled={!state.destinationId && !state.conductorId}
              isLoading={createConnection.isPending || updateConnection.isPending}
            >
              Save
            </Button>
          </HStack>
        ) : (
          <HStack>
            <Button
              variant="unstyled"
              onClick={handleEdit}
              display={'none'}
              _groupHover={{
                display: !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
              }}
            >
              Edit
            </Button>
            <Button
              variant="unstyled"
              onClick={handleClear}
              isDisabled={connection === undefined}
              isLoading={deleteConnection.isPending}
              display="none"
              _groupHover={{
                display: connection && !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
              }}
            >
              Clear
            </Button>
          </HStack>
        )}
        <Box
          onClick={handleAddConnection}
          display={'none'}
          _groupHover={{
            display: connection && !isEditing && !isAnyConnectionEditing ? 'block' : 'none',
          }}
          cursor="pointer"
        >
          <BiPlus aria-label="Add connection" fontSize="1.25em" />
        </Box>
      </HStack>
    );
  };

  return (
    <HStack
      _groupHover={{ bg: selectedConnection?.id === connection?.id ? 'transparent' : 'gray.50' }}
      onDoubleClick={handleEdit}
      {...rest}
    >
      <Box flex={1} pl={4}>
        <Text flex={1}>{connectionPoint.name}</Text>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
        <EditableField
          isEditing={isEditing}
          value={state.destinationId}
          options={destinationOptions}
          onChange={handleChange('destinationId')}
          type="dropdown"
        />
      </Box>
      <Box flex={1}>
        <EditableField
          isEditing={isEditing}
          value={state.conductorId}
          options={conductorOptions}
          onChange={handleChange('conductorId')}
          type="dropdown"
        />
      </Box>
      <Box flex={1}>
        <EditableField
          isEditing={isEditing}
          value={state.signalName}
          onChange={handleChange('signalName')}
          type="input"
        />
      </Box>
      <Box flex={1}>
        <EditableField
          isEditing={selectedDesignPart && acceptsContacts(selectedDesignPart) ? isEditing : false}
          value={state.sourceContactId}
          options={sourceContactOptions}
          onChange={handleChange('sourceContactId')}
          type="dropdown"
        />
      </Box>
      <Box flex={1}>
        <EditableField
          isEditing={isEditing}
          value={state.destinationContactId}
          options={destinationContactOptions}
          onChange={handleChange('destinationContactId')}
          type="dropdown"
        />
      </Box>
      <Spacer />
      <Box display="flex" justifyContent="flex-end">
        {renderActions()}
      </Box>
    </HStack>
  );
};

export default ConnectionItem;
