import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Contact, PartType } from '@senrasystems/senra-ui';
import IncludedAccessoryTable from './IncludedAccessoryTable.tsx';
import { minMaxAwgFormatter } from './MinMaxAwgFields.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import { HStack, Text } from '@chakra-ui/react';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const IncludedContactsTable = ({ editing }: Props) => {
  return (
    <FormControl>
      <HStack>
        <FormLabel id="includedContactsLabel">
          <Text>Contacts</Text>
        </FormLabel>
      </HStack>
      <IncludedAccessoryTable
        labelId="includedContactsLabel"
        partType={PartType.CONTACT}
        editing={editing}
        customColumns={[
          {
            header: 'Gender',
            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput value={(part as Contact).gender} formControlProps={{ isDisabled: true }} />
              </EditableFormText>
            ),
          },
          {
            header: 'Gauge',

            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput formControlProps={{ isDisabled: true }} value={minMaxAwgFormatter(part as Contact)} />
              </EditableFormText>
            ),
          },
          {
            header: 'Termination',
            renderFn: (part) => (
              <EditableFormText label={null} editing={editing}>
                <TextInput formControlProps={{ isDisabled: true }} value={(part as Contact).termination} />
              </EditableFormText>
            ),
          },
        ]}
      />
    </FormControl>
  );
};

export default IncludedContactsTable;
