import { ControllerRenderProps, useController, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
  Checkbox,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Input,
  VStack,
} from '@chakra-ui/react';
import InsertArrangementDropdown from '../../components/InsertArrangementDropdown.tsx';
import { Connector, IncludedAccessory, PartGender, PartType } from '@senrasystems/senra-ui';
import IncludedContactsTable from '../../components/IncludedContactsTable.tsx';
import IncludedBackshellTable from '../../components/IncludedBackshellTable.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import { useConfiguration } from '../../../../queries/admin.ts';
import Loading from '@web/components/Loading.tsx';
interface Props {
  editing: boolean;
  isCreateMode?: boolean;
}

const ConnectorFields = ({ editing, isCreateMode = false, ...rest }: Props) => {
  const { control, getValues, register, setValue, watch } = useFormContext<Connector>();
  const { field: shielded } = useController({ name: 'shielded', control });
  const { field: acceptsContacts } = useController({ name: 'acceptsContacts', control });
  const { field: acceptsBackshell } = useController({ name: 'acceptsBackshell', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const accessoriesOfType = (partType: PartType) => {
    return getValues('includedAccessories').filter((ia: IncludedAccessory) => {
      return ia.type === partType;
    });
  };

  const removeAccessoriesOfType = (partType: PartType) => {
    setValue(
      'includedAccessories',
      (getValues('includedAccessories') ?? [])
        .filter((ia: IncludedAccessory) => ia.type !== partType || ia.id !== undefined)
        .map((ia: IncludedAccessory) => {
          if (ia.type === partType) ia._destroy = true;
          return ia;
        }),
    );
  };

  const handleSwitchChange = (field: ControllerRenderProps<Connector>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field === acceptsContacts && !e.target.checked) removeAccessoriesOfType(PartType.CONTACT);
    else if (field === acceptsBackshell && !e.target.checked) removeAccessoriesOfType(PartType.BACKSHELL);

    field.onChange(e.target.checked);
  };

  return (
    <Stack spacing={6}>
      <HStack spacing={10}>
        <Box flex="1">
          <EditableFormText editing={editing} label="Gender" value={getValues('gender')}>
            <FormControl flex={1}>
              <FormLabel>Gender</FormLabel>
              <Select isDisabled={!editing} {...register('gender')}>
                {Object.values(PartGender).map((val) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <InsertArrangementDropdown editing={editing} />
        </Box>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Termination" value={getValues('termination')}>
            <FormControl flex={1}>
              <FormLabel>Termination</FormLabel>
              <Select isDisabled={!editing} {...register('termination')}>
                {config.parts.connector.terminations.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      <HStack spacing={10}>
        <VStack spacing={4} flex={1} alignSelf="flex-start">
          <FormControl>
            <Checkbox
              isDisabled={!editing}
              isChecked={shielded.value}
              onChange={handleSwitchChange(shielded)}
              aria-label="Shielded"
              defaultChecked={isCreateMode}
            >
              Shielded
            </Checkbox>
          </FormControl>
          <FormControl>
            <Checkbox
              isDisabled={!editing}
              isChecked={acceptsBackshell.value}
              onChange={handleSwitchChange(acceptsBackshell)}
              aria-label="Accepts backshell"
              defaultChecked={isCreateMode}
            >
              Accepts Backshell
            </Checkbox>
          </FormControl>
        </VStack>
        <Box flex={1} alignSelf="flex-start">
          <FormControl>
            <Checkbox
              isDisabled={!editing}
              isChecked={acceptsContacts.value}
              onChange={handleSwitchChange(acceptsContacts)}
              aria-label="Accepts contacts"
              defaultChecked={isCreateMode}
            >
              Accepts Contacts
            </Checkbox>
          </FormControl>
        </Box>
        <Box flex={1} alignSelf="flex-start">
          <EditableFormText editing={editing} label="Torque Value" value={getValues('torqueValue')}>
            <FormControl flex={1}>
              <FormLabel>Torque Value</FormLabel>
              <Input isDisabled={!editing} {...register('torqueValue')} placeholder="ft/lbs" />
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      {(editing
        ? true
        : accessoriesOfType(PartType.BACKSHELL).length > 0 || accessoriesOfType(PartType.CONTACT).length > 0) && (
        <Accordion allowToggle={true}>
          <AccordionItem borderTop="1px solid" borderColor={'gray.200'} {...rest}>
            <AccordionButton _hover={{ bg: 'gray.50' }}>
              <Box as="span" flex="1" textAlign="left">
                <Text fontSize={'lg'}>Included Accessories</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {watch('acceptsContacts', true) && (editing || accessoriesOfType(PartType.CONTACT).length > 0) && (
                <Box w="45vw">
                  <IncludedContactsTable editing={editing} />
                </Box>
              )}
              <Box height="40px" />
              {watch('acceptsBackshell', true) && (editing || accessoriesOfType(PartType.BACKSHELL).length > 0) && (
                <Box w="25vw">
                  <IncludedBackshellTable editing={editing} />
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Stack>
  );
};

export default ConnectorFields;
