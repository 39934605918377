import { produce } from 'immer';
import { Node, XYPosition } from '@xyflow/react';
import { DesignPart, PartType, UUID } from '@senrasystems/senra-ui';
import { CustomNodeData, CustomNodeDataMap, NodeType } from '../types';
import { defaultConnectorNodeData } from '../components/nodes/ConnectorNode.tsx';
import { defaultBreakoutPointNodeData } from '../components/nodes/BreakoutPointNode.tsx';
import { defaultLayoutPointNodeData } from '../components/nodes/LayoutPointNode.tsx';
import { defaultPassiveNodeData } from '../components/nodes/PassiveNode.tsx';
import { defaultSpliceNodeData } from '../components/nodes/SpliceNode.tsx';
import { defaultNoteGroupNodeData } from '../components/nodes/NoteGroupNode.tsx';

/**
 * Factory class for creating nodes for the graph.
 */
class NodeFactory {
  /**
   * Creates a node with the given parameters.
   * @param nodeId - The ID of the node to create
   * @param nodeType - The type of the node (e.g., 'Connector', 'BreakoutPoint', etc.)
   * @param defaultData - The default data for the node type
   * @param position - The position of the node
   * @param nodeProps - Additional properties for the node (specific to CustomNodeData)
   */
  private static createNode(
    nodeId: string,
    nodeType: NodeType | PartType,
    defaultData: CustomNodeData,
    position: XYPosition,
    nodeProps?: Partial<CustomNodeData>,
  ): Node {
    // Use Immer to create a deep copy of nodeProps
    const clonedNodeProps = produce(nodeProps, (draft) => draft || {});

    return {
      id: nodeId,
      type: nodeType,
      data: { ...defaultData, ...clonedNodeProps },
      position,
    };
  }

  /**
   * Creates a design part node.
   * @param part - The design part to create a node for
   * @param position - The position of the node
   * @param nodeProps - Additional node properties (specific to CustomNodeData)
   */
  static createDesignPart(part: DesignPart, position: XYPosition, nodeProps?: Partial<CustomNodeData>): Node {
    const defaultData = NodeFactory.createNodeData(part);
    return NodeFactory.createNode(part.id, part.partData.type as PartType, defaultData, position, nodeProps);
  }

  /**
   * Creates a breakout point node.
   * @param position - The position of the node
   * @param nodeProps - Additional node properties (specific to CustomNodeData)
   */
  static createBreakoutPoint(position: XYPosition, nodeProps?: Partial<CustomNodeData>): Node {
    const id = window.crypto.randomUUID();
    return NodeFactory.createNode(id, NodeType.BreakoutPoint, defaultBreakoutPointNodeData, position, nodeProps);
  }

  /**
   * Creates a layout point node.
   * @param position - The position of the node
   * @param nodeProps - Additional node properties (specific to CustomNodeData)
   */
  static createLayoutPoint(position: XYPosition, nodeProps?: Partial<CustomNodeData>): Node {
    const id = window.crypto.randomUUID();
    return NodeFactory.createNode(id, NodeType.LayoutPoint, defaultLayoutPointNodeData, position, nodeProps);
  }

  /**
   * Creates a note group node.
   * @param position - The position of the node
   * @param nodeProps - Additional node properties (specific to CustomNodeData)
   */
  static createNoteGroup(position: XYPosition, nodeProps?: Partial<CustomNodeData>): Node {
    const id = window.crypto.randomUUID();
    return NodeFactory.createNode(id, NodeType.NoteGroup, defaultNoteGroupNodeData, position, nodeProps);
  }

  /**
   * Create node data for a given part.
   * @param part
   * @private
   */
  private static createNodeData(part: DesignPart): CustomNodeData {
    switch (part.partData.type) {
      case PartType.CONNECTOR:
        return {
          ...defaultConnectorNodeData,
          designPartId: part.id,
        };
      case PartType.PASSIVE:
        return {
          ...defaultPassiveNodeData,
          designPartId: part.id,
        };
      case PartType.SPLICE:
        return {
          ...defaultSpliceNodeData,
          designPartId: part.id,
        };
      default:
        throw new Error(`Unsupported part type: ${part.partData.type}`);
    }
  }
}

/**
 * Wrapper function to check if a node exists, add it to the nodes array if it doesn't, and return its ID.
 * @param nodesArray - The current state of nodes in the graph
 * @param newNode - The node to be added if it doesn't already exist
 * @returns The new node if it was added, or undefined if it already exists
 */
const addNodeIfNotExists = (nodesArray: Node[], newNode: Node): Node | undefined => {
  const nodeExists = nodesArray.some((node) => node.id === newNode.id);
  if (!nodeExists) {
    nodesArray.push(newNode);
    return newNode;
  } else {
    // eslint-disable-next-line no-console
    console.debug(`Node with id ${newNode.id} already exists.`);
    return undefined;
  }
};

/**
 * Removes a node from the nodes array if it exists.
 * @param nodesArray - The array of nodes.
 * @param nodeId - The ID of the node to remove.
 */
export const removeNodeIfExists = (nodesArray: Node[], nodeId: string): void => {
  const index = nodesArray.findIndex((node) => node.id === nodeId);
  if (index > -1) {
    nodesArray.splice(index, 1);
  }
};

/**
 * Wrapper function to create and add a design part node if it doesn't already exist, and return its ID.
 * @returns The new node if it was added, or undefined if it already exists
 */
export const createAndAddDesignPartNode = (
  nodesArray: Node[],
  part: DesignPart,
  position: XYPosition,
  nodeProps?: Partial<CustomNodeData>,
): Node | undefined => {
  const newNode = NodeFactory.createDesignPart(part, position, nodeProps);
  return addNodeIfNotExists(nodesArray, newNode);
};

/**
 * Wrapper function to create and add a breakout point node if it doesn't already exist, and return its ID.
 * @param nodesArray - The current state of nodes in the graph
 * @param position - The position of the node
 * @param nodeProps - Additional properties for the node (specific to CustomNodeData)
 * @returns The new node
 */
export const createAndAddBreakoutPointNode = (
  nodesArray: Node[],
  position: XYPosition,
  nodeProps?: Partial<CustomNodeData>,
): Node => {
  const newNode = NodeFactory.createBreakoutPoint(position, nodeProps);
  nodesArray.push(newNode);

  return newNode;
};

/**
 * Wrapper function to create and add a layout point node if it doesn't already exist, and return its ID.
 * @param nodesArray - The current state of nodes in the graph
 * @param position - The position of the node
 * @param nodeProps - Additional properties for the node (specific to CustomNodeData)
 * @returns The new node
 */
export const createAndAddLayoutPointNode = (
  nodesArray: Node[],
  position: XYPosition,
  nodeProps?: Partial<CustomNodeData>,
): Node => {
  const newNode = NodeFactory.createLayoutPoint(position, nodeProps);
  nodesArray.push(newNode);

  return newNode;
};

/**
 * Wrapper function to create and add a layout point node if it doesn't already exist, and return its ID.
 * @param nodesArray - The current state of nodes in the graph
 * @param position - The position of the node
 * @param nodeProps - Additional properties for the node (specific to CustomNodeData)
 * @returns The new node
 */
export const createAndAddNoteGroupNode = (
  nodesArray: Node[],
  position: XYPosition,
  nodeProps?: Partial<CustomNodeData>,
): Node => {
  const newNode = NodeFactory.createNoteGroup(position, nodeProps);
  nodesArray.push(newNode);

  return newNode;
};

/**
 * Updates a specific property or the entire data object for a single node.
 * Supports updating with a partial data object.
 * @param nodes - The array of nodes to update.
 * @param nodeId - The ID of the node to update.
 * @param newData - The new data to set for the node. Can be a specific property, the entire data object, or a partial data object.
 */
export const updateNodeData = <
  T extends keyof CustomNodeData,
  U extends Partial<CustomNodeData> = Partial<CustomNodeData>,
>(
  nodes: Node[],
  nodeId: string,
  newData: U | { property: T; value: U[T] },
): Node[] => {
  return produce(nodes, (draft) => {
    const node = draft.find((n) => n.id === nodeId);

    if (node?.data) {
      if ('property' in newData) {
        // Update a specific property
        (node.data as U)[newData.property] = newData.value;
      } else {
        // Merge with existing data if it's a partial object
        node.data = {
          ...node.data,
          ...newData,
        };
      }
    }
  }) as Node[];
};

/**
 * Finds a node by its ID in the nodes array.
 * @param nodes - The array of nodes to search.
 * @param nodeId - The ID of the node to find.
 * @param additionalCheck - Optional. A function that returns a boolean indicating whether the node is valid.
 * @returns The node if found, or undefined if not found.
 */
export const findNodeById = (
  nodes: Node[],
  nodeId: UUID,
  additionalCheck?: (node: Node) => boolean,
): Node | undefined => {
  const node = nodes.find((n) => n.id === nodeId && (!additionalCheck || additionalCheck(n)));
  if (!node) {
    // eslint-disable-next-line no-console
    console.warn(`Node with id ${nodeId} not found.`);
  }
  return node;
};

/**
 * Updates all nodes of a certain type, either by replacing the entire data object or by updating a specific property.
 * Supports updating with a partial data object.
 * @param nodes - The array of nodes to update.
 * @param nodeType - The type of nodes to target for the update.
 * @param newData - The new data to set, either as an object or as a specific property and its value.
 * @param condition - Optional. A function that returns a boolean indicating whether the update should be applied to a node.
 * @returns The updated array of nodes.
 */
export const updateNodesOfType = <
  T extends NodeType,
  U extends Partial<CustomNodeDataMap[T]> = Partial<CustomNodeDataMap[T]>,
>(
  nodes: Node[],
  nodeType: T,
  newData: U | { property: keyof U; value: U[keyof U] },
  condition?: (node: Node<CustomNodeDataMap[T]>) => boolean,
): Node[] => {
  return produce(nodes, (draft) => {
    draft.forEach((node) => {
      if (node.type === nodeType && node.data && (!condition || condition(node as Node<CustomNodeDataMap[T]>))) {
        if ('property' in newData) {
          // Update a specific property
          (node.data as U)[newData.property] = newData.value;
        } else {
          // Merge with existing data if it's a partial object
          node.data = {
            ...node.data,
            ...newData,
          };
        }
      }
    });
  }) as Node[];
};

/**
 * Validates the node type. If the node type is not supported, logs an error and returns false.
 * @param nodeType - The node type to validate.
 * @param validNodeTypes - An array of valid node types.
 * @returns A boolean indicating whether the node type is valid.
 */
export const isValidateNodeType = (nodeType: NodeType, validNodeTypes: NodeType[]): boolean => {
  if (!validNodeTypes.includes(nodeType as NodeType)) {
    // eslint-disable-next-line no-console
    console.warn(`Unsupported node type: ${nodeType}.`);
    return false;
  }
  return true;
};
