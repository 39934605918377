import { SpliceNode } from '../../components/nodes/SpliceNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useFlagNoteActions } from './useFlagNoteActions.tsx';
import { useActionsBase } from './useActionsBase.tsx';

/**
 * Hook for splice actions.
 */
export const useSpliceActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const splice = element as SpliceNode;

  const { flagNoteActions } = useFlagNoteActions(splice?.id);

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Splice,
  } as MenuActions;
};
