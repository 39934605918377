// useConductorOptions.ts
import { useMemo } from 'react';
import { ConnectionMap, isConductor, ResolvedConnectionPoint, UUID } from '@senrasystems/senra-ui';
import { Option } from '../../../components/SearchableDropdown.tsx';
import { useConnections } from '../../../hooks/useConnections.tsx';

export interface UseConnectionsData {
  connectionPoints: ResolvedConnectionPoint[];
  connectionsByConductorId: ConnectionMap;
}

/**
 * useConductorOptions hook returns a list of conductor options for a given connection point. Valid conductor options
 * are connection points that are conductor parts. The list is sorted by disabled status.
 * @param currentValue
 * @param injectedData
 */
export const useConductorOptions = (currentValue?: UUID, injectedData?: UseConnectionsData) => {
  const { connectionPoints, connectionsByConductorId } = useConnections();

  const data = useMemo(
    () => injectedData || { connectionPoints, connectionsByConductorId },
    [connectionPoints, connectionsByConductorId, injectedData],
  );

  const options = useMemo(
    () =>
      data.connectionPoints
        .filter((cp) => isConductor(cp.designPart.partData.type))
        .map(
          (cp): Option => ({
            label: cp.displayName,
            value: cp.id,
            isDisabled: cp.id !== currentValue && data.connectionsByConductorId[cp.id] !== undefined,
          }),
        )
        .sort((a: Option, b: Option) => (a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1)),
    [data, currentValue],
  );

  return { options };
};
