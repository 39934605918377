import { Badge as ChakraBadge } from '@chakra-ui/react';

const Badge = ({ children, color }: { children: string; color?: string }) => {
  return (
    <ChakraBadge colorScheme={color} borderRadius="md" py={1} px={2} textTransform="none">
      {children}
    </ChakraBadge>
  );
};

export default Badge;
