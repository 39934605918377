import { useRouteError } from 'react-router-dom';
import Hero from '../Hero.tsx';
import { Box, ChakraProvider, Heading, Text } from '@chakra-ui/react';
import defaultTheme from '../../apps/themes.ts';

const ErrorPage = () => {
  const error = useRouteError() as { statusText?: string; status?: string; message?: string };

  let text = '';
  if (error.hasOwnProperty('status') && error.status) text += error.status + ' ';
  if (error.hasOwnProperty('statusText')) text += error.statusText;
  else if (error.hasOwnProperty('message')) text += error.message;

  return (
    <ChakraProvider theme={defaultTheme}>
      <Hero>
        <Box textAlign="center">
          <Heading as="h2" fontSize="5xl">
            <Text as="span" fontWeight="normal">
              Houston,{' '}
            </Text>{' '}
            <Text as="span" color="primary" fontWeight="bold">
              we have a problem.
            </Text>
          </Heading>
          <Text py={5} color="neutral.100">
            An unexpected error has occurred:
          </Text>
          <Text fontStyle="italic" color="neutral.400">
            {text}
          </Text>
        </Box>
      </Hero>
    </ChakraProvider>
  );
};

export default ErrorPage;
