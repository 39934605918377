import { useFormContext } from 'react-hook-form';
import { Box, IconButton, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';
import { AttachmentIcon, ChevronDownIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { errorToast, successToast } from '@web/common/toasts.ts';
import { getDocumentObjectURL } from '@web/common/util.ts';
import { apiDeleteDocument } from '@web/api/admin-api.ts';
import S3FileInput from '../../../components/form/S3FileInput.tsx';
import { DocumentCategory, Part } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing?: boolean;
  index: number;
  remove: (index: number) => void;
}

const PartDocumentDisplay = ({ editing = true, index, remove }: Props) => {
  const { control, register, getValues } = useFormContext<Part>();
  const handleDownloadDocument = () => {
    const document = getValues(`documents.${index}`);
    return getDocumentObjectURL(document)
      .then((fileBlob) => window.open(fileBlob, '_blank'))
      .catch((err) => errorToast(`Error downloading file: ${err.message}`));
  };

  const handleDeleteDocument = async () => {
    const document = getValues(`documents.${index}`);
    if (document.id) {
      await apiDeleteDocument(document.id);
    }
    remove(index);
    successToast('Document successfully deleted.');
  };

  return (
    <InputGroup>
      <Box display="flex" alignItems="center">
        <EditableFormText value={null} label={getValues(`documents.${index}.type`) + '\t'} editing={editing}>
          <InputLeftAddon>
            <Select
              isDisabled={!editing}
              icon={<ChevronDownIcon display={editing ? 'initial' : 'none'} />}
              variant="unstyled"
              {...register(`documents.${index}.type`)}
            >
              {Object.entries(DocumentCategory).map(([key, val]) => {
                return (
                  <option key={key} value={key}>
                    {val}
                  </option>
                );
              })}
            </Select>
          </InputLeftAddon>
        </EditableFormText>
      </Box>
      <Box ml={1} display="flex" alignItems="center">
        <S3FileInput
          control={control}
          name={`documents.${index}`}
          button={
            editing ? <IconButton isDisabled={!editing} aria-label="Add Document" icon={<AttachmentIcon />} /> : <Box />
          }
          multiple={false}
          direction="row"
          showDeleteButton={false}
          singleFile={true}
        />
      </Box>
      {editing && (
        <IconButton aria-label="Delete Document" icon={<DeleteIcon color="red.300" />} onClick={handleDeleteDocument} />
      )}
      {!editing && (
        <IconButton aria-label="Download Document" icon={<ExternalLinkIcon />} onClick={handleDownloadDocument} />
      )}
    </InputGroup>
  );
};

export default PartDocumentDisplay;
