import { Operations } from '../Operations.ts';
import { Graph, isMeasurementEdge } from '../../types.ts';
import { Edge, Node } from '@xyflow/react';
import { MeasurementEdgeData } from '../../components/edges/MeasurementEdge/MeasurementEdge.tsx';
import { createAndAddMeasurementEdge } from '../EdgeFactory.ts';
import { shouldCreateMeasurementEdge } from '../../utils/graph.ts';

// Operation to refresh measurements
export type RebuildMeasurementsOperation = {
  type: 'RebuildMeasurements';
  params: Record<string, never>;
};

/**
 * Create measurement edges for connected nodes. Measurement edges are created between two measurement nodes that are
 * directly connected or connected through a path of layout points.
 */
export class RebuildMeasurements implements Operations<RebuildMeasurementsOperation> {
  // Execute the operation
  execute(graph: Graph, _operation: RebuildMeasurementsOperation): Graph {
    const { nodes, edges } = graph;

    // Step 1: Extract and preserve data from existing measurement edges
    const measurementEdgeDataMap = this.extractMeasurementEdgeData(edges);

    // Step 2: Remove existing measurement edges
    const nonMeasurableEdges = edges.filter((edge) => !isMeasurementEdge(edge));

    // Step 2: Recreate measurement edges
    const newEdges = this.recreateMeasurementEdges(nodes, edges, measurementEdgeDataMap);

    // Return the nodes and the combined list of non-measurement edges and new measurement edges
    return { nodes, edges: [...nonMeasurableEdges, ...newEdges] };
  }

  /**
   * Extracts measurement edge data from the existing edges.
   * @param edges
   * @private
   */
  private extractMeasurementEdgeData(edges: Edge[]): Map<string, MeasurementEdgeData> {
    const measurementEdgeDataMap = new Map<string, MeasurementEdgeData>();

    edges.forEach((edge) => {
      if (isMeasurementEdge(edge) && edge.data) {
        const key = `${edge.source}:${edge.target}`;
        measurementEdgeDataMap.set(key, { ...edge.data });
        const reverseKey = `${edge.target}:${edge.source}`;
        measurementEdgeDataMap.set(reverseKey, { ...edge.data });
      }
    });

    return measurementEdgeDataMap;
  }

  /**
   * Recreates measurement edges between connected nodes.
   * @param nodes
   * @param edges
   * @param measurementEdgeDataMap
   * @private
   */
  private recreateMeasurementEdges(
    nodes: Node[],
    edges: Edge[],
    measurementEdgeDataMap: Map<string, MeasurementEdgeData>,
  ): Edge[] {
    const newEdges: Edge[] = [];
    const addedConnections = new Set<string>();

    nodes.forEach((node1, i) => {
      for (let j = i + 1; j < nodes.length; j++) {
        const node2 = nodes[j];
        const connectionKey = `${node1.id}:${node2.id}`;
        const reverseConnectionKey = `${node2.id}:${node1.id}`;

        if (shouldCreateMeasurementEdge(nodes, edges, node1, node2)) {
          const data = measurementEdgeDataMap.get(connectionKey);
          const newEdge = createAndAddMeasurementEdge(newEdges, node1.id, node2.id, data);
          if (newEdge) {
            addedConnections.add(connectionKey);
            addedConnections.add(reverseConnectionKey);
          }
        }
      }
    });

    return newEdges;
  }
}
