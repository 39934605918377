import { ReactNode } from 'react';
import { Td } from '@chakra-ui/react';

interface Props {
  children?: ReactNode;
}

const PositionTd = ({ children }: Props) => (
  <Td w={40} borderRightWidth={1} borderRightColor="gray.200" borderBottom="unset" p={0}>
    {children}
  </Td>
);

export default PositionTd;
