import { doDownload, doRequest, getUrl } from '../common/api';
import { CreateOrderDto } from '../common/lib';

export async function apiCreateOrder(order: CreateOrderDto) {
  return doRequest('POST', getUrl(`/api/v1/orders`), JSON.stringify({ order }));
}

export async function apiUpdateOrder(orderId: string, order) {
  return doRequest('PUT', getUrl(`/api/v1/orders/${orderId}`), JSON.stringify({ order }));
}

export async function apiGetOrders(page: string, { query }: { query?: string | null }, state?: string) {
  let endpoint = `/api/v1/orders?page=${page}?per=25`;
  if (query) {
    endpoint += `&q=${query}`;
  }
  if (state) {
    endpoint += `&t=${state}`;
  }
  return doRequest('GET', getUrl(endpoint));
}

export async function apiGetOrderById(orderId: string) {
  return doRequest('GET', getUrl(`/api/v1/orders/${orderId}`));
}

export async function downloadLatestQuote(documentId: string, orderNumber: string) {
  return doDownload(getUrl(`/api/v1/documents/${documentId}/download`), `${orderNumber}-quote.pdf`);
}

export async function downloadLatestPurchaseOrder(documentId: string, orderNumber: string) {
  return doDownload(getUrl(`/api/v1/documents/${documentId}/download`), `${orderNumber}-purchase-order.pdf`);
}

export async function apiReorder(orderId: string) {
  return doRequest('POST', getUrl(`/api/v1/orders/${orderId}/clone`));
}

export async function apiArchiveOrder(orderId: string) {
  return doRequest('DELETE', getUrl(`/api/v1/orders/${orderId}`));
}

export async function apiGetSignedS3Url(file: File) {
  return doRequest(
    'GET',
    getUrl(`/api/v1/s3/sign?object_name=${file.name}&content_type=${encodeURIComponent(file.type)}`),
  );
}

export async function apiUploadPurchaseOrder(payload: {
  documentableId: string;
  documentableType: string;
  s3Key: string;
  type: string;
  purchaseOrderNumber: string;
  purchaseOrderAmount: string;
}) {
  return doRequest('POST', getUrl(`/api/v1/documents`), JSON.stringify(payload));
}
