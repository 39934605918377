import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useDesign } from '../../hooks/useDesign.tsx';
import { isConnectionTarget } from '@senrasystems/senra-ui';
import WiringList from '../WiringList/index.tsx';
import { ContextPanelWithTabs } from '../../components/ContextPanel';
import Schematic from './Schematic.tsx';

const tabs = [{ value: 'wiring', headerLabel: 'Wiring List', content: <WiringList /> }];

/**
 * Schematic component displays a schematic view of the selected design part's connections.
 * @constructor
 */
const SchematicWithWiringList = () => {
  // Get the selected design part
  const { selectedDesignPart } = useDesign();

  // Determine whether to open or close the context menu
  const {
    isOpen: isWiringListOpen,
    onToggle: onToggleWiringList,
    onOpen: onWiringListOpen,
    onClose: onWiringListClose,
  } = useDisclosure();

  useEffect(() => {
    if (selectedDesignPart && isConnectionTarget(selectedDesignPart)) {
      onWiringListOpen();
    } else {
      onWiringListClose();
    }
  }, [selectedDesignPart, onWiringListOpen, onWiringListClose]);

  return (
    <>
      <Schematic />
      <ContextPanelWithTabs
        isOpen={isWiringListOpen}
        togglePanel={onToggleWiringList}
        openPanel={onWiringListOpen}
        tabs={tabs}
      />
    </>
  );
};

export default SchematicWithWiringList;
