import { Edge, Node, XYPosition } from '@xyflow/react';
import { Operations } from '../Operations.ts';
import { Graph, isSegmentEdge, NodeType } from '../../types.ts';
import {
  createAndAddBreakoutPointNode,
  createAndAddLayoutPointNode,
  isValidateNodeType,
} from '../../graph/NodeFactory.ts';
import {
  createAndAddMeasurementEdge,
  createAndAddSegmentEdge,
  findEdgeById,
  removeEdgeIfExists,
} from '../../graph/EdgeFactory.ts';
import { findMeasurementEdge, getMidpoint } from '../../utils/graph.ts';
import { UUID } from '@senrasystems/senra-ui';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge.tsx';

// Operation to add a control point
export type AddControlPointOperation = {
  type: 'AddControlPoint';
  params: {
    nodeType: NodeType.BreakoutPoint | NodeType.LayoutPoint;
    edgeId: UUID;
  };
};

/**
 * Creates a new control point node and splits the edge into two segments.
 */
export class AddControlPoint implements Operations<AddControlPointOperation> {
  // Rebuild the graph after the operation
  requiresRebuild = true;

  // Execute the operation
  execute(graph: Graph, operation: AddControlPointOperation): Graph {
    const { nodes, edges } = graph;
    const { nodeType, edgeId } = operation.params;

    // Validate parameters
    if (
      !isValidateNodeType(nodeType, [NodeType.BreakoutPoint, NodeType.LayoutPoint]) ||
      !findEdgeById(edges, edgeId, isSegmentEdge)
    ) {
      // eslint-disable-next-line no-console
      console.warn('Invalid parameters for AddControlPoint operation.', nodeType, edgeId);
      return graph;
    }

    // Find the edge to split
    const edge = findEdgeById(edges, edgeId);
    if (!edge || !isSegmentEdge(edge)) {
      // eslint-disable-next-line no-console
      console.warn(`Edge not found for id ${edgeId}.`);
      return graph;
    }

    // Determine the position of the new control point on the segment
    const { x, y } = getMidpoint(nodes, edge);

    // Create the new node
    const newNode = this.createControlPointNode(nodes, nodeType, { x, y });
    if (!newNode) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to create a new ${nodeType} node.`);
      return graph;
    }

    // Split the edge into two segments
    this.splitEdge(edges, edge, newNode.id);

    // If the control point is a breakout point, split the measurement edge
    if (nodeType === NodeType.BreakoutPoint) {
      this.splitMeasurementEdge(edges, edge, newNode.id);
    }

    // Return the updated graph state
    return { nodes, edges };
  }

  /**
   * Creates a new control point node.
   * @param nodes
   * @param nodeType
   * @param position
   */
  private createControlPointNode = (nodes: Node[], nodeType: NodeType, position: XYPosition): Node | undefined => {
    const { x, y } = position;

    let newNode: Node | undefined;

    if (nodeType === NodeType.BreakoutPoint) {
      newNode = createAndAddBreakoutPointNode(nodes, { x, y });
    }

    if (nodeType === NodeType.LayoutPoint) {
      newNode = createAndAddLayoutPointNode(nodes, { x, y });
    }

    return newNode;
  };

  /**
   * Splits the edge into two segments.
   * @param edges
   * @param edge
   * @param newNodeId
   */
  private splitEdge = (edges: Edge[], edge: Edge<SegmentEdgeData>, newNodeId: UUID): void => {
    // Remove the original edge
    removeEdgeIfExists(edges, edge.id);

    // Create the first edge (source -> newNode)
    createAndAddSegmentEdge(edges, edge.source, newNodeId, edge.data);

    // Create the second edge (newNode -> target)
    createAndAddSegmentEdge(edges, newNodeId, edge.target, edge.data);
  };

  /**
   * Splits the measurement edge into two segments. The measurement is divided by 2.
   * @param edges
   * @param edge
   * @param newNodeId
   */
  private splitMeasurementEdge = (edges: Edge[], edge: Edge, newNodeId: UUID): void => {
    // Find the measurement edge for the original edge
    const measurementEdge = findMeasurementEdge(edges, edge.source, edge.target);
    if (!measurementEdge) {
      // eslint-disable-next-line no-console
      console.warn(`Measurement edge not found for edge ${edge.id}.`);
      return;
    }

    // Divide the measurement by 2
    const halfMeasurement = (measurementEdge.data?.measurement || 0) / 2;

    // Remove the original measurement edge
    removeEdgeIfExists(edges, measurementEdge.id);

    // Create the first measurement edge (source -> newNode)
    createAndAddMeasurementEdge(edges, edge.source, newNodeId, {
      ...measurementEdge.data,
      measurement: halfMeasurement,
    });

    // Create the second measurement edge (newNode -> target)
    createAndAddMeasurementEdge(edges, newNodeId, edge.target, {
      ...measurementEdge.data,
      measurement: halfMeasurement,
    });
  };
}
