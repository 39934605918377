import { useMatch } from 'react-router';
import { RouteNames } from '@web/consts/routeNames.ts';
import { generatePath } from 'react-router-dom';
import { Box, chakra, ChakraProps, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { TfiLayout, TfiNewWindow } from 'react-icons/tfi';
import { useWindowManager } from '../hooks/useWindowManager.tsx';
import { useDesignId } from '../hooks/useDesignId.tsx';
import { ReactNode, useState } from 'react';

const OpenWindowIcon = chakra(TfiNewWindow, {
  baseStyle: {
    ariaLabel: 'Open window',
    role: 'button',
    cursor: 'pointer',
    fontSize: '1.25em',
  },
});

const CloseWindowIcon = chakra(TfiLayout, {
  baseStyle: {
    ariaLabel: 'Close window',
    role: 'button',
    cursor: 'pointer',
    fontSize: '1.25em',
  },
});

interface Props extends ChakraProps {
  title: string;
  routeName: string;
  windowFeatures?: string;
  showTitle?: boolean;
  actions?: ReactNode;
  children?: ReactNode | ((props: { editMode: boolean; renderWindowIcon?: () => ReactNode }) => ReactNode);
}

/**
 * Wraps a component with a Title, and a button in the top right that will popout the component in a new window.
 * @param title
 * @param routeName
 * @param windowFeatures
 * @param showTitle
 * @param actions
 * @param children
 * @param rest
 * @constructor
 */
const ManagedWindow = ({ title, routeName, windowFeatures, showTitle = true, actions, children, ...rest }: Props) => {
  const designId = useDesignId();
  const matchDesign = useMatch(RouteNames.DESIGNS.DESIGN);
  const matchDesignPart = useMatch(RouteNames.DESIGNS.DESIGN_PART);
  const isInLayout = matchDesign !== null || matchDesignPart !== null;
  const url = generatePath(routeName, { designId });
  const { openWindow, postWindowCloseMessage } = useWindowManager();
  const [editMode, setEditMode] = useState(false);

  const handleWindowOpen = () => {
    const windowName = title || '_blank';
    openWindow(url, windowName, windowFeatures || 'width=800,height=600,left=200,top=200');
  };

  const handleWindowClose = () => {
    postWindowCloseMessage(url);
  };

  const renderWindowIcon = () => {
    return isInLayout ? (
      <OpenWindowIcon data-testid="open-window-icon" onClick={handleWindowOpen} />
    ) : (
      <CloseWindowIcon data-testid="close-window-icon" onClick={handleWindowClose} />
    );
  };

  const titleBg = useColorModeValue('gray.100', 'gray.600');
  const textColor = useColorModeValue('black', 'white');

  const renderTitle = () => {
    return (
      <HStack
        h="30px"
        w="full"
        color={textColor}
        bg={titleBg}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.300"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        top={0}
        zIndex={1}
        p={4}
      >
        <Text
          fontWeight="500"
          textTransform="uppercase"
          userSelect="none"
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
        >
          {title}
        </Text>
        <HStack spacing={4} position="absolute" right={4}>
          {actions}
          {renderWindowIcon()}
        </HStack>
      </HStack>
    );
  };

  return (
    <Box
      onMouseEnter={() => setEditMode(true)}
      onMouseLeave={() => setEditMode(false)}
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="hidden"
      {...rest}
    >
      {showTitle && renderTitle()}
      <Box flexGrow={1} overflowY="auto">
        {typeof children === 'function' ? children({ editMode, renderWindowIcon }) : children}
      </Box>
    </Box>
  );
};

export default ManagedWindow;
