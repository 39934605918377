import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import TextInput from '../../components/form/TextInput';
import { RouteNames } from '@web/consts/routeNames.ts';
import { apiUploadQuote } from '../../api/admin-api';
import { errorToast } from '../../common/toasts';
import SubmitButton from '../../components/buttons/SubmitButton';
import S3FileInput from '../../components/form/S3FileInput';
import { Alert, AlertIcon, Box, Link, Stack, Text } from '@chakra-ui/react';
import { useOrder } from '../../queries/orders';
import Loading from '../../components/Loading';
import { OrderDocumentTypes } from '../../common/lib/common-types/orders';
import AdminPage from './AdminPage';
import { S3Upload } from '../../common/lib';

interface FormValues extends FieldValues {
  orderId: string;
  estimateNumber: string;
  expirationInDays: number;
  documents: S3Upload[];
}

interface Props {
  orderId: string;
}

function QuoteUploadForm({ orderId }: Props) {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormValues>({ defaultValues: { estimateNumber: '', expirationInDays: 7, documents: [] } });

  const onSubmit = async (formData: FormValues) => {
    if (!formData.documents.length) {
      errorToast('Please select a PDF document to upload.');
      return;
    }

    const results = await apiUploadQuote({
      documentableId: orderId,
      documentableType: 'Order',
      s3Key: formData.documents[0].s3Key,
      type: OrderDocumentTypes.Quote,
      estimateNumber: formData.estimateNumber,
      expirationInDays: formData.expirationInDays,
    });

    if (results) {
      navigate(RouteNames.ORDERS.SHOW.replace(':orderId', orderId));
    }
  };

  const validateDocuments = (documents: S3Upload[]) => {
    return documents.length > 0;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6}>
        <TextInput
          label="Estimate number *"
          {...register('estimateNumber', { required: true })}
          hint="Please input the estimate number generated by QuickBooks"
        />
        <TextInput
          label="Quote expiration in days"
          {...register('expirationInDays', {
            required: false,
          })}
          min={3}
          type="number"
        />
        <S3FileInput
          label="Quote document (PDF) *"
          hint="Upload the manually generated quote. This should be a single PDF document."
          control={control}
          name="documents"
          multiple={false}
          {...register('documents', { validate: validateDocuments })}
        />
        <Box>
          <SubmitButton text="Upload Quote" loading={isSubmitting} disabled={!isValid} />
        </Box>
      </Stack>
    </form>
  );
}

export default function AdminManualQuoteUpload() {
  const { orderId = '' } = useParams();
  const { data: order } = useOrder(orderId);

  if (!order) {
    return <Loading message="Please wait while we load your order…" />;
  }

  return (
    <AdminPage title={`Manual Quote Upload for Order ${order.orderNumber}`}>
      <Stack p={8} spacing={8} maxW="32rem">
        <Alert status="warning">
          <AlertIcon />
          <Text>
            Please note that uploading a quote will notify the order creator (
            <Link textDecoration="underline" href={`mailto:${order.user.email}`}>
              {order.user.name}
            </Link>
            ) via email.
          </Text>
        </Alert>
        <Text>Manually generated quotes should be in PDF format.</Text>
        <QuoteUploadForm orderId={orderId} />
      </Stack>
    </AdminPage>
  );
}
