import { Column } from './table/Table.tsx';
import { User } from '@senrasystems/senra-ui';
import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

interface Props {
  users: User[];
}

const EmptyUsersList = () => <Text>Cannot load users list.</Text>;

const UsersList = ({ users }: Props) => {
  const columns: Column[] = [
    {
      title: 'Name',
      key: 'name',
    },
    {
      title: 'Email',
      key: 'email',
    },
  ];

  const data = users.map((user) => ({
    id: user.id,
    values: [{ value: user.name }, { value: user.email }],
  }));

  return (
    <div>
      {users.length === 0 && <EmptyUsersList />}
      {users.length > 0 && (
        <Table>
          <Thead>
            <Tr>
              {columns.map((col) => (
                <Th key={col.key}>{col.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => (
              <Tr key={row.id}>
                {row.values.map((cell, i) => (
                  <Td key={`${row.id}-${i}`}>{cell.value}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </div>
  );
};

export default UsersList;
