import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import Loading from '../Loading';

const AuthenticationGuard = ({ component }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

export default AuthenticationGuard;
