import { ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { errorToast, successToast } from '../../common/toasts';
interface Props<T extends FieldValues> extends ModalProps {
  onSubmit: (data: T) => Promise<T | undefined>;
  header: ReactNode | string;
  footer?: ReactNode;
  children: ReactNode;
  successToastMsg: string;
  minWidth?: string[] | string;
  closeButton?: boolean;
}

const ModalForm = <T extends FieldValues>({
  isOpen,
  onSubmit,
  successToastMsg,
  size,
  onClose,
  header,
  footer,
  children,
  minWidth,
  closeButton = true,
}: Props<T>) => {
  const { handleSubmit, reset } = useFormContext<T>();

  const submitHandler = (data: T) =>
    onSubmit(data)
      .then((response) => {
        if (response) {
          reset(response);
          successToast(successToastMsg);
          closeHandler();
        }
      })
      .catch((e) => {
        errorToast(e.message);
        reset({} as T, { keepValues: true });
      });

  const closeHandler = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size={size} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minWidth={minWidth}>
        <form
          onSubmit={async (event) => {
            event.stopPropagation();
            event.preventDefault();
            await handleSubmit(submitHandler)(event);
          }}
        >
          <ModalHeader>{header}</ModalHeader>
          {closeButton && <ModalCloseButton />}
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ModalForm;
