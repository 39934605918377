import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { Box, useDisclosure } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { apiGetInsertArrangements } from '@web/api/parts-api.ts';
import { useInsertArrangements } from '../api/queries.ts';
import CreatableTypeAheadSelectInput from '../../../components/form/CreatableTypeAheadSelectInput.tsx';
import InsertArrangementModal, { Mode } from '../features/InsertArrangements/InsertArrangementModal.tsx';
import { InsertArrangement, Part } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const InsertArrangementDropdown = ({ editing }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, register, setValue } = useFormContext<Part>();
  const { field } = useController({ name: 'insertArrangement', control });
  const { data: insertArrangements } = useInsertArrangements();

  const formMethods = useForm<InsertArrangement>();

  const toOption = (insertArrangement: InsertArrangement) => ({
    label: insertArrangement.name,
    value: insertArrangement.id,
  });

  const defaultOptions = insertArrangements?.data.map(toOption);

  const optionValue = field.value ? toOption(field.value) : null;

  const handleSearchInsertArrangements = async (inputValue: string) => {
    const res = await apiGetInsertArrangements(inputValue);
    if (res) {
      return res.data.map((insertArrangement) => ({ label: insertArrangement.name, value: insertArrangement.id }));
    }
    return [];
  };

  const handleCreateInsertArrangement = (inputValue: string) => {
    formMethods.reset({ name: inputValue, cavities: [] });
    onOpen();
    return Promise.resolve({ label: '', value: '' });
  };

  const handleOnChange = (newOption: SingleValue<{ label: string; value: string }>) => {
    if (newOption) {
      field.onChange({ name: newOption.label, id: newOption.value });
      setValue('insertArrangementId', newOption.value);
    }
  };

  return (
    <EditableFormText
      editing={editing}
      value={field.value ? `${field.value.name} // ${field.value.cavities?.length} Cavities` : ''}
      label="Insert Arrangement"
    >
      <Box>
        {isOpen && (
          <FormProvider {...formMethods}>
            <InsertArrangementModal
              mode={Mode.CREATE}
              isOpen={isOpen}
              onClose={onClose}
              afterSubmit={(insertArrangement) => handleOnChange(toOption(insertArrangement))}
            />
          </FormProvider>
        )}
        <input type="hidden" {...register('insertArrangementId', { value: field.value?.id })} />
        <CreatableTypeAheadSelectInput
          label="Insert Arrangement"
          editing={editing}
          value={optionValue}
          defaultOptions={defaultOptions}
          isMulti={false}
          onChange={handleOnChange}
          onCreateOption={handleCreateInsertArrangement}
          loadOptions={handleSearchInsertArrangements}
        />
      </Box>
    </EditableFormText>
  );
};

export default InsertArrangementDropdown;
