import { OrderState } from '@web/common/lib';
import { toTitleCase } from '../common/lib/string-utils/string-utils';
import Badge from './Badge';

interface Props {
  orderState: OrderState;
}

const OrderStateBadge = ({ orderState }: Props) => {
  const colorMap: Partial<Record<OrderState, string>> = {
    accepted: 'green',
    delivered: 'green',
    quoted: 'green',
    pending_quote: 'yellow',
  };

  return <Badge color={colorMap[orderState] ?? 'red'}>{toTitleCase(orderState)}</Badge>;
};

export default OrderStateBadge;
