import { Box, BoxProps, HStack, IconButtonProps, Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { isAccessory, isLinear, Part } from '@senrasystems/senra-ui';
import { useDesignId } from '../../../hooks/useDesignId.tsx';
import { useSuggestPartName } from '../../../hooks/useSuggestPartName.tsx';
import { useCreateDesignPartMutation } from '../../../api/queries.ts';
import AddIconButton from '../../../components/AddIconButton.tsx';
import { useDesignToast } from '../../../hooks/useDesignToast.tsx';

interface Props extends BoxProps {
  part: Part;
  inputProps?: InputProps;
  iconButtonProps?: IconButtonProps;
}

/**
 * AddPartButton component is a button that allows the user to add a part to the design. It includes an input field for
 * the part name and an icon button to add the part to the design. The part name is automatically suggested based on the
 * part type. If the user changes the suggested name, the component will not suggest a new name until the user does a
 * new search. If we're adding a part is an accessory, we don't show the input field for the part name.
 * @param part
 * @param iconButtonProps
 * @param inputProps
 * @param rest
 * @constructor
 */
const AddPartButton = ({ part, inputProps, iconButtonProps, ...rest }: Props) => {
  const designId = useDesignId();
  const { showErrorToast, showSuccessToast } = useDesignToast();
  const { suggestPartName, isLoading, error } = useSuggestPartName(designId);
  const {
    mutate: addDesignPart,
    isPending: addDesignPartPending,
    isSuccess: addDesignPartSuccess,
    error: addDesignPartError,
  } = useCreateDesignPartMutation();
  const [partName, setPartName] = useState('');
  const [suggestedNameHasChanged, setSuggestedNameHasChanged] = useState(false);

  // Suggest a part name based on the part type
  useEffect(() => {
    if (part && !isLoading && !error && !suggestedNameHasChanged) {
      setPartName(suggestPartName(part));
      setSuggestedNameHasChanged(false);
    }
  }, [error, isLoading, part, partName, suggestPartName, suggestedNameHasChanged]);

  // Handle success adding a part
  useEffect(() => {
    if (addDesignPartSuccess) {
      setPartName('');
      setSuggestedNameHasChanged(false);
      showSuccessToast('Part added', 'The part was successfully added to the design');
    }
  }, [addDesignPartSuccess, showSuccessToast]);

  // Handle errors adding a part
  useEffect(() => {
    if (addDesignPartError) {
      showErrorToast('Error adding part', addDesignPartError.message);
    }
  }, [addDesignPartError, showErrorToast]);

  if (!part || isLoading || error) {
    return;
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPartName(event.target.value);
    setSuggestedNameHasChanged(true);
  };

  const handleAddClick = () => {
    const quantity = isLinear(part.type) ? 0 : 1;
    addDesignPart({
      designId,
      data: {
        name: partName,
        quantity: quantity,
        partId: part.id,
      },
    });
  };

  return (
    <Box {...rest}>
      <HStack>
        {!isAccessory(part.type) && (
          <Input
            id="part-name"
            flex={1}
            value={partName}
            onChange={handleOnChange}
            placeholder="Name"
            borderRadius="md"
            _focus={{
              border: 'none',
              boxShadow: 'none',
            }}
            autoComplete="off"
            {...inputProps}
          />
        )}
        <AddIconButton
          aria-label="Add"
          onClick={handleAddClick}
          bg="gray.300"
          _hover={{ color: 'white', bg: 'blue.500' }}
          isLoading={addDesignPartPending || !partName || partName.length === 0}
          {...iconButtonProps}
        />
      </HStack>
    </Box>
  );
};

export default AddPartButton;
