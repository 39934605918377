import { HStack, StackProps, Text } from '@chakra-ui/react';
import EditableText from '../../../../../components/EditableText.tsx';
import { useDesignOverview } from '../../../../../hooks/useDesignOverview.tsx';
import { XYPosition } from '@xyflow/react';
import { GraphOperation } from '../../../graph/Operations.ts';
import { useLayoutBuilder } from '../../../hooks/useLayoutBuilder.tsx';
import { UUID } from '@senrasystems/senra-ui';
import { useUpdateConductorLengths } from '../../../hooks/useUpdateConductorLengths.tsx';

interface MeasurementEdgeLabelProps {
  edgeId: UUID;
  measurement: number;
  position: XYPosition;
}

/**
 * Measurement edge label component for displaying and editing the measurement value.
 * @param edgeId
 * @param measurement
 * @param position
 * @constructor
 */
const MeasurementEdgeLabel = ({ edgeId, measurement, position }: MeasurementEdgeLabelProps) => {
  // Get the unit of measure
  const { id: designId, lengthUnit } = useDesignOverview();

  // Get the graph builder to execute operations
  const { executeGraphOperation } = useLayoutBuilder();

  // Get the function to update conductor lengths
  const updateConductorLengths = useUpdateConductorLengths(designId);

  // Define the label properties
  const labelProps: Partial<StackProps> = {
    h: '20px',
    w: '100px',
    fontFamily: 'mono',
    fontSize: 'sm',
    color: 'gray.950',
    position: 'absolute',
    top: position.y - 20,
    left: position.x,
    cursor: 'pointer',
  };

  // Save the measurement value
  const handleEdit = (value: string) => {
    // Parse the value to a number
    const newValue = parseFloat(value);
    if (isNaN(newValue)) {
      return;
    }

    // Graph operation to update the measurement value
    const params: GraphOperation = {
      type: 'UpdateEdgeData',
      params: { edgeId, data: { measurement: Number(value) } },
    };

    // Execute the graph operation
    updateConductorLengths({
      onSuccess: () => executeGraphOperation(params),
    });
  };

  return (
    <HStack {...labelProps} sx={{ pointerEvents: 'all' }}>
      <EditableText text={measurement.toString()} onEdit={handleEdit} bg="white" />
      <Text>{lengthUnit}</Text>
    </HStack>
  );
};

export default MeasurementEdgeLabel;
