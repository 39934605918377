import { BuildNote, UUID } from '@senrasystems/senra-ui';
import { Operations } from '../Operations';
import { reject, flatMap, map, keyBy } from 'lodash';
import { Graph, isNoteGroupNode } from '../../types';
import { createAndAddNoteGroupNode } from '../NodeFactory';
import { Edge, Node } from '@xyflow/react';
import { calculateMidpoint } from '../../utils/geometry';
import { createAndAddNoteEdge } from '../EdgeFactory';

// Given the id of the node or edge and the id maps for nodes and edges, returns the position of a node or the midpoint of an edge
const getElementPosition = (id: UUID, nodesById: { [id: UUID]: Node }, edgesById: { [id: UUID]: Edge }) => {
  const node = nodesById[id];
  const edge = edgesById[id];

  if (node) {
    return node.position;
  } else if (edge) {
    const sourceNode = nodesById[edge.source];
    const targetNode = nodesById[edge.target];

    return calculateMidpoint(sourceNode.position, targetNode.position);
  }

  return null;
};

export type UpdateBuildNotesOperation = {
  type: 'UpdateBuildNotes';
  params: {
    flagNotes: BuildNote[];
  };
};

/**
 * Adds any missing and removes any unnecessary note groups from the layout.
 */

export class UpdateBuildNotes implements Operations<UpdateBuildNotesOperation> {
  // Execute the operation
  execute(graph: Graph, operation: UpdateBuildNotesOperation): Graph {
    const { nodes, edges } = graph;
    const {
      params: { flagNotes },
    } = operation;

    let updatedNodes = [...nodes];
    let updatedEdges = [...edges];

    // Step 1: Remove any note nodes for unreferenced element ids
    // 1a. Get all the element ids referenced by build notes
    const buildNoteElementIds = flatMap(flagNotes, 'layoutElementIds');
    const buildNoteElementIdsSet = new Set(buildNoteElementIds);

    // 1b. Get the current note nodes to remove (ones with element ids not referenced by build notes)
    const currentNoteNodes = updatedNodes.filter(isNoteGroupNode);
    const noteNodesToRemove = currentNoteNodes.filter(({ data: { layoutElementId } }) => {
      const isElementReferenced = buildNoteElementIdsSet.has(layoutElementId);
      return !isElementReferenced;
    });
    const noteNodeIdsToRemove = new Set(map(noteNodesToRemove, 'id'));

    // 1c. Remove all the note nodes and edges to remove
    updatedNodes = reject(updatedNodes, (node) => noteNodeIdsToRemove.has(node.id));
    updatedEdges = reject(
      updatedEdges,
      (edge) => noteNodeIdsToRemove.has(edge.source) || noteNodeIdsToRemove.has(edge.target),
    );

    // Step 2: Add any missing NoteGroupNodes.
    // 2a. First calculate the elementLayoutIds already have NoteGroupNodes associated with them
    const noteGroupNodesAfterRemoval = updatedNodes.filter(isNoteGroupNode);
    const elementIdsWithNoteNodes = new Set(map(noteGroupNodesAfterRemoval, 'data.layoutElementId'));

    // 2b. Then see which elements have build notes but are missing note nodes
    const elementIdsMissingNoteNode = buildNoteElementIds.filter((id) => !elementIdsWithNoteNodes.has(id));

    // 2c. For each id missing a node, create a node and an edge from the node to the element.
    const nodesById = keyBy(updatedNodes, 'id');
    const edgesById = keyBy(updatedEdges, 'id');

    elementIdsMissingNoteNode.forEach((layoutElementId) => {
      const elementPosition = getElementPosition(layoutElementId, nodesById, edgesById);
      if (elementPosition) {
        // Offset the note node position by a default amount in the x and y directions
        const noteNodePosition = { x: elementPosition.x - 100, y: elementPosition.y + 50 };
        const newNode = createAndAddNoteGroupNode(updatedNodes, noteNodePosition, { layoutElementId });

        if (newNode) {
          createAndAddNoteEdge(updatedEdges, newNode.id, layoutElementId);
        }
      }
    });

    return { nodes: updatedNodes, edges: updatedEdges };
  }
}
