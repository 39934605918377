import { useCallback, useState } from 'react';
import { ConnectionGroup } from './useSchematic.tsx';
import { ResolvedConnectionPoint } from '@senrasystems/senra-ui';

// Track selected states of objects in the schematic
interface SelectionState {
  source: ResolvedConnectionPoint[];
  destination: ResolvedConnectionPoint[];
  conductor: ResolvedConnectionPoint[];
}

/**
 * useSchematicSelectionState hook tracks selected states of connection points and conductors in the schematic.
 * @param connectionGroup
 */
export const useSchematicSelectionState = (connectionGroup: ConnectionGroup) => {
  // Track selected states of connection points and conductors
  const [selectionState, setSelectionState] = useState<SelectionState>({
    source: [],
    destination: [],
    conductor: [],
  });

  // Helper to update selection state
  const updateSelectionState = useCallback((newState: Partial<SelectionState>) => {
    setSelectionState((prevState) => ({
      source: newState.source || prevState.source,
      destination: newState.destination || prevState.destination,
      conductor: newState.conductor || prevState.conductor,
    }));
  }, []);

  // Function to select all connections related to a specific source connection point
  const handleSourceSelect = (sourceCp: ResolvedConnectionPoint) => {
    if (!sourceCp) return;

    const connections = connectionGroup.connections.filter((c) => c.source?.id === sourceCp.id);

    // Filter out null values from mapped results
    const newDestinations = connections.map((c) => c.destination).filter(Boolean) as ResolvedConnectionPoint[];
    const newConductors = connections.map((c) => c.conductor).filter(Boolean) as ResolvedConnectionPoint[];

    updateSelectionState({
      source: [sourceCp],
      destination: newDestinations,
      conductor: newConductors,
    });
  };

  // Function to select all connections related to a specific destination connection point
  const handleDestinationSelect = (destinationCp: ResolvedConnectionPoint) => {
    if (!destinationCp) return;

    const connections = connectionGroup.connections.filter((c) => c.destination?.id === destinationCp.id);

    // Filter out null values from mapped results
    const newSources = connections.map((c) => c.source).filter(Boolean) as ResolvedConnectionPoint[];
    const newConductors = connections.map((c) => c.conductor).filter(Boolean) as ResolvedConnectionPoint[];

    updateSelectionState({
      source: newSources,
      destination: [destinationCp],
      conductor: newConductors,
    });
  };

  // Function to select all connections related to a specific conductor
  const handleConductorSelect = (conductorCp: ResolvedConnectionPoint | null) => {
    if (!conductorCp) return;

    const connections = connectionGroup.connections.filter((c) => c.conductor?.id === conductorCp.id);

    // Filter out null values from mapped results
    const newSources = connections.map((c) => c.source).filter(Boolean) as ResolvedConnectionPoint[];
    const newDestinations = connections.map((c) => c.destination).filter(Boolean) as ResolvedConnectionPoint[];

    updateSelectionState({
      source: newSources,
      destination: newDestinations,
      conductor: [conductorCp],
    });
  };

  // Handler to clear the selection state
  const clearSelection = () => {
    setSelectionState({ source: [], destination: [], conductor: [] });
  };

  // Check if a source connection point is selected
  const isSourceSelected = (cp: ResolvedConnectionPoint): boolean => {
    if (!cp) return false;
    return selectionState.source.some((item) => item?.id === cp?.id);
  };

  // Check if a destination connection point is selected
  const isDestinationSelected = (cp: ResolvedConnectionPoint): boolean => {
    if (!cp) return false;
    return selectionState.destination.some((item) => item?.id === cp?.id);
  };

  // Check if a conductor is selected
  const isConductorSelected = (cp: ResolvedConnectionPoint): boolean => {
    if (!cp) return false;
    return selectionState.conductor.some((item) => item?.id === cp?.id);
  };

  return {
    isSourceSelected,
    isDestinationSelected,
    isConductorSelected,
    handleSourceSelect,
    handleDestinationSelect,
    handleConductorSelect,
    clearSelection,
  };
};
