import AdminPage from '../AdminPage';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  ChakraProps,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon, ExternalLinkIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Cell, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ClipboardEvent, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';
import Papa from 'papaparse';
import * as xlsx from 'xlsx';
import { apiFindBestOffers } from '@web/api/procurement-api.ts';
import { errorToast } from '@web/common/toasts.ts';
import { usdCurrencyFormatter } from '@web/common/util.ts';
import Loading from '../../../components/Loading';
import FileInput from '../../../components/form/FileInput';
import { SenraTable } from '../../../components/table/SenraTable';
import { useSenraTable } from '../../../components/table/hooks/useSenraTable';
import {
  BestOffersRequest,
  defaultBestOfferRequest,
  IBestOfferRequest,
  PartOffers,
  ProcurementOffer,
} from '@web/models/Procurement.model.ts';
import OffersResultsCard from './OffersResultsCard';
import { BiTrash } from 'react-icons/bi';
import { downloadFile } from '@web/common/api.ts';

const AdminFindBestOffers = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [bestOffersRequestItems, setBestOffersRequestItems] = useState<IBestOfferRequest[]>([defaultBestOfferRequest]);
  const [partOffersResults, setPartOffersResults] = useImmer<PartOffers[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [harnessQuantity, setHarnessQuantity] = useState<number>(1);
  const { CellText } = useSenraTable<ProcurementOffer>();
  const { CellInputString } = useSenraTable<IBestOfferRequest>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedOffers: ProcurementOffer[] = useMemo(
    () =>
      partOffersResults
        .flatMap((partOffers) => [...partOffers.bestOffers, ...partOffers.nonBestOffers])
        .filter((offer) => offer.selected),
    [partOffersResults],
  );

  const isRequestRowValid = (bestOfferRequest: IBestOfferRequest): boolean => {
    return !!bestOfferRequest.partNumber;
  };

  const deleteRequestRow = (index: number): void => {
    setBestOffersRequestItems((requestItems) => requestItems.filter((_, i) => i !== index));
  };

  const validRequestItems: IBestOfferRequest[] = bestOffersRequestItems.filter(isRequestRowValid);

  const updateBestOffers = (partNumber: string) => (newBestOffers: ProcurementOffer[]) => {
    setPartOffersResults((draft) => {
      for (const partOffers of draft) {
        if (partOffers.partNumber === partNumber) {
          partOffers.bestOffers = newBestOffers;
        }
      }
    });
  };

  const handleRadioOnChange =
    (partNumber: string) =>
    (offerId: string): void => {
      setPartOffersResults((draft) => {
        for (const partOffers of draft) {
          if (partOffers.partNumber === partNumber) {
            for (const offer of partOffers.bestOffers) {
              offer.selected = offer.id === offerId;
            }
            for (const offer of partOffers.nonBestOffers) {
              offer.selected = offer.id === offerId;
            }
          }
        }
      });
    };

  const resetRequestData = () => {
    setFiles([]);
    setBestOffersRequestItems([defaultBestOfferRequest]);
    setPartOffersResults([]);
    setHarnessQuantity(1);
  };

  const getMaterialCost = (multiplier: number = 1): string => {
    const totalCost = selectedOffers.reduce((acc, offer) => {
      const rowTotal = parseFloat(offer.totalPriceUsd) || 0;

      return Math.round((acc + rowTotal) * 100) / 100;
    }, 0);

    return usdCurrencyFormatter.format(totalCost * multiplier);
  };

  const getHighestLeadTime = (): number => {
    if (!selectedOffers.length) {
      return 0;
    }

    return Math.max(...selectedOffers.map((offer) => (offer.leadDays && parseInt(offer.leadDays)) || 0));
  };

  const onCellPaste = (e: ClipboardEvent<HTMLDivElement>, cell: Cell<IBestOfferRequest, unknown>) => {
    const ceilInt = (val: string) => Math.ceil(parseInt(val) || 1);
    const isSingleValue = (val: string) => !val.includes('\t') && !val.includes('\n');
    const isSingleColumn = (row: string): boolean => row.split('\t').length === 1;
    const pastedData: string = e.clipboardData.getData('text');

    // If just pasting a single val, default behavior.
    if (isSingleValue(pastedData)) return;

    e.preventDefault();

    const rows = pastedData.split('\n');

    // If all rows have only one column and was pasted in the
    // "quantity" column, paste in that column.
    if (rows.every(isSingleColumn) && cell.column.id === 'quantity') {
      setBestOffersRequestItems((items) => [
        ...items
          .filter(isRequestRowValid)
          .map((item, idx) => ({ partNumber: item.partNumber, quantity: ceilInt(rows[idx]) })),
        defaultBestOfferRequest,
      ]);

      return;
    }

    // Otherwise, create new rows with the "partNumber" column
    // and "quantity" column, if a second column is available.
    // Note that we're making some assumptions about the data.
    // This tool is internal and low risk, so making these assumptions
    // should be fine and easy for the user to circumvent.
    const requestItems: IBestOfferRequest[] = [];

    for (const row of pastedData.split('\n')) {
      const cols = row.split('\t');
      const reqItem = { partNumber: cols[0], quantity: ceilInt(cols[1]) };

      isRequestRowValid(reqItem) && requestItems.push(reqItem);
    }

    setBestOffersRequestItems((items) => [
      ...items.filter(isRequestRowValid),
      ...requestItems,
      defaultBestOfferRequest,
    ]);
  };

  const onBomUpload = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length !== 1) {
      errorToast('Please make sure to upload only one file.');
      return;
    }

    const [file] = acceptedFiles;
    let csv: string;

    if (file.type === 'text/csv') {
      csv = await file.text();
    } else if (file.type.includes('spreadsheet')) {
      csv = await parseXlsxToCsv(file);
    } else {
      errorToast('The uploaded file must be Excel or CSV format.');
      return;
    }

    const bestOffersRequest: BestOffersRequest = parseCsvToBestOffersRequest(csv);
    const validItems: IBestOfferRequest[] = bestOffersRequest.requestItems.filter(isRequestRowValid);
    setBestOffersRequestItems(validItems);
    setFiles(acceptedFiles);
  };

  const parseCsvToBestOffersRequest = (csv: string): BestOffersRequest => {
    const contentsWithHeaders = 'partNumber,quantity\n' + csv;
    const parsedFileContents: Papa.ParseResult<IBestOfferRequest> = Papa.parse(contentsWithHeaders, {
      header: true,
      skipEmptyLines: 'greedy',
    });

    return BestOffersRequest.fromParsedCsv(parsedFileContents);
  };

  const parseXlsxToCsv = async (file: File): Promise<string> => {
    const fileContents = await file.arrayBuffer();
    const workbook = xlsx.read(fileContents);
    return xlsx.utils.sheet_to_csv(workbook.Sheets.Sheet1);
  };

  const handleBestOffersSubmit = async (): Promise<void> => {
    if (validRequestItems.length) {
      setBestOffersRequestItems(validRequestItems);
      const bestOffersRequest = new BestOffersRequest(validRequestItems);
      setIsFetching(true);
      const results = await apiFindBestOffers(bestOffersRequest.toDto());
      setIsFetching(false);

      setPartOffersResults(results);
    }
  };

  const handleDownloadCsvTemplateClick = (): void => {
    const fileName = `${new Date().toISOString()}_template.csv`;
    downloadFile('Part Number,Desired Quantity', fileName);
  };

  const downloadSelectedOffersSummary = (): void => {
    const valOrNone = (val: string | undefined) => val ?? 'Not Found';
    const timesHarnessQty = (val: string) => (parseFloat(val) * harnessQuantity).toFixed(3);
    const fileName = `summary_${new Date().toISOString()}.csv`;
    const csvOffers: string[] = [
      `Harness Quantity,${harnessQuantity}`,
      '',
      'Part Number,Distributor,Purchase Link,Manufacturer,Lead Days,Desired Quantity,Available Quantity,Unit Price,Total Price,Total Price * Harness Quantity,PO Number',
    ];

    let rowTotalSum = 0;

    for (const offer of selectedOffers) {
      rowTotalSum += parseFloat(offer.totalPriceUsd);

      csvOffers.push(
        `${offer.desiredPartNumber},${offer.distributor},${ProcurementOffer.urlOrNone(offer.purchaseLink)},${valOrNone(
          offer.manufacturer,
        )},${valOrNone(offer.leadDays)},${offer.desiredQuantity},${valOrNone(offer.inventoryLevel)},${
          offer.unitPriceUsd
        },${offer.totalPriceUsd},${timesHarnessQty(offer.totalPriceUsd)}`,
      );
    }

    csvOffers.push('', `,,,,,,,,Grand Total,${timesHarnessQty(rowTotalSum.toString())}`);

    downloadFile(csvOffers.join('\n'), fileName);
  };

  const columnHelper = createColumnHelper<IBestOfferRequest>();

  const requestColumns = useMemo<ColumnDef<IBestOfferRequest>[]>(
    () => [
      {
        id: 'requestColumns',
        columns: [
          {
            header: 'Part Number',
            accessorKey: 'partNumber',
            cell: CellInputString,
            enableColumnFilter: false,
            size: 350,
          },
          {
            header: 'Desired Quantity',
            accessorKey: 'quantity',
            cell: CellInputString,
            enableColumnFilter: false,
          },
          columnHelper.display({
            id: 'actions',
            cell: (props) => (
              <Box textAlign="right">
                <DeleteIcon
                  aria-label="Delete row"
                  textAlign="right"
                  mr={5}
                  onClick={() => deleteRequestRow(props.row.index)}
                />
              </Box>
            ),
          }),
        ],
      },
    ],
    [CellInputString, columnHelper],
  );

  const summaryColumns = useMemo<ColumnDef<ProcurementOffer>[]>(
    () => [
      {
        header: 'Part Number',
        accessorKey: 'desiredPartNumber',
        cell: CellText,
        enableColumnFilter: false,
        size: 250,
      },
      {
        header: 'Distributor',
        accessorKey: 'distributor',
        cell: ({ getValue, row }) => {
          const distributorName = getValue() as string;

          const purchaseLink = row.original.purchaseLink;

          if (purchaseLink.includes('http')) {
            return (
              <Link href={purchaseLink} isExternal>
                {distributorName} <ExternalLinkIcon mx="2px" />
              </Link>
            );
          }

          return (
            <Tooltip label="Purchase link not found">
              <Text>
                {distributorName} <InfoOutlineIcon mx="2px" />
              </Text>
            </Tooltip>
          );
        },
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Manufacturer',
        accessorKey: 'manufacturer',
        cell: CellText,
        enableColumnFilter: false,
        size: 250,
      },
      {
        header: 'Dist Lead Days',
        accessorKey: 'leadDays',
        cell: CellText,
        enableColumnFilter: false,
      },
      {
        header: 'Desired Quantity',
        accessorKey: 'desiredQuantity',
        cell: CellText,
        enableColumnFilter: false,
      },
      {
        header: 'Unit Price',
        accessorKey: 'unitPriceUsd',
        cell: CellText,
        enableColumnFilter: false,
      },
      {
        header: 'Total Price',
        accessorKey: 'totalPriceUsd',
        cell: CellText,
        enableColumnFilter: false,
      },
    ],
    [CellText],
  );

  interface StatsProps extends ChakraProps {
    materialTitle?: string;
    leadTitle?: string;
    summaryBtn?: boolean;
  }

  const SelectedOffersStats = ({
    materialTitle = 'Material Cost',
    leadTitle = 'Lead Time',
    width = '200px',
    bg = 'blackAlpha.50',
    summaryBtn = true,
  }: StatsProps) => (
    <Box w={width} bg={bg} p="4" textAlign="center">
      <StatGroup>
        <Stat>
          <StatLabel>{materialTitle}</StatLabel>
          <StatNumber id="materialCost">{getMaterialCost()}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>{leadTitle}</StatLabel>
          <StatNumber id="highestLeadTime">{getHighestLeadTime()} Days</StatNumber>
        </Stat>
      </StatGroup>
      {summaryBtn && (
        <Button onClick={onOpen} colorScheme="purple" mt={5}>
          View Summary
        </Button>
      )}
    </Box>
  );

  const bgColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="80%">
          <ModalHeader>Offer Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width="full">
              <Center>
                <SenraTable
                  tableDefinition={summaryColumns}
                  data={selectedOffers}
                  isNewRowEnabled={false}
                  supportNestedKeys={false}
                />
              </Center>
            </Box>
            <Flex direction="row" justifyContent="center" columnGap="4em" alignItems="center" mt={35}>
              <Box width="25%">
                <SelectedOffersStats
                  materialTitle="Harness Material Cost"
                  leadTitle="Estimated Lead Time"
                  width="full"
                  summaryBtn={false}
                  bg="inherit"
                />
              </Box>
              <Text fontSize="3xl">&#215;</Text>
              <Box>
                <NumberInput
                  width="100px"
                  min={0}
                  value={harnessQuantity || 0}
                  onChange={(_, numVal) => setHarnessQuantity(numVal)}
                >
                  <NumberInputField id="harnessQtyInput" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
              <Text fontSize="3xl">&#61;</Text>
              <StatGroup textAlign="center">
                <Stat>
                  <StatLabel>Total Material Cost</StatLabel>
                  <StatNumber id="totalMaterialCost">{getMaterialCost(harnessQuantity || 0)}</StatNumber>
                </Stat>
              </StatGroup>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="purple" onClick={downloadSelectedOffersSummary}>
              Download Summary
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box textAlign="right" marginTop="10px" marginRight="20px">
        <Tooltip label="Submit Feedback">
          <Link href="https://senrasystems.atlassian.net/wiki/spaces/SD/pages/56099189/Stakeholder+Feedback" isExternal>
            <box-icon name="message-edit" color="white" />
          </Link>
        </Tooltip>
      </Box>

      <Flex position="sticky" px={4} mb={-36} zIndex="1">
        <Spacer />
        <SelectedOffersStats />
      </Flex>

      <Box>
        <AdminPage title="Find Best Offers">
          <Box>
            <Card bg="transparent">
              <CardHeader>
                <Heading>Upload Excel, CSV or Fill Out Table</Heading>
              </CardHeader>

              <CardBody>
                <Flex direction="row" columnGap="50px">
                  <Box>
                    <FileInput
                      label="Upload BOM"
                      hint="Click here or drag to upload your BOM in Excel or CSV format."
                      onUpload={onBomUpload}
                      files={files}
                      multiple={false}
                      bgColor={bgColor}
                    />
                    <Link onClick={handleDownloadCsvTemplateClick} as="i">
                      Click to Download CSV BOM template
                    </Link>
                  </Box>
                  <Flex direction="column" justifyContent="space-around">
                    <Text fontSize="3xl" color="gray">
                      &rarr;
                    </Text>
                  </Flex>
                  <Box>
                    <SenraTable
                      tableDefinition={requestColumns}
                      data={bestOffersRequestItems}
                      newRowTemplate={defaultBestOfferRequest}
                      onDataUpdate={setBestOffersRequestItems}
                      supportNestedKeys={false}
                      onPaste={onCellPaste}
                    />
                    <Flex direction="row" justifyContent="end">
                      <Button onClick={resetRequestData} colorScheme="red" variant="outline" leftIcon={<BiTrash />}>
                        Clear Data
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </CardBody>
              <Box p={4}>
                <Button
                  onClick={handleBestOffersSubmit}
                  isDisabled={!validRequestItems.length}
                  colorScheme="purple"
                  w="400px"
                  mt="50px"
                  p="20px"
                >
                  Find Best Offers &rarr;
                </Button>
              </Box>
            </Card>

            {isFetching && <Loading inline message="Finding best offers…" />}
            {!isFetching &&
              partOffersResults.map((partOffers) => {
                return (
                  <OffersResultsCard
                    partNumber={partOffers.partNumber}
                    bestOffers={partOffers.bestOffers}
                    nonBestOffers={partOffers.nonBestOffers}
                    onRadioGroupChange={handleRadioOnChange(partOffers.partNumber)}
                    onBestOffersUpdate={updateBestOffers(partOffers.partNumber)}
                  />
                );
              })}
          </Box>
        </AdminPage>
      </Box>
    </>
  );
};

export default AdminFindBestOffers;
