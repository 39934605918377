import { useCallback, useMemo } from 'react';
import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { BuildNote, NoteType, UUID } from '@senrasystems/senra-ui';
import { useUpdateBuildNoteMutation } from '../../../../api/queries';
import { useDesignId } from '../../../../hooks/useDesignId';
import { useDesignBuildNotes } from '../../../../hooks/useDesignBuildNotes';

export const useFlagNoteActions = (elementId: UUID) => {
  const designId = useDesignId();
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);
  const { mutate: updateNoteMutation } = useUpdateBuildNoteMutation();

  const flagNoteIdsForElement = useMemo(() => {
    if (elementId) {
      const flagNotesForElement = flagNotes.filter((note) => note.layoutElementIds.includes(elementId));
      return flagNotesForElement.map(({ id }) => id);
    }

    return [];
  }, [flagNotes, elementId]);

  const handleToggleFlagNote = useCallback(
    (flagNote: BuildNote) => {
      // We should always have a design, but we check just in case
      if (designId) {
        const { id: noteId, layoutElementIds } = flagNote;
        const shouldAddNoteToElement = !layoutElementIds.includes(elementId);

        const updatedLayoutElementIds = shouldAddNoteToElement
          ? [...layoutElementIds, elementId]
          : layoutElementIds.filter((id) => id !== elementId);

        // Update the note in the server
        updateNoteMutation({ designId, noteId, buildNote: { layoutElementIds: updatedLayoutElementIds } });
      }
    },
    [elementId, designId, updateNoteMutation],
  );

  const flagNoteActions = useMemo(() => {
    if (flagNotes.length === 0) {
      return (
        <MenuOptionGroup title="Flag Notes">
          <MenuItemOption isDisabled>No Flag Notes</MenuItemOption>
        </MenuOptionGroup>
      );
    }

    return (
      <MenuOptionGroup title="Flag Notes" type="checkbox" value={flagNoteIdsForElement}>
        {flagNotes.map((note) => (
          <MenuItemOption
            key={note.id}
            value={note.id}
            onClick={() => handleToggleFlagNote(note)}
          >{`${note.position} - ${note.body}`}</MenuItemOption>
        ))}
      </MenuOptionGroup>
    );
  }, [flagNotes, flagNoteIdsForElement, handleToggleFlagNote]);

  return { flagNoteActions };
};
