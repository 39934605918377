import Confirm from '../Confirm';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

export function OrderCommentFooter({
  canEdit,
  isEditing,
  canReply = true,
  setIsEditing,
  setIsReplying,
  onDeleteComment,
}: {
  canEdit: boolean;
  isEditing: boolean;
  canReply?: boolean;
  setIsEditing: (v: boolean) => void;
  setIsReplying: (v: boolean) => void;
  onDeleteComment: () => void;
}) {
  if (!canReply && (!canEdit || isEditing)) return <div />;
  return (
    <Box>
      <Flex>
        {canReply && (
          <Text
            opacity={0.6}
            _hover={{ opacity: 1, textDecoration: 'underline', color: 'primary' }}
            cursor="pointer"
            onClick={() => setIsReplying(true)}
          >
            Reply
          </Text>
        )}
        {!canReply && (
          <Tooltip
            bg="gray.900"
            color="gray.50"
            fontWeight="normal"
            hasArrow
            label="Please click Reply on the parent comment"
            placement="right"
          >
            <Text opacity={0.2}>Reply</Text>
          </Tooltip>
        )}
        <Box mr={1} />
        {canEdit && <>·</>}
        <Box ml={1} />
        {canEdit && (
          <>
            <Text
              opacity={0.6}
              _hover={{ opacity: 1, textDecoration: 'underline', color: 'primary' }}
              cursor="pointer"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Text>
            <Box mr={1} />
            ·
            <Box ml={1} />
            <Confirm
              title="Delete Comment"
              body="Are you sure you want to delete this comment? Any replies to this comment will also be deleted."
              onConfirm={onDeleteComment}
            >
              <Text
                opacity={0.6}
                _hover={{ opacity: 1, textDecoration: 'underline', color: 'primary' }}
                cursor="pointer"
                onClick={() => setIsEditing(true)}
              >
                Delete
              </Text>
            </Confirm>
          </>
        )}
      </Flex>
    </Box>
  );
}
