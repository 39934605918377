import { Button } from '@chakra-ui/react';

interface SubmitButtonProps {
  text?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  loading?: boolean;
  disabled?: boolean;
}

const SubmitButton = ({ text = 'Save', size = 'md', loading, disabled }: SubmitButtonProps) => (
  <Button isLoading={loading} loadingText={text} isDisabled={disabled} colorScheme="purple" size={size} type="submit">
    {text}
  </Button>
);

export default SubmitButton;
