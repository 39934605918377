import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedSetValue = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

  useEffect(() => {
    debouncedSetValue(value);

    return () => {
      debouncedSetValue.cancel();
    };
  }, [value, debouncedSetValue]);

  return debouncedValue;
};

export default useDebouncedValue;