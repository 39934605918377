import { apiGetOrderById, apiGetOrders } from '../api/order-api';
import { Order, OrderCommentDto } from '../common/lib';
import { apiGetOrderComments } from '../api/order-comments-api';
import { useQuery } from '@tanstack/react-query';

export const ordersCacheKey = (page: string, query?: string | null, state?: string | null) =>
  ['orders', page, query, state].filter(Boolean);
export const orderCacheKey = (orderId: string) => ['order', orderId];
export const orderCommentsCacheKey = (orderId: string) => ['order', orderId, 'comments'];

export const useOrders = (page: string, query?: string | null, state?: string | null) => {
  return useQuery({
    queryKey: ordersCacheKey(page, query, state),
    queryFn: () =>
      apiGetOrders(page, { query }, state).then((data) => {
        return { orders: data.data as Order[], meta: data.meta };
      }),
  });
};

export const useOrder = (orderId: string) =>
  useQuery({
    queryKey: orderCacheKey(orderId),
    queryFn: () => apiGetOrderById(orderId).then((data) => data as Order),
    refetchOnWindowFocus: false,
  });

export const useOrderComments = (orderId: string) =>
  useQuery({
    queryKey: orderCommentsCacheKey(orderId),
    queryFn: () => apiGetOrderComments(orderId).then((data) => data.data as OrderCommentDto[]),
  });
