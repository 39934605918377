import { Checkbox, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string | ReactNode;
}

const CheckboxInput = forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { label, ...rest } = props;

  return (
    <FormControl maxW="lg" mb={4} flex={1}>
      <HStack>
        <FormLabel cursor="pointer" fontWeight="normal" w="100%">
          {label}
        </FormLabel>
        <Checkbox borderColor="purple.200" colorScheme="checkbox" ref={ref} size="lg" variant="outline" {...rest} />
      </HStack>
    </FormControl>
  );
});

export default CheckboxInput;
