import { XYPosition } from '@xyflow/react';

/**
 * Calculates the angle in degrees between two points (p1 and p2) in 2D space.
 * The angle is normalized to be within the range of 0 to 360 degrees.
 * @param p1 - The first point (x, y).
 * @param p2 - The second point (x, y).
 * @returns The angle in degrees between p1 and p2.
 */
export const calculateAngleBetweenPoints = (p1: XYPosition, p2: XYPosition): number => {
  const angleInRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
  const angleInDegrees = (angleInRadians * 180) / Math.PI;

  // Adjust to face the desired direction by removing 180 degrees
  return (angleInDegrees + 180 + 360) % 360; // Normalize to [0, 360)
};

/**
 * Calculate the distance between two points.
 * @param p1
 * @param p2
 */
export const calculateDistance = (p1: XYPosition, p2: XYPosition): number => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

/**
 * Calculate the midpoint between two points.
 * @param p1
 * @param p2
 */
export const calculateMidpoint = (p1: XYPosition, p2: XYPosition): XYPosition => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

/**
 * Rotates a point around the origin by a given angle.
 * @param point - The point to rotate.
 * @param angleInDegrees - The angle in degrees.
 * @returns The rotated point.
 */
export const rotatePoint = (point: XYPosition, angleInDegrees: number): XYPosition => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  const rotatedX = point.x * Math.cos(angleInRadians) - point.y * Math.sin(angleInRadians);
  const rotatedY = point.x * Math.sin(angleInRadians) + point.y * Math.cos(angleInRadians);
  return { x: rotatedX, y: rotatedY };
};
