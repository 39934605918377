import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { Design } from '@senrasystems/senra-ui';
import { useEffect, useState } from 'react';
import TypeAheadSelectInput from '@web/components/form/TypeAheadSelectInput';
import { useAdminTenants } from '../../../queries/admin';
import ErrorText from '@web/components/form/ErrorText';
import { useCurrentUser } from '@web/queries/users';
import Loading from '@web/components/Loading';
import { SingleValue } from 'chakra-react-select';

export type DesignFormData = Omit<Design, 'designParts' | 'creator'>;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (data: DesignFormData) => void;
  onSave?: (data: DesignFormData) => void;
  initialData?: DesignFormData;
}

const DesignModal = ({ isOpen, onClose, onCreate, onSave, initialData }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DesignFormData>();

  useEffect(() => {
    if (isOpen) {
      reset(initialData || {});
    }
  }, [isOpen, initialData, reset]);

  useEffect(() => {
    if (!isOpen) {
      reset(initialData || {});
    }
  }, [isOpen, initialData, reset]);

  const submitHandler = (data: DesignFormData) => {
    initialData ? onSave && onSave(data) : onCreate && onCreate(data);
    closeHandler();
    reset();
  };

  const { data: user } = useCurrentUser();
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [value, setValue] = useState<SingleValue<{ label: string; value: string }>>(null);

  const { isLoading, data } = useAdminTenants('1', query);

  const options = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : data?.data.map((tenant: { id: string; name: string }) => ({
        value: tenant.id,
        label: tenant.name,
      })) || [];

  if (!user) {
    return <Loading message="Loading user…" />;
  }

  const handleBlur = () => {
    setError(value?.label.trim().length ? '' : 'Tenant required');
  };
  const modalTitle = initialData ? 'Edit Design' : 'Create Design';

  const closeHandler = () => {
    setValue({ label: '', value: '' });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="custom">
      <ModalOverlay />
      <ModalContent w="600px">
        <ModalHeader>
          {modalTitle}
          <Box borderBottom="1px solid" borderColor="gray.300" px={2} py={2} />
        </ModalHeader>
        <ModalBody w="full">
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              {user.employee && !initialData && (
                <GridItem colSpan={6}>
                  <FormControl isRequired>
                    <FormLabel htmlFor="tenant">Tenant</FormLabel>
                    <Controller
                      name="tenant.id"
                      control={control}
                      rules={{ required: true, onBlur: handleBlur }}
                      render={({ field: { onBlur, onChange } }) => {
                        const handleChange = (selectedOption?: SingleValue<{ label: string; value: string }>) => {
                          onChange(selectedOption?.value ?? null);
                          setError('');
                          setValue(selectedOption ?? { label: '', value: '' });
                        };
                        return (
                          <TypeAheadSelectInput
                            options={options}
                            onChange={handleChange}
                            querySetter={setQuery}
                            value={value}
                            noOptionsMessage={() => 'No matching tenants'}
                            onBlur={onBlur}
                            placeholder="Select a tenant…"
                          />
                        );
                      }}
                    />
                    <ErrorText>{error}</ErrorText>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.partNumber} isRequired>
                  <FormLabel htmlFor="partNumber">Part Number</FormLabel>
                  <Input
                    id="partNumber"
                    placeholder="Part Number"
                    autoComplete="off"
                    {...register('partNumber', { required: 'Part Number is required' })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.partRevision}>
                  <FormLabel htmlFor="partRevision">Part Revision</FormLabel>
                  <Input
                    id="partRevision"
                    placeholder="Part Revision"
                    autoComplete="off"
                    {...register('partRevision')}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!errors.lengthUnit} isRequired>
                  <FormLabel htmlFor="unit">Unit</FormLabel>
                  <Select
                    id="unit"
                    placeholder="Select unit"
                    {...register('lengthUnit', { required: 'Unit is required' })}
                  >
                    <option value="in">in</option>
                    <option value="mm">mm</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={6}>
                <FormControl isInvalid={!!errors.description}>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Textarea id="description" placeholder="Description" {...register('description')} />
                </FormControl>
              </GridItem>
            </Grid>
            <ModalFooter px={0}>
              <Button variant="ghost" mr={3} onClick={closeHandler}>
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit">
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DesignModal;
