import { ReactNode } from 'react';
import BackshellFields from './features/Backshells/BackshellFields.tsx';
import CableFields from './features/Cables/CableFields.tsx';
import ConnectorFields from './features/Connectors/ConnectorFields.tsx';
import ContactFields from './features/Contacts/ContactFields.tsx';
import GenericPartFields from './features/Generic/GenericPartFields.tsx';
import PassiveFields from './features/Passives/PassiveFields.tsx';
import PigtailFields from './features/Pigtails/PigtailFields.tsx';
import SpliceFields from './features/Splices/SpliceFields.tsx';
import WireFields from './features/Wires/WireFields.tsx';
import OverwrapFields from './features/Overwraps/OverwrapFields.tsx';
import { PartType } from '@senrasystems/senra-ui';

export const getFieldsForPartType = (
  partType: PartType,
  editing: boolean = false,
  isCreateMode: boolean = false,
): ReactNode | undefined => {
  switch (partType) {
    case PartType.CABLE:
      return <CableFields editing={editing} />;
    case PartType.CONNECTOR:
      return <ConnectorFields editing={editing} isCreateMode={isCreateMode} />;
    case PartType.CONTACT:
      return <ContactFields editing={editing} />;
    case PartType.PIGTAIL:
      return <PigtailFields editing={editing} />;
    case PartType.GENERIC:
      return <GenericPartFields editing={editing} />;
    case PartType.PASSIVE:
      return <PassiveFields editing={editing} />;
    case PartType.SPLICE:
      return <SpliceFields editing={editing} />;
    case PartType.WIRE:
      return <WireFields editing={editing} />;
    case PartType.BACKSHELL:
      return <BackshellFields editing={editing} />;
    case PartType.OVERWRAP:
      return <OverwrapFields editing={editing} />;
    default:
      return undefined;
  }
};
