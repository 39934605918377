import Title from '../components/Title';
import { Stack, Text } from '@chakra-ui/react';
import { useCurrentTenant } from '../queries/tenants';
import UsersList from '../components/UsersList.tsx';
import Loading from '../components/Loading';

const CompanySettings = () => {
  const { data: tenant, isLoading, isError } = useCurrentTenant();

  if (isLoading) {
    return <Loading />;
  }

  if (!tenant || isError) {
    return <Text m={12}>Company not found. Please contact support@senrasystems.us for assistance.</Text>;
  }

  return (
    <Stack p={8} spacing={8}>
      <Title title={`${tenant.name} Settings`} />
      {!!tenant.users && (
        <Stack spacing={6}>
          <UsersList users={tenant.users} />
        </Stack>
      )}
    </Stack>
  );
};

export default CompanySettings;
