import { Accordion } from '@chakra-ui/react';
import { NoteType, UUID } from '@senrasystems/senra-ui';
import NotesAccordionItem from './NotesAccordionItem';
import NotesTable from './NotesTable';
import TrianglePosition from './TrianglePosition';
import {
  useCreateBuildNoteMutation,
  useDeleteBuildNoteMutation,
  useUpdateBuildNoteMutation,
} from '../../../../api/queries';
import { useDesignId } from '../../../../hooks/useDesignId';
import { useDesignBuildNotes } from '../../../../hooks/useDesignBuildNotes';

const BuildNotesView = () => {
  const designId = useDesignId();
  const { data: generalNotes = [] } = useDesignBuildNotes(NoteType.GENERAL);
  const { data: flagNotes = [] } = useDesignBuildNotes(NoteType.FLAG);

  const { mutate: createNoteMutation } = useCreateBuildNoteMutation();
  const { mutate: updateNoteMutation } = useUpdateBuildNoteMutation();
  const { mutate: deleteNoteMutation } = useDeleteBuildNoteMutation();

  const createGeneralNote = (body: string) => createNoteMutation({ designId, body, type: NoteType.GENERAL });
  const createFlagNote = (body: string) => createNoteMutation({ designId, body, type: NoteType.FLAG });
  const updateNote = (noteId: UUID, body: string) => updateNoteMutation({ designId, noteId, buildNote: { body } });
  const deleteNote = (noteId: UUID) => deleteNoteMutation({ designId, noteId });

  return (
    <Accordion defaultIndex={[0, 1]} allowMultiple h="40vh" overflow="auto">
      <NotesAccordionItem headerLabel="General">
        <NotesTable
          notes={generalNotes}
          addNotePlaceholder="Add general note..."
          onAddNote={createGeneralNote}
          onEditNote={updateNote}
          onDeleteNote={deleteNote}
        />
      </NotesAccordionItem>
      <NotesAccordionItem headerLabel="Flag">
        <NotesTable
          PositionComponent={TrianglePosition}
          notes={flagNotes}
          addNotePlaceholder="Add flag note..."
          onAddNote={createFlagNote}
          onEditNote={updateNote}
          onDeleteNote={deleteNote}
        />
      </NotesAccordionItem>
    </Accordion>
  );
};

export default BuildNotesView;
