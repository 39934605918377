import { useMemo } from 'react';
import ManagedWindow from '../../components/ManagedWindow.tsx';
import { Box, HStack, Text } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { ConnectionGroup, SchematicMap, useSchematic } from './hooks/useSchematic.tsx';
import ConnectorAssembly from './components/ConnectorAssembly.tsx';
import { useDesign } from '../../hooks/useDesign.tsx';
import { isConnectionTarget } from '@senrasystems/senra-ui';

/**
 * Schematic component displays a schematic view of the selected design part's connections.
 * @constructor
 */
const Schematic = () => {
  // Get the selected design part
  const { selectedDesignPart } = useDesign();

  // Get the schematic data
  const { schematic, isLoading, error } = useSchematic();

  // Filter schematic data to only the groups where the source is the selected design part
  const selectedDesignPartSchematic: SchematicMap = useMemo(() => {
    if (selectedDesignPart && isConnectionTarget(selectedDesignPart)) {
      return Object.entries(schematic).reduce((map, [key, value]) => {
        if (value.connectionGroup.source?.designPart?.id === selectedDesignPart.id) {
          map[key] = value;
        }
        return map;
      }, {} as SchematicMap);
    }
    return schematic;
  }, [schematic, selectedDesignPart]);

  if (isLoading || error) {
    return null;
  }

  if (Object.entries(selectedDesignPartSchematic).length === 0) {
    return (
      <ManagedWindow title="Schematic" routeName={RouteNames.DESIGNS.SCHEMATIC}>
        <Box p={8}>No schematic data found.</Box>
      </ManagedWindow>
    );
  }

  // Render the source labels
  const renderSourceLabel = (connectionGroup: ConnectionGroup) => (
    <HStack h="42px" bg="white" textAlign="left">
      <Text fontWeight="bold">{connectionGroup.source?.designPart?.name}</Text>
      <Text>{connectionGroup.source?.designPart?.partData.partNumber}</Text>
    </HStack>
  );

  // Render the destination labels
  const renderDestinationLabel = (connectionGroup: ConnectionGroup) => (
    <HStack h="42px" bg="white" textAlign="right">
      <Text>{connectionGroup.destination?.designPart?.partData.partNumber}</Text>
      <Text fontWeight="bold">{connectionGroup.destination?.designPart?.name}</Text>
    </HStack>
  );

  return (
    <ManagedWindow title="Schematic" routeName={RouteNames.DESIGNS.SCHEMATIC}>
      {() => (
        <Box h="full" p={4}>
          {Object.entries(selectedDesignPartSchematic).map(([key, { connectionGroup }], index) => (
            <Box key={key}>
              <HStack w="calc(100% - 82px)" justifyContent="space-between">
                {!(selectedDesignPart && isConnectionTarget(selectedDesignPart)) || index === 0 ? (
                  renderSourceLabel(connectionGroup)
                ) : (
                  <HStack h="42px" w="64px" bg="gray.500" />
                )}
                {renderDestinationLabel(connectionGroup)}
              </HStack>
              <ConnectorAssembly connectionGroup={connectionGroup} />
            </Box>
          ))}
        </Box>
      )}
    </ManagedWindow>
  );
};

export default Schematic;
