import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

interface Props extends InputGroupProps {
  filter: string;
  setFilter: (value: string) => void;
}

const FilterBar = ({ filter, setFilter, ...rest }: Props) => {
  return (
    <InputGroup alignItems="center" {...rest}>
      {' '}
      {/* Ensure InputGroup aligns items */}
      <InputLeftElement pointerEvents="none" height="100%" display="flex" alignItems="center">
        <AiOutlineSearch color="gray.300" />
      </InputLeftElement>
      <Input
        id="filter"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="unstyled"
        placeholder="Filter by name or part number"
        py={2}
        pl={10}
        borderRadius="md"
        autoComplete="off"
      />
    </InputGroup>
  );
};

export default FilterBar;
