import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { apiUpdateUserProfile } from '../api/profile-api.ts';
import Loading from './Loading.tsx';
import TextInput from './form/TextInput.tsx';
import PhoneInput from './form/PhoneInput.tsx';
import { useCurrentUser } from '../queries/users.ts';
import { User } from '@senrasystems/senra-ui';
import { successToast } from '../common/toasts.ts';
import SubmitButton from './buttons/SubmitButton.tsx';
import { Stack } from '@chakra-ui/react';

interface PhoneNumbersValidation {
  phone: boolean;
}

const ProfileForm = () => {
  const { data: user } = useCurrentUser();

  if (user) {
    return <Form user={user} />;
  } else {
    return <Loading message="Loading profile settings" />;
  }
};

const Form = ({ user }: { user: User }) => {
  const [phone, setPhone] = useState<string>(user.phone ?? '');
  const [phoneNumbersValidation, setPhoneNumbersValidation] = useState<PhoneNumbersValidation>();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues: user });

  async function onSubmit(formData: FieldValues) {
    if (!validatePhoneNumbers()) return;
    const results = await apiUpdateUserProfile({
      name: user.name,
      email: formData.email,
      phone,
    });
    if (results) {
      successToast('Your profile has been successfully updated.');
    }
  }

  const validatePhoneNumbers = () => {
    const isPhoneValid = phone && isValidPhoneNumber(phone);
    setPhoneNumbersValidation({
      phone: isPhoneValid,
    });
    return isPhoneValid;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6}>
        <TextInput label="Name *" readOnly {...register('name')} />
        <TextInput label="Primary email *" readOnly {...register('email')} />
        <PhoneInput
          label="Primary phone *"
          error={
            phoneNumbersValidation && !phoneNumbersValidation.phone ? 'Primary phone number is invalid.' : undefined
          }
          name="phone"
          value={phone}
          onChange={setPhone}
        />
        <div>
          <SubmitButton text="Save changes" loading={isSubmitting} />
        </div>
      </Stack>
    </form>
  );
};

export default ProfileForm;
