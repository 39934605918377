import { useRef, useCallback } from 'react';

// This hook creates a getter with an static reference. Should only be used if necessary.
export const useValueGetter = (value: unknown) => {
  const valueRef = useRef(value);
  valueRef.current = value;

  const valueGetter = useCallback(() => valueRef.current, []);

  return valueGetter;
};
