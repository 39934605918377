import { useFormContext } from 'react-hook-form';
import { Box, Divider, HStack, Stack } from '@chakra-ui/react';
import { Part, PartType } from '@senrasystems/senra-ui';
import TextInput from '@web/components/form/TextInput.tsx';
import TextAreaInput from '@web/components/form/TextAreaInput.tsx';
import ManufacturerDropdown from './ManufacturerDropdown.tsx';
import PartConfidenceDisplay from './PartConfidenceDisplay.tsx';
import PartSpecReferences from './PartSpecReferences.tsx';
import UserMeta from './UserMeta.tsx';
import PartFieldsActionBar from './PartFieldsActionBar.tsx';
import { getFieldsForPartType } from '../utils.tsx';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  partType: PartType;
  isEditing: boolean;
  isUpdateMode: boolean;
  isCreateMode: boolean;
  theme?: 'light' | 'dark';
  onClose?: () => void;
  toggleEdit?: () => void;
  onDeletePart?: (partId: string) => Promise<void>;
}

const PartFields = ({
  partType,
  isEditing,
  isUpdateMode,
  isCreateMode,
  theme = 'light',
  onClose,
  toggleEdit,
  onDeletePart,
}: Props) => {
  const { register, getValues } = useFormContext<Part>();

  const part = getValues();

  return (
    <Stack spacing={6} overflow={'scroll'}>
      <Divider />
      <HStack spacing={10}>
        <Box flex="1">
          <EditableFormText label="Part Number" value={getValues('partNumber')} editing={isEditing}>
            <TextInput
              label="Part Number"
              formControlProps={{ isRequired: true, isDisabled: !isEditing }}
              {...register('partNumber', { required: true })}
            />
          </EditableFormText>
        </Box>
        <Box flex="1">
          <ManufacturerDropdown editing={isEditing} />
        </Box>
      </HStack>
      <Box>
        <EditableFormText label="Description" value={getValues('description')} editing={isEditing}>
          <TextAreaInput
            label="Description"
            formControlProps={{ isDisabled: !isEditing }}
            {...register('description')}
          />
        </EditableFormText>
      </Box>
      {getFieldsForPartType(partType, isEditing, isCreateMode)}
      <HStack alignItems="flex-start" spacing={10}>
        <Box flex="1">
          <PartSpecReferences editing={isEditing} />
        </Box>
        <Box flex="1">
          <PartConfidenceDisplay editing={isEditing} />
        </Box>
      </HStack>
      <Divider mt={30} />
      {!!part && (
        <>
          <HStack spacing={20}>
            {!!part.creator && <UserMeta text="Created " timestamp={part.createdAt} userName={part.creator.name} />}
            {!!part.updater && <UserMeta text="Updated " timestamp={part.updatedAt} userName={part.updater.name} />}
          </HStack>
          <Divider />
        </>
      )}
      <PartFieldsActionBar
        isUpdateMode={isUpdateMode}
        isEditing={isEditing}
        isCreateMode={isCreateMode}
        onClose={onClose}
        toggleEdit={toggleEdit}
        onDeletePart={onDeletePart}
      />
    </Stack>
  );
};

export default PartFields;
