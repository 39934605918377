import { useEffect, useMemo, useState } from 'react';
import { PartType } from '@senrasystems/senra-ui';
import { usePartsByPartNumberQuery } from '../api/queries.ts';
import { debounce } from 'lodash';

export const usePartSearch = ({ designToolSearch }: { designToolSearch: boolean } = { designToolSearch: false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<PartType[]>(Object.values(PartType));
  const { data, isLoading, isSuccess, error } = usePartsByPartNumberQuery(searchTerm, filters, designToolSearch);
  const parts = data?.data || [];

  // Memoize the debounced search function
  const debouncedSearch = useMemo(() => debounce((text: string) => setSearchTerm(text), 500), []);

  // Cleanup the debounced search function
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch, searchTerm]);

  const findPart = (partNumber: string) => {
    debouncedSearch(partNumber);
  };

  return { parts, findPart, filters, setFilters, isLoading, isSuccess, error };
};
