import { Link, Stack } from '@chakra-ui/react';

const AttachmentsStack = ({ attachments }: { attachments: { id: string; url: string; filename: string }[] }) => (
  <Stack spacing={3}>
    {attachments.map((attachment) => (
      <Link
        isExternal
        key={attachment.id}
        href={attachment.url}
        bgColor="gray.600"
        w={96}
        px={2}
        py={1}
        borderWidth={1}
        fontSize="sm"
      >
        {attachment.filename}
      </Link>
    ))}
  </Stack>
);

export default AttachmentsStack;
