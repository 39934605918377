import ProfileForm from '../components/ProfileForm.tsx';
import Title from '../components/Title';
import { Stack } from '@chakra-ui/react';

const Profile = () => {
  return (
    <Stack p={8} spacing={8}>
      <Title title="Profile Settings" />
      <ProfileForm />
    </Stack>
  );
};

export default Profile;
