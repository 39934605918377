import { Node } from '@xyflow/react';
import { Operations } from '../Operations.ts';
import { Graph, isBreakoutPointNode } from '../../types.ts';
import { NodeType } from '../../types';
import { findNodeById, updateNodesOfType } from '../NodeFactory.ts';
import { UUID } from '@senrasystems/senra-ui';

// Operation to highlight control points that intersect with a given node
export type HighlightBreakoutPointsOperation = {
  type: 'HighlightBreakoutPoints';
  params: {
    nodeId: UUID;
    intersections: UUID[];
  };
};

/**
 * Highlights the breakout points that can be merged with the dragged node.
 */
export class HighlightBreakoutPoints implements Operations<HighlightBreakoutPointsOperation> {
  // Execute the operation
  execute(graph: Graph, operation: HighlightBreakoutPointsOperation): Graph {
    const { nodes, edges } = graph;
    const { nodeId, intersections } = operation.params;

    // Validate parameters
    if (!findNodeById(nodes, nodeId, isBreakoutPointNode)) {
      return graph;
    }

    // Find the dragged node
    const draggedNode = findNodeById(nodes, nodeId);

    // Check if draggedNode and draggedNode.type are defined
    if (draggedNode && !isBreakoutPointNode(draggedNode)) {
      return graph;
    }

    // Convert intersections to a Set for faster lookup
    const intersectionSet = new Set(intersections);

    // Check if there's any valid intersection
    const hasValidIntersection = nodes.some((node) => isBreakoutPointNode(node) && intersectionSet.has(node.id));

    // If there are no intersections or no valid intersections, remove all highlights
    if (intersections.length === 0 || !hasValidIntersection) {
      const updatedNodes = updateNodesOfType(nodes, NodeType.BreakoutPoint, { mergeCandidate: false });
      return { nodes: updatedNodes, edges };
    }

    // Highlight the dragged node and the valid intersections
    const updatedNodes = updateNodesOfType(
      nodes,
      NodeType.BreakoutPoint,
      { mergeCandidate: true },
      (node: Node) => node.id === nodeId || intersectionSet.has(node.id),
    );

    return { nodes: updatedNodes, edges };
  }
}
