import { useEffect, useMemo } from 'react';
import { Handle, Node, NodeProps, Position, useReactFlow, useUpdateNodeInternals } from '@xyflow/react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { DesignPart, UUID } from '@senrasystems/senra-ui';
import { RiRotateLockLine } from 'react-icons/ri';
import { centerAbsolute } from '@web/apps/styles.ts';
import { defaultLayoutConfig } from '../../config.ts';
import { isConnectorNode, isControlPointNode } from '../../types.ts';
import { getConnectedLayoutNodes } from '../../utils/graph.ts';
import { calculateAngleBetweenPoints, rotatePoint } from '../../utils/geometry.ts';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';
import { findNodeById } from '../../graph/NodeFactory.ts';

export type ConnectorNodeData = {
  designPartId: UUID;
  rotateLock: boolean;
  angle: number;
};

export const defaultConnectorNodeData: ConnectorNodeData = {
  designPartId: '',
  rotateLock: false,
  angle: 0,
};

export type ConnectorNode = Node<ConnectorNodeData>;

/**
 * Connector node component.
 * @param props
 * @constructor
 */
const ConnectorNode = (props: NodeProps<ConnectorNode>) => {
  // Extract props
  const { data = defaultConnectorNodeData, selected } = props;

  // Extract custom node data
  const { designPartId, rotateLock, angle } = data;

  // Get the design part data
  const designPart = useDesignParts().getDesignPartById(designPartId);

  // Get the color based on the selected state
  const color = selected ? defaultLayoutConfig.selectedNodeColor : defaultLayoutConfig.nodeColor;

  // React Flow hooks
  const { getNodes, getEdges, setNodes } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  // Get connected nodes, and find a target node to determine the angle to rotate this connector
  const connectedNodes = getConnectedLayoutNodes(getNodes(), getEdges(), props.id);

  // Prioritize the nodes based on priority (control point > connector with rotate lock > any other node)
  const targetNode =
    connectedNodes.find((node) => isControlPointNode(node)) ||
    connectedNodes.find((node) => isConnectorNode(node) && node.data.rotateLock) ||
    connectedNodes[0];

  // Calculate angle to make the node perpendicular to the edge
  const calculatedAngle = useMemo(() => {
    if (!targetNode) return 0;
    if (rotateLock) {
      return angle;
    } else {
      const me = findNodeById(getNodes(), props.id);
      if (me) {
        return calculateAngleBetweenPoints(me.position, targetNode.position);
      } else {
        return 0;
      }
    }
  }, [angle, getNodes, props.id, rotateLock, targetNode]);

  useEffect(() => {
    // Update the node internals after calculating the angle
    updateNodeInternals(props.id);
  }, [calculatedAngle, setNodes, props.id, updateNodeInternals]);

  return (
    <Box position="relative">
      <ConnectorLabel designPart={designPart} angle={calculatedAngle} />
      {rotateLock && (
        <Box sx={{ ...centerAbsolute, color: 'lightgrey' }}>
          <RiRotateLockLine />{' '}
        </Box>
      )}
      <ConnectorShape color={color} angle={calculatedAngle} />
      <ConnectorHandle angle={calculatedAngle} />
    </Box>
  );
};

const ConnectorLabel = ({ designPart, angle }: { designPart?: DesignPart; angle: number }) => {
  const isFacingLeft = angle > 90 && angle < 270;
  return (
    <HStack
      position="absolute"
      top="10px"
      {...(isFacingLeft ? { right: '52px' } : { left: '52px' })}
      transform={`rotate(${angle}deg)`}
    >
      <HStack color="gray.950" fontFamily="mono" transform={`rotate(${-angle}deg)`}>
        <Text fontWeight="700">{designPart?.name}</Text>
        <Text>{designPart?.partData.partNumber}</Text>
      </HStack>
    </HStack>
  );
};

/**
 * Custom shape for the connector node (svg)
 * @param color
 * @param angle
 * @constructor
 */
const ConnectorShape = ({ color = defaultLayoutConfig.nodeColor, angle = 0 }: { color?: string; angle?: number }) => {
  // Original dimensions of the connector shape
  const originalWidth = 39;
  const originalHeight = 43;

  // Calculate the center of rotation
  const centerX = originalWidth / 2;
  const centerY = originalHeight / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M37 43C38.1046 43 39 42.1046 39 41L39 2C39 0.895431 38.1046 0 37 0H20.6346C20.197 0 19.7714 0.143531 19.4232 0.408586L5.78864 10.787C5.29172 11.1653 5 11.754 5 12.3785V13L1.90735e-06 13L0 30H5V30.6215C5 31.246 5.29172 31.8347 5.78864 32.2129L19.4232 42.5914C19.7714 42.8565 20.197 43 20.6346 43H37Z"
          fill={color}
        />
        <path d="M5 30H4L4 13H5L5 30Z" fill="#1A202C" />
      </g>
    </svg>
  );
};

const ConnectorHandle = ({ angle }: { angle: number }) => {
  // Calculate handlePosition based on the calculated angle
  const handlePosition = useMemo(() => {
    const handleOffset = { x: -19, y: 0 }; // Handle offset
    return rotatePoint(handleOffset, angle); // Rotate the offset by the calculated angle
  }, [angle]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: `calc(50% + ${handlePosition.y}px)`, // Adjust top position based on handlePosition
        left: `calc(50% + ${handlePosition.x}px)`, // Adjust left position based on handlePosition
        transform: 'translate(-50%, -50%)', // Center the handle
        visibility: 'hidden', // Keep the handle hidden by default
      }}
    >
      <Handle type="source" position={Position.Top} />
    </Box>
  );
};

export default ConnectorNode;
