import { Box, Accordion, HStack, Text } from '@chakra-ui/react';
import { useDesign } from '../../../../hooks/useDesign';
// import { groupBy } from 'lodash';

const DetailNotesView = () => {
  const { selectedDesignPart } = useDesign();

  if (!selectedDesignPart) {
    return (
      <Box p={4} h="40vh" color="gray.600">
        <Text>No part selected. Select a part to view details.</Text>
      </Box>
    );
  }

  // const groupedNotes = groupBy(design?.buildNotes, 'type');
  // const flagNotes = groupedNotes.FlagNote;
  // TODO: Filter flag notes by part number

  return (
    <Accordion h="40vh" allowMultiple>
      <HStack p={4}>
        <Text>{selectedDesignPart.name}</Text>
        <Text color="blue.500">{selectedDesignPart.partData.partNumber}</Text>
      </HStack>
    </Accordion>
  );
};

export default DetailNotesView;
