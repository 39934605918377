import { createContext, ReactNode, useContext } from 'react';
import { Part } from '@senrasystems/senra-ui';

// Context type for design
export type PartSearchModalContextType = {
  renderPartAction?: (part: Part) => ReactNode;
};

// Create a context for design
const PartSearchModalContext = createContext<PartSearchModalContextType | undefined>(undefined);

// Hook to use the design context
export const usePartSearchModal = () => {
  const context = useContext(PartSearchModalContext);
  if (context === undefined) {
    throw new Error('usePartSearchModal must be used within a PartSearchModalProvider');
  }
  return context;
};

// Provider to manage part search modal
interface PartSearchModalProviderProps {
  children: ReactNode;
  renderPartAction?: (part: Part) => ReactNode;
}

export const PartSearchModalProvider = ({ children, renderPartAction }: PartSearchModalProviderProps) => {
  // Value for the context
  const value = { renderPartAction };

  return <PartSearchModalContext.Provider value={value}>{children}</PartSearchModalContext.Provider>;
};
