import { ReactNode } from 'react';
import { Flex, Center, Box } from '@chakra-ui/react';
import { PiTriangleThin } from 'react-icons/pi';

interface Props {
  position: ReactNode;
}

const TrianglePosition = ({ position }: Props) => (
  <Flex position="relative" justify="center" align="flex-end">
    <Box as={PiTriangleThin} size={36} color="gray.400" />
    <Center position="absolute" top={0} right={0} bottom={0} left={0}>
      <Box mt={3}>{position}</Box>
    </Center>
  </Flex>
);

export default TrianglePosition;
