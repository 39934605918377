import { useController, useFormContext } from 'react-hook-form';
import { SingleValue } from 'chakra-react-select';
import { apiGetManufacturers } from '@web/api/parts-api.ts';
import { useCreateManufacturer, useManufacturers } from '../api/queries.ts';
import CreatableTypeAheadSelectInput from '../../../components/form/CreatableTypeAheadSelectInput.tsx';
import { Manufacturer, Part } from '@senrasystems/senra-ui';
import EditableFormText from '@web/components/form/EditableFormText.tsx';

interface Props {
  editing: boolean;
}

const ManufacturerDropdown = ({ editing }: Props) => {
  const { control, register, setValue } = useFormContext<Part>();
  const { field } = useController({ name: 'manufacturer', control });
  const createManufacturer = useCreateManufacturer();
  const { data: manufacturers = [] } = useManufacturers();

  const toOption = (manufacturer: Manufacturer) => ({ label: manufacturer.name, value: manufacturer.id });

  const defaultOptions = manufacturers.map(toOption);

  const optionValue = field.value ? toOption(field.value) : null;

  const handleSearchManufacturers = async (inputValue: string) => {
    const res = await apiGetManufacturers(inputValue);
    if (res) {
      return res.data.map((manufacturer) => ({ label: manufacturer.name, value: manufacturer.id }));
    }
    return [];
  };

  const handleCreateManufacturer = async (manufacturerName: string) => {
    const res = await createManufacturer.mutateAsync({ name: manufacturerName });
    if (res) {
      return { label: res.name, value: res.id };
    }
  };

  const handleOnChange = (newOption: SingleValue<{ label: string; value: string } | undefined>) => {
    if (newOption) {
      field.onChange({ name: newOption.label, id: newOption.value });
      setValue('manufacturerId', newOption.value);
    }
  };

  return (
    <EditableFormText editing={editing} value={field.value ? field.value?.name : ''} label="Manufacturer">
      <input type="hidden" {...register('manufacturerId', { value: field.value?.id })} />
      <CreatableTypeAheadSelectInput
        label="Manufacturer"
        editing={editing}
        value={optionValue}
        defaultOptions={defaultOptions}
        onChange={handleOnChange}
        onCreateOption={handleCreateManufacturer}
        loadOptions={handleSearchManufacturers}
      />
    </EditableFormText>
  );
};

export default ManufacturerDropdown;
