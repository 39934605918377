import { ReactNode } from 'react';
import { Flex, Box, HStack, Collapse, Button } from '@chakra-ui/react';
import { MdKeyboardArrowUp } from 'react-icons/md';

interface Props {
  isOpen: boolean;
  headerContent: ReactNode;
  onTogglePanel: () => void;
  children: ReactNode;
}

const ContextPanel = ({ isOpen, headerContent, onTogglePanel, children }: Props) => (
  <Flex flexDirection="column">
    <HStack
      p={3}
      width="full"
      color="gray.500"
      bg="gray.100"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="gray.300"
      onDoubleClick={onTogglePanel}
      justify="space-between"
      fontWeight="500"
      userSelect="none"
      textTransform="uppercase"
    >
      <Box w={6} />
      {headerContent}
      <Button w={6} onClick={onTogglePanel} size="sm">
        <Box transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'} transition="transform 250ms ease-out">
          <MdKeyboardArrowUp
            aria-label={`${isOpen ? 'close' : 'open'} context panel`}
            role="button"
            cursor="pointer"
            fontSize="1.25em"
          />
        </Box>
      </Button>
    </HStack>
    <Collapse in={isOpen} animateOpacity>
      {children}
    </Collapse>
  </Flex>
);

export default ContextPanel;
