import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {}

/**
 * SourcePinSVG is a simple SVG component that represents a source pin.
 * @param rest
 * @constructor
 */
const SourcePin = ({ ...rest }: Props) => (
  <Box {...rest}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="100%"
      viewBox="0 0 24 9"
      fill="none"
      style={{
        background: 'linear-gradient(to right, #718096 50%, #ffffff 50%)',
        position: 'absolute',
        left: '52px',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.834 3.25781C12.2338 1.39567 13.8894 -0.000252304 15.871 -0.000252131C18.1514 -0.000251932 20 1.84838 20 4.12878C20 6.40918 18.1514 8.25781 15.871 8.25781C13.982 8.25781 12.3893 6.98938 11.8982 5.25781L2.62268e-07 5.25781L4.37114e-07 3.25781L11.834 3.25781ZM13.8065 4.12878C13.8065 2.98858 14.7308 2.06426 15.871 2.06426C17.0112 2.06426 17.9355 2.98858 17.9355 4.12878C17.9355 5.26898 17.0112 6.1933 15.871 6.1933C14.7308 6.1933 13.8065 5.26898 13.8065 4.12878Z"
        fill="#CBD5E0"
      />
    </svg>
  </Box>
);

export default SourcePin;
