import { EdgeType, NodeType } from '../../types.ts';
import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { GraphOperation } from '../../graph/Operations.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { SegmentEdge } from '../../components/edges/SegmentEdge.tsx';
import { MenuActions } from './actionsTypes.ts';
import { useFlagNoteActions } from './useFlagNoteActions.tsx';

/**
 * Hook for segment actions.
 */
export const useSegmentActions = () => {
  const { showMenu, closeMenu, renderMenu, executeOperation, element } = useActionsBase();
  const edge = element as SegmentEdge;

  const { flagNoteActions } = useFlagNoteActions(edge?.id);

  const onAddOverWrap = (value: boolean) => {
    const params: GraphOperation = {
      type: 'UpdateEdgeData',
      params: { edgeId: edge.id, data: { hasOverWrap: value } },
    };
    executeOperation(params);
    closeMenu();
  };

  const onAddBreakoutPoint = () => {
    const params: GraphOperation = {
      type: 'AddControlPoint',
      params: { nodeType: NodeType.BreakoutPoint, edgeId: edge.id },
    };
    executeOperation(params);
    closeMenu();
  };

  const onAddLayoutPoint = () => {
    const params: GraphOperation = {
      type: 'AddControlPoint',
      params: { nodeType: NodeType.LayoutPoint, edgeId: edge.id },
    };
    executeOperation(params);
    closeMenu();
  };

  const menu = renderMenu(
    <>
      <MenuGroup title="Actions">
        {!edge?.data?.hasOverWrap ? (
          <MenuItem onClick={() => onAddOverWrap(true)}>Add overwrap</MenuItem>
        ) : (
          <MenuItem onClick={() => onAddOverWrap(false)}>Remove overwrap</MenuItem>
        )}
        <MenuItem onClick={onAddBreakoutPoint}>Add breakout point</MenuItem>
        <MenuItem onClick={onAddLayoutPoint}>Add layout point</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {flagNoteActions}
    </>,
  );
  return {
    showMenu,
    closeMenu,
    menu,
    elementType: EdgeType.Segment,
  } as MenuActions;
};
