import { MouseEvent, useState } from 'react';
import { defaultLayoutConfig } from '../config.ts';
import { useLayoutBuilder } from './useLayoutBuilder.tsx';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { useLayoutActions } from './actions/useLayoutActions.tsx';
import { isBreakoutPointNode, isDesignPartNode } from '../types.ts';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { useUpdateLayoutNotes } from './useUpdateLayoutNotes.tsx';
import { useUpdateConductorLengths } from './useUpdateConductorLengths.tsx';
import { GraphOperation } from '../graph/Operations.ts';

/**
 * Hook to manage ReactFlow events and context menus.
 */
export const useLayout = () => {
  // Settings for the layout
  const [config, setConfig] = useState(defaultLayoutConfig);

  // Design Context
  const { getDesignPartById } = useDesignParts();
  const { designId, setSelectedDesignPart } = useDesign();

  // ReactFlow API methods
  const { getNodes, getEdges, getIntersectingNodes } = useReactFlow();

  // Graph builder
  const { executeGraphOperation, loadLayout, saveLayout, updateLayout } = useLayoutBuilder();

  // Update layout notes
  useUpdateLayoutNotes(updateLayout);

  // Function to update conductor lengths
  const updateConductorLengths = useUpdateConductorLengths(designId);

  // Flag to remember if there were intersections (this is for drag and merge control points)
  const [hadIntersections, setHadIntersections] = useState(false);

  // Custom hook for all context menu operations
  const { closeAllMenus, onGraphElementContextMenu, onPaneContextMenu, contextMenus } = useLayoutActions();

  // Initialize the layout
  const onInit = async () => {
    await loadLayout();
  };

  // Pane click handler
  const onPaneClick = (event: MouseEvent) => {
    event.preventDefault();
    closeAllMenus();
    // eslint-disable-next-line no-console
    console.debug('Nodes:', getNodes());
    // eslint-disable-next-line no-console
    console.debug('Edges:', getEdges());
  };

  const onNodeClick = (_event: MouseEvent, node: Node) => {
    // eslint-disable-next-line no-console
    console.debug('Node clicked:', node);
    if (isDesignPartNode(node)) {
      const designPart = getDesignPartById(node.data.designPartId);
      if (designPart) {
        setSelectedDesignPart(designPart);
      }
    }
  };

  const onEdgeClick = (_event: MouseEvent, edge: Edge) => {
    // eslint-disable-next-line no-console
    console.debug('Edge clicked:', edge);
  };

  // Node drag handler
  const onNodeDrag = (_event: MouseEvent, node: Node) => {
    if (!isBreakoutPointNode(node)) {
      return;
    }

    // Get the nodes that intersect with the dragged node
    const intersectingNodes = getIntersectingNodes(node);
    const intersections = intersectingNodes.filter(isBreakoutPointNode).map((n) => n.id);
    const hasIntersections = intersections.length > 0;

    // Execute the operation regardless, as breakoutIntersections will be an empty array if there are no intersections
    if (hasIntersections || hadIntersections) {
      executeGraphOperation({ type: 'HighlightBreakoutPoints', params: { nodeId: node.id, intersections } });
    }

    // Update the hadIntersections state based on whether intersections exist
    setHadIntersections(hasIntersections);
  };

  // Node drag stop handler
  const onNodeDragStop = (_event: MouseEvent, node: Node) => {
    // Save the layout
    saveLayout(getNodes(), getEdges());

    // Check if the dragged node can be merged with another node
    if (isBreakoutPointNode(node)) {
      // Get the nodes that intersect with the dragged node
      const intersectingNodes = getIntersectingNodes(node);

      // Check if there is exactly one intersection, and it's of the same type as the dragged node
      const canMerge = intersectingNodes.length === 1 && isBreakoutPointNode(intersectingNodes[0]);

      if (canMerge) {
        // Params to merge breakout points
        const params: GraphOperation = {
          type: 'MergeBreakoutPoints',
          params: { fromNodeId: node.id, toNodeId: intersectingNodes[0].id },
        };

        // Merge the control points if conditions are met
        updateConductorLengths({
          onSuccess: () => {
            executeGraphOperation(params);
          },
        });
      }
    }
  };

  return {
    config,
    setConfig,
    onInit,
    onPaneContextMenu,
    onGraphElementContextMenu,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  };
};
