import { PassiveNode } from '../../components/nodes/PassiveNode.tsx';
import { NodeType } from '../../types.ts';
import { MenuActions } from './actionsTypes.ts';
import { useActionsBase } from './useActionsBase.tsx';
import { useFlagNoteActions } from './useFlagNoteActions.tsx';

/**
 * Hook for passive actions.
 */
export const usePassiveActions = () => {
  const { showMenu, closeMenu, renderMenu, element } = useActionsBase();
  const node = element as PassiveNode;

  const { flagNoteActions } = useFlagNoteActions(node?.id);

  const menu = renderMenu(flagNoteActions);

  return {
    showMenu,
    closeMenu,
    menu,
    elementType: NodeType.Passive,
  } as MenuActions;
};
