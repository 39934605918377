import { Tenant } from '@senrasystems/senra-ui';
import { apiGetCompanySettings } from '../api/company-settings-api';
import { useQuery } from '@tanstack/react-query';

const tenantCacheKey = 'currentTenant';

export const useCurrentTenant = () =>
  useQuery({
    queryKey: [tenantCacheKey],
    queryFn: () => apiGetCompanySettings().then((data) => data as Tenant),
  });
