import { Actor, Design, Tenant, UUID } from '@senrasystems/senra-ui';
import { useDesignQuery } from '../api/queries.ts';
import { useDesignId } from './useDesignId.tsx';

// Define the DesignOverview interface
export interface DesignOverview {
  id: UUID;
  name: string;
  description: string;
  partNumber: string;
  partRevision: string;
  lengthUnit: 'mm' | 'in';
  creator?: Actor;
  tenant: Tenant;
}

// Define a default overview object
const defaultDesignOverview: DesignOverview = {
  id: '',
  name: '',
  description: '',
  partNumber: '',
  partRevision: '',
  lengthUnit: 'mm',
  creator: undefined,
  tenant: {} as Tenant,
};

// Define the select function
const selectDesignOverview = (design: Design | undefined): DesignOverview => ({
  id: design?.id || '',
  name: design?.name || '',
  description: design?.description || '',
  partNumber: design?.partNumber || '',
  partRevision: design?.partRevision || '',
  lengthUnit: design?.lengthUnit || 'mm',
  creator: design?.creator,
  tenant: design?.tenant || ({} as Tenant),
});

/**
 * Hook to get a design overview by its ID, only subscribing to specific properties by using the select function of
 * useDesignQuery.
 */
export const useDesignOverview = (): DesignOverview => {
  const designId = useDesignId();

  // Fetch design overview
  const { data } = useDesignQuery(designId, selectDesignOverview);

  return data || defaultDesignOverview;
};
