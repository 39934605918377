import { Badge, Flex } from '@chakra-ui/react';
import { getColors } from '@web/common/colors.ts';

interface IColorBadgeProps {
  value?: string;
}

export const ColorBadge = ({ value }: IColorBadgeProps) => {
  const colors = getColors(value);
  return (
    !!colors?.length && (
      <Flex h={5} borderRadius="sm" height="10px" clipPath="content-box">
        {colors.map((color) => {
          return <Badge w={4} flex={1} key={color} borderRadius="none" backgroundColor={color} />;
        })}
      </Flex>
    )
  );
};
