import { produce } from 'immer';
import { PartType } from '@senrasystems/senra-ui';
import { XYPosition } from '@xyflow/react';

type PositionData = {
  x: number;
  y: number;
  incrementX: number;
  incrementY?: number; // Optional Y-axis increment, for future flexibility
};

// Define the initial positions and spacing for each part type
const initialPositions: Partial<Record<PartType, PositionData>> = {
  [PartType.SPLICE]: { x: 50, y: 50, incrementX: 75 },
  [PartType.PASSIVE]: { x: 50, y: 100, incrementX: 75 },
  [PartType.CONNECTOR]: { x: 50, y: 150, incrementX: 75 },
};

/**
 * A class to manage the positions of nodes in the layout.
 */
class PositionManager {
  private positions: Partial<Record<PartType, PositionData>>;

  constructor(positions?: Partial<Record<PartType, PositionData>>) {
    this.positions = produce(initialPositions, (draft) => {
      if (positions) {
        Object.assign(draft, positions);
      }
    });
  }

  /**
   * Get the next position for a given part type and update the position state.
   * @param partType - The part type to get the next position for
   * @returns The next position for the given part type
   */
  getNextPosition(partType: PartType): XYPosition {
    const positionData = this.positions[partType];
    if (!positionData) {
      throw new Error(`Position data not defined for part type: ${partType}`);
    }

    // Get the current position
    const position = { x: positionData.x, y: positionData.y };

    // Update the position for the next node using immer
    this.positions = produce(this.positions, (draft) => {
      const draftPositionData = draft[partType];
      if (draftPositionData) {
        draftPositionData.x += draftPositionData.incrementX;
        if (draftPositionData.incrementY) {
          draftPositionData.y += draftPositionData.incrementY;
        }
      }
    });

    return position;
  }

  /**
   * Reset the positions to the initial state.
   */
  reset(): void {
    this.positions = produce(initialPositions, () => {});
  }
}

export default PositionManager;
