import TextInput from '../../../components/form/TextInput';
import SubmitButton from '../../../components/buttons/SubmitButton';
import { UseFormRegister } from 'react-hook-form';
import SelectInput from '../../../components/form/SelectInput';
import { Box, Stack } from '@chakra-ui/react';

interface Props {
  isNew?: boolean; // are we creating a new tenant or editing an existing one
  onSubmit: () => void;
  register: UseFormRegister<{ name: string; usBased: string; orderNumberPrefix: string }>;
  isSubmitting: boolean;
  isValid: boolean;
  handleTenantNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TenantForm = ({ isNew, onSubmit, register, isSubmitting, isValid, handleTenantNameChange }: Props) => {
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <TextInput label="Name *" onChangeCallback={handleTenantNameChange} {...register('name', { required: true })} />
        <SelectInput
          label="U.S. Based"
          options={
            <>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </>
          }
          {...register('usBased', { setValueAs: (value: string) => (value === 'true' ? true : false) })}
        />
        <TextInput
          label="Order Number Prefix *"
          hint="The unique prefix used for customers' order numbers. If the full order number is 'SNRA-001', then the prefix is 'SNRA'."
          {...register('orderNumberPrefix', { required: true })}
        />
        <Box>
          <SubmitButton loading={isSubmitting} disabled={!isValid} />
        </Box>
      </Stack>
    </form>
  );
};

export default TenantForm;
