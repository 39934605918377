import { ChangeEvent, ReactNode } from 'react';
import { Box, Input, Switch, Text } from '@chakra-ui/react';
import SearchableDropdown, { Option } from '../../../components/SearchableDropdown.tsx';

// Input types for the EditableField component
export type EditableFieldType = 'input' | 'dropdown' | 'switch';

// EditableField component props
interface Props {
  isEditing: boolean;
  value: string | boolean;
  options?: Option[];
  onChange: (value: string | boolean) => void;
  type: EditableFieldType;
}

/**
 * EditableField component is a field that can be edited in place, and is fairly specific to the WiringList feature. It
 * can be an input, dropdown, or switch can displays text or a form field based on the editMode prop.
 * @param editMode
 * @param value
 * @param options
 * @param onChange
 * @param type
 * @constructor
 */
const EditableField = ({ isEditing, value, options, onChange, type }: Props) => {
  // Handle changes to the field value for a dropdown
  const handleSelectChange = (option: Option | null) => {
    onChange(option ? option.value : '');
  };

  // Handle changes to the field value for an input
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  // Handle changes to the field value for a switch
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  // Render the field based on the type prop
  const renderField = (): ReactNode => {
    switch (type) {
      case 'input':
        return <Input value={value as string} onChange={handleInputChange} />;
      case 'dropdown':
        return (
          <SearchableDropdown
            options={options || []}
            value={options?.find((opt) => opt.value === value) || null}
            onChange={handleSelectChange}
          />
        );
      case 'switch':
        return <Switch isChecked={value as boolean} onChange={handleSwitchChange} />;
      default:
        return null;
    }
  };

  return isEditing ? (
    <Box>{renderField()}</Box>
  ) : (
    <Text>{options ? options.find((o) => o.value === value)?.label : value}</Text>
  );
};

export default EditableField;
