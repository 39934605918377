import { Edge, Node } from '@xyflow/react';
import { Operations } from '../Operations.ts';
import { Bundle, Graph, isBreakoutPointNode, isControlPointNode, isDesignPartNode } from '../../types.ts';
import {
  findMeasurementEdge,
  getConnectedSegments,
  removeConnectedMeasurements,
  removeConnectedSegments,
  shouldCreateMeasurementEdge,
} from '../../utils/graph.ts';
import { SegmentEdgeData } from '../../components/edges/SegmentEdge.tsx';
import { createAndAddMeasurementEdge, createAndAddSegmentEdge } from '../EdgeFactory.ts';
import { findNodeById, removeNodeIfExists } from '../NodeFactory.ts';
import { UUID } from '@senrasystems/senra-ui';

// Operation to remove a control point
export type RemoveControlPointOperation = {
  type: 'RemoveControlPoint';
  params: {
    nodeId: UUID;
  };
};

/**
 * Removes a control point node from the graph. This operation will remove the control point node and reconnect the
 * nodes that were connected to the control point.
 */
export class RemoveControlPoint implements Operations<RemoveControlPointOperation> {
  // Rebuild the graph after the operation
  requiresRebuild = true;
  
  // Execute the operation
  execute(graph: Graph, operation: RemoveControlPointOperation): Graph {
    const { nodes, edges } = graph;
    const { nodeId } = operation.params;

    // Find the control point node
    const controlPointToRemove = findNodeById(nodes, nodeId);
    if (!controlPointToRemove || !isControlPointNode(controlPointToRemove)) {
      // eslint-disable-next-line no-console
      console.warn(`Control point node not found: ${nodeId}`);
      return { nodes, edges };
    }

    // Get edges connected to the control point
    const connectedEdges = getConnectedSegments(edges, controlPointToRemove.id);

    // Build a map of nodes connected to the control point and their bundles
    const nodeConnectionMap = this.buildNodeConnectionMap(nodes, connectedEdges, controlPointToRemove);

    // Reconnect nodes based on matching bundles, and also create measurement edges if necessary
    this.reconnectNodes(nodes, edges, controlPointToRemove, nodeConnectionMap);

    // Remove the control point node, segments, and measurements
    removeNodeIfExists(nodes, controlPointToRemove.id);
    removeConnectedSegments(edges, controlPointToRemove.id);
    removeConnectedMeasurements(edges, controlPointToRemove.id);

    return { nodes: nodes, edges: edges };
  }

  /**
   * Build a map of nodes connected to the control point and their bundles.
   * @param nodes
   * @param connectedEdges
   * @param controlPointToRemove
   * @private
   */
  private buildNodeConnectionMap(
    nodes: Node[],
    connectedEdges: Edge[],
    controlPointToRemove: Node,
  ): Map<string, { node: Node; bundles: Bundle[] }> {
    const nodeConnectionMap = new Map<string, { node: Node; bundles: Bundle[] }>();

    connectedEdges.forEach((edge) => {
      const otherNodeId = edge.source === controlPointToRemove.id ? edge.target : edge.source;
      const otherNode = findNodeById(nodes, otherNodeId);
      if (otherNode && (isDesignPartNode(otherNode) || isControlPointNode(otherNode))) {
        const edgeData = edge.data as SegmentEdgeData;
        if (!nodeConnectionMap.has(otherNodeId)) {
          nodeConnectionMap.set(otherNodeId, { node: otherNode, bundles: [...edgeData.bundles] });
        } else {
          const existingEntry = nodeConnectionMap.get(otherNodeId);
          existingEntry?.bundles.push(...edgeData.bundles);
        }
      }
    });

    return nodeConnectionMap;
  }

  /**
   * Reconnect nodes based on matching bundles.
   * @param nodes
   * @param edges
   * @param controlPointToRemove
   * @param nodeConnectionMap
   * @private
   */
  private reconnectNodes(
    nodes: Node[],
    edges: Edge[],
    controlPointToRemove: Node,
    nodeConnectionMap: Map<
      string,
      {
        node: Node;
        bundles: Bundle[];
      }
    >,
  ): void {
    const nodeEntries = Array.from(nodeConnectionMap.entries());

    for (let i = 0; i < nodeEntries.length; i++) {
      const [sourceNodeId, sourceEntry] = nodeEntries[i];

      for (let j = i + 1; j < nodeEntries.length; j++) {
        const [targetNodeId, targetEntry] = nodeEntries[j];

        const matchingBundles = this.getMatchingBundles(sourceEntry.bundles, targetEntry.bundles);

        if (matchingBundles.length > 0) {
          createAndAddSegmentEdge(edges, sourceNodeId, targetNodeId, { bundles: matchingBundles });
          if (
            isBreakoutPointNode(controlPointToRemove) &&
            shouldCreateMeasurementEdge(nodes, edges, sourceEntry.node, targetEntry.node)
          ) {
            // Find the measurement edge for the source and control point
            const m1 = findMeasurementEdge(edges, sourceNodeId, controlPointToRemove.id);
            // Find the measurement edge for the target and control point
            const m2 = findMeasurementEdge(edges, targetNodeId, controlPointToRemove.id);
            // Add the measurement data
            if (m1 && m2) {
              const measurementData = {
                ...m1.data,
                measurement: (m1.data?.measurement || 0) + (m2.data?.measurement || 0),
              };
              createAndAddMeasurementEdge(edges, sourceNodeId, targetNodeId, measurementData);
            }
          }
        }
      }
    }
  }

  /**
   * Get bundles that match between two sets of bundles.
   * @param sourceBundles
   * @param targetBundles
   * @private
   */
  private getMatchingBundles(sourceBundles: Bundle[], targetBundles: Bundle[]): Bundle[] {
    return sourceBundles.filter((sourceBundle) =>
      targetBundles.some(
        (targetBundle) =>
          (sourceBundle.sourceDesignPartId === targetBundle.sourceDesignPartId &&
            sourceBundle.destinationDesignPartId === targetBundle.destinationDesignPartId) ||
          (sourceBundle.sourceDesignPartId === targetBundle.destinationDesignPartId &&
            sourceBundle.destinationDesignPartId === targetBundle.sourceDesignPartId),
      ),
    );
  }
}
