import { FormProvider, useForm } from 'react-hook-form';
import PartsLibraryDashboard, { CustomColumnDef } from '../../components/PartsLibraryDashboard.tsx';
import { minMaxAwgFormatter, MinMaxAwg } from '../../components/MinMaxAwgFields.tsx';
import { Contact, Part, PartGender, PartType } from '@senrasystems/senra-ui';
import { Text } from '@chakra-ui/react';

const ContactsDashboard = () => {
  const methods = useForm<Contact>({ defaultValues: { type: PartType.CONTACT } });
  const columns: CustomColumnDef[] = [
    {
      header: 'AWG',
      renderFn: (part: Part) => {
        const contact = part as Contact;
        const minMaxAwg: MinMaxAwg = {
          gaugeMinAwg: contact.gaugeMinAwg,
          gaugeMaxAwg: contact.gaugeMaxAwg,
        };
        return <Text>{minMaxAwgFormatter(minMaxAwg)}</Text>;
      },
    },
    {
      header: 'Gender',
      renderFn: (part: Part) => {
        const contact = part as Contact;
        return (
          <Text>
            {contact.gender === PartGender.MALE
              ? 'Pin'
              : contact.gender === PartGender.FEMALE
                ? 'Socket'
                : 'Sealing Plug'}
          </Text>
        );
      },
    },
    {
      header: 'Termination',
      renderFn: (part: Part) => {
        const contact = part as Contact;
        return <Text>{contact.termination}</Text>;
      },
    },
  ];

  return (
    <FormProvider {...methods}>
      <PartsLibraryDashboard partType={PartType.CONTACT} columnDefs={columns} />
    </FormProvider>
  );
};

export default ContactsDashboard;
