import { Button } from '@chakra-ui/react';

interface DiscardButtonProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const CancelButton = ({ size = 'md', onClick }: DiscardButtonProps) => (
  <Button variant="ghost" onClick={onClick} size={size}>
    Cancel
  </Button>
);

export default CancelButton;
