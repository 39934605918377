import { CellContext } from '@tanstack/react-table';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';

export const Checkbox = <T, U extends boolean>(props: CellContext<T, U>) => {
  return (
    <ChakraCheckbox
      isChecked={props.getValue()}
      onChange={(e) => {
        const { row, column } = props;
        const { index, depth } = row;
        props.table.options.meta?.updateData(index, depth, column.id, e.target.checked);
      }}
    />
  );
};
