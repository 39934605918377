import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Box, Text } from '@chakra-ui/react';
import { UUID } from '@senrasystems/senra-ui';
import { defaultLayoutConfig } from '../../config.ts';
import { centerAbsolute } from '@web/apps/styles.ts';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';

export type SpliceNodeData = {
  designPartId: UUID;
};

export const defaultSpliceNodeData: SpliceNodeData = {
  designPartId: '',
};

export type SpliceNode = Node<SpliceNodeData>;

/**
 * Splice node component.
 * @param props
 * @constructor
 */
const SpliceNode = (props: NodeProps<SpliceNode>) => {
  const { selected, data = defaultSpliceNodeData } = props;
  const { designPartId } = data;

  // Get the design part data
  const designPart = useDesignParts().getDesignPartById(designPartId);

  // Get the color based on the selected state
  const color = selected ? defaultLayoutConfig.selectedNodeColor : defaultLayoutConfig.nodeColor;

  return (
    <Box position="relative">
      <Text position="absolute" top="-20px">
        {designPart?.name}
      </Text>
      <SpliceShape color={color} />
      <Box sx={{ ...centerAbsolute, visibility: 'hidden' }}>
        <Handle type="source" position={Position.Top} />
      </Box>
    </Box>
  );
};

const SpliceShape = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill={color}
      />
      <path
        d="M17.3356 8.67086C17.7061 8.48562 17.8562 8.03512 17.671 7.66463C17.4857 7.29415 17.0352 7.14398 16.6648 7.32922L10.8232 10.25H3C2.58579 10.25 2.25 10.5858 2.25 11C2.25 11.4142 2.58579 11.75 3 11.75H10.9832C10.9949 11.7503 11.0066 11.7503 11.0183 11.75H11.0543L18.5924 16.6296C18.9402 16.8547 19.4045 16.7553 19.6296 16.4076C19.8547 16.0598 19.7553 15.5955 19.4076 15.3704L12.6653 11.006L17.3356 8.67086Z"
        fill="white"
      />
    </svg>
  );
};

export default SpliceNode;
