import { CSSObject } from '@emotion/react';
import { StylesConfig } from 'react-select';

export const getDropdownStyle: <T, IsMulti extends boolean = true>(
  height?: string,
  width?: string,
) => StylesConfig<T, IsMulti> = (height, width = 'fit-content') => ({
  container: (provided: CSSObject) => ({
    ...provided,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'var(--chakra-colors-gray-600)',
    height,
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    height,
    width,
    padding: '0 6px',
  }),
  indicatorsContainer: (provided: CSSObject) => ({
    ...provided,
    height,
  }),
  input: (provided: CSSObject) => ({
    ...provided,
    margin: '0px',
    color: 'white',
    height,
  }),
  indicatorSeparator: () => ({
    display: 'none',
    color: 'white',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: height,
    height,
    color: 'white',
    backgroundColor: 'gray.500',
    border: 'none',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
    height,
  }),
  multiValue: (base) => ({
    ...base,
    color: 'white',
    background: '#52525B',
    height,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
    height,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    background: '#151516',
    opacity: 1,
    border: '1px solid var(--chakra-colors-gray-500)',
  }),
  option: (baseStyles, props) => ({
    ...baseStyles,
    color: 'white',
    background: props.isFocused ? 'var(--chakra-colors-gray-500)' : baseStyles.background,
    opacity: 1,
  }),
});
